import { Modal } from '@mui/material';
import React, { useRef, useState, useEffect } from 'react';
import { AppInputField } from '../../../../general/AppInputField/AppInputField';
import SignaturePad from 'react-signature-pad-wrapper';
import { AppButton } from '../../../../general/AppButton/AppButton';
import { Download } from 'react-feather';
import { UploadSimple, CheckCircle } from 'phosphor-react';
import { useDropzone } from 'react-dropzone';
import { COLORS } from '../../../../../utils/colors';
import { useAppSelector } from '../../../../../redux/store';
import './CreateSignatureModal.scss';
import moment from 'moment';
import { useWindowActualWidth } from '../../../../../utils/domTools';

const CreateSignatureModal = ({
  open,
  handleClose = () => {},
  addSignature = () => {},
  onDownloadRealease,
  defaultIsManualSign,
  onlyUpload = false,
  title,
  buttonText,
}: {
  open: boolean;
  handleClose: () => void;
  addSignature: (signature: string, isManual: boolean) => void;
  onDownloadRealease: () => void;
  defaultIsManualSign: boolean;
  onlyUpload?: boolean;
  title?: string;
  buttonText?: string;
}) => {
  const signaturePadRef = useRef<any>(null);
  const windowWidth = useWindowActualWidth();
  const { userSignatures } = useAppSelector((state) => state.auth);
  const [signature, setSignature] = useState('');
  const [uploadedSignature, setUploadedSignature] = useState('');
  const [recentSignature, setRecentSignature] = useState('');
  const [isManualSign, setIsManualSign] = useState(false);
  const [uploadSignature, setUploadSignature] = useState(false);
  const [file, setFile] = useState<File[]>([]);
  const [signatureFile, setSignatureFile] = useState<File[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);
  const [signatureType, setSignatureType] = useState<'draw' | 'upload' | 'text' | 'recent'>('draw');
  const [fullName, setFullName] = useState('');
  const [selectedRecent, setSelectedRecent] = useState('');
  const padWidth = windowWidth / 3.38;

  useEffect(() => {
    if (open) {
      setUploadSignature(false);
      setIsManualSign(defaultIsManualSign);
      setSignature('');
      setRecentSignature('');
      setFile([]);
      setUploadedSignature('');
      setSignatureFile([]);
      setFullName('');
    }
  }, [open]);

  useEffect(() => {
    if (userSignatures.length > 0) {
      setSelectedRecent(userSignatures[0].id);
      setRecentSignature(userSignatures[0].signature);
      setSignatureType('recent');
    }
  }, [userSignatures, open]);

  const cropSignatureCanvas = (canvas: any) => {
    // First duplicate the canvas to not alter the original
    const croppedCanvas = document.createElement('canvas');
    const croppedCtx = croppedCanvas.getContext('2d');
    if (!croppedCtx) {
      return null;
    }

    croppedCanvas.width = canvas.width;
    croppedCanvas.height = canvas.height;
    croppedCtx.drawImage(canvas, 0, 0);

    // Next do the actual cropping
    let w = croppedCanvas.width;
    let h = croppedCanvas.height;
    const pix: any = { x: [], y: [] };
    const imageData = croppedCtx.getImageData(0, 0, croppedCanvas.width, croppedCanvas.height);
    let x: any = null;
    let y: any = null;
    let index: any = null;

    for (y = 0; y < h; y++) {
      for (x = 0; x < w; x++) {
        index = (y * w + x) * 4;
        if (imageData.data[index + 3] > 0) {
          pix.x.push(x);
          pix.y.push(y);
        }
      }
    }
    pix.x.sort((a: any, b: any) => {
      return a - b;
    });
    pix.y.sort((a: any, b: any) => {
      return a - b;
    });
    const n = pix.x.length - 1;

    w = pix.x[n] - pix.x[0];
    h = pix.y[n] - pix.y[0];
    const cut = croppedCtx.getImageData(pix.x[0], pix.y[0], w, h);

    croppedCanvas.width = w;
    croppedCanvas.height = h;
    croppedCtx.putImageData(cut, 0, 0);

    return croppedCanvas.toDataURL();
  };

  const textToDataURL = () => {
    const canvas = document.getElementById('canvas-signature-text') as HTMLCanvasElement;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.font = '120px september-spirit';
        ctx.fillStyle = 'black';
        ctx.textAlign = 'center';
        ctx.fillText(fullName.trim(), canvas.width / 2, canvas.height / 2);

        const dataURL = cropSignatureCanvas(canvas);
        return dataURL;
      }
    }
    return null;
  };

  const handleGetSignature = async () => {
    if (isManualSign) {
      if (file.length > 0) {
        const blobUrl = URL.createObjectURL(file[0]);
        addSignature(blobUrl, isManualSign);
      }
    } else {
      if (signatureType === 'draw') {
        if (signaturePadRef.current) {
          const rr = cropSignatureCanvas(signaturePadRef.current.canvasRef.current);
          if (rr) {
            addSignature(rr, isManualSign);
            setSignature(rr);
          }
        }
      } else if (signatureType === 'text') {
        // const signatureText = await textToDataURL();
        const signatureText = await textToDataURL();
        if (signatureText) {
          addSignature(signatureText, isManualSign);
        }
      } else if (signatureType === 'recent') {
        addSignature(recentSignature, isManualSign);
      } else {
        addSignature(uploadedSignature, isManualSign);
      }
    }
  };

  useEffect(() => {
    const handler = (e: any) => {
      if (
        containerRef.current &&
        containerRef.current.contains(e.target) &&
        signaturePadRef.current
      ) {
        const isSignEmpty = signaturePadRef.current.isEmpty();
        const sign = signaturePadRef.current.toDataURL();
        try {
          cropSignatureCanvas(signaturePadRef.current.canvasRef.current);
          if (sign && !isSignEmpty) {
            setSignatureFile([]);
            setUploadedSignature('');
            setFullName('');
            setSignature(sign);
            setFile([]);
            setSelectedRecent('');
            setRecentSignature('');
          }
        } catch {}
      }
    };

    window.addEventListener('click', handler);
    return () => {
      window.removeEventListener('click', handler);
    };
  }, []);

  const handleSelectFile = (uploadedFiles: FileList) => {
    const fileList = Array.from(uploadedFiles) as File[];
    setFile(fileList);
  };

  const onDrop = (acceptedFiles: any) => {
    handleSelectFile(acceptedFiles);
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf'],
    },
  });

  function fileToDataURI(
    fileToConvert: File,
    callback: (dataURI: string | null, croppedDataURI: string | null) => void
  ): void {
    const reader = new FileReader();

    reader.onload = function () {
      if (typeof reader.result === 'string' || reader.result instanceof ArrayBuffer) {
        const dataURI = typeof reader.result === 'string' ? reader.result : null;

        const img = new Image();
        img.src = dataURI || '';

        img.onload = function () {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          if (!ctx) {
            callback(dataURI || null, null);
            return;
          }

          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);

          // Call the cropSignatureCanvas function to crop the image
          const croppedDataURI = cropSignatureCanvas(canvas);

          callback(dataURI || null, croppedDataURI);
        };
      } else {
        callback(null, null);
      }
    };

    reader.onerror = function () {
      callback(null, null);
    };
    reader.readAsDataURL(fileToConvert);
  }

  const handleClearSignature = () => {
    if (signaturePadRef.current) {
      setSignature('');
      signaturePadRef.current.clear();
    }
  };
  const handleUploadSignatureFile = (uploadedFiles: FileList) => {
    const fileList = Array.from(uploadedFiles) as File[];
    handleClearSignature();
    setFullName('');
    setSelectedRecent('');
    setRecentSignature('');
    setSignatureFile(fileList);
    const selectedFile = fileList[0];
    fileToDataURI(
      selectedFile,
      function (originalDataURI: string | null, croppedDataURI: string | null) {
        if (originalDataURI && croppedDataURI) {
          setUploadedSignature(originalDataURI);
        }
      }
    );
  };

  const onDropSinagtureFile = (acceptedFiles: any) => {
    handleUploadSignatureFile(acceptedFiles);
  };

  const signatureUploadDropZone = useDropzone({
    onDrop: onDropSinagtureFile,
    accept: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
    },
  });
  const getUploadRootProps = signatureUploadDropZone.getRootProps;
  const getUploadInputProps = signatureUploadDropZone.getInputProps;
  const buttonDisable = () => {
    return (
      (isManualSign && file.length === 0) ||
      (!isManualSign && signature === '' && signatureType === 'draw') ||
      (!isManualSign && uploadedSignature === '' && signatureType === 'upload') ||
      (!isManualSign && fullName === '' && signatureType === 'text') ||
      (!isManualSign && recentSignature === '' && signatureType === 'recent')
    );
  };
  const handleCloseModal = () => {
    handleClose();
    setSignature('');
    setRecentSignature('');
    setFile([]);
    setIsManualSign(false);
    setUploadedSignature('');
    setSignatureFile([]);
    setUploadSignature(false);
    setSignatureType('draw');
    setFullName('');
  };

  const handleSelectRecent = (val: string, id: string) => {
    handleClearSignature();
    setSignatureFile([]);
    setUploadedSignature('');
    setFullName('');
    setSelectedRecent(id);
    setRecentSignature(val);
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div
        ref={containerRef}
        className="flex min-h-80 w-4/12 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg  flex-col">
        <h2 className="text-lg font-semibold mx-4 my-3">{`${
          title
            ? title
            : isManualSign
            ? 'Download & Manual Upload Release'
            : 'Digital Sign or Upload Signature'
        }`}</h2>
        <div style={{ height: '0.01em' }} className="h-0.5 w-full bg-slate-400"></div>
        {isManualSign ? (
          <div className="mx-4 mt-3 mb-2">
            {!onlyUpload && (
              <div className="GREY_200-BORDER border rounded-lg p-4 items-center justify-center flex flex-col">
                <div className="w-full mb-6 text-sm font-medium">
                  Please download the release to sign manually
                </div>
                <div>
                  <AppButton
                    type="SECONDARY"
                    text="Download Release"
                    onClick={onDownloadRealease}
                    icon={<Download size={20} color={COLORS.PRIMARY_500} />}
                  />
                </div>
              </div>
            )}
            <div className="PRIMARY_50-BG mt-4 rounded-lg p-4 items-center justify-center flex flex-col">
              <div className="w-full mb-6 text-sm font-medium">
                After signing the release, make sure to upload below
              </div>
              <input className="hidden" {...getInputProps()} />
              <div {...getRootProps()} className="justify-center flex flex-col items-center">
                <AppButton
                  text="Upload Signed Release"
                  onClick={() => {}}
                  icon={<UploadSimple size={20} color={COLORS.WHITE} />}
                />
                <div className="text-xs GREY_500-CLR">*Accepted file .pdf</div>
              </div>
              <div className="text-sm font-normal mt-6">
                {file.map((item, index) => (
                  <div key={index}>{item.name}</div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div>
            <p className="text-xs mx-4 mt-3 mb-2">Confirm your name & signature</p>
            <div className=" mx-4 p-3 mb-4 bg-slate-100 rounded">
              {/* <div className="">
                <p className="text-xs font-bold mb-1">Full Name</p>
                <AppInputField placeholder={'Name'} />
              </div> */}

              <div className="flex flex-col">
                <div className="flex border-b">
                  <button
                    onClick={() => {
                      setSignatureType('draw');
                    }}
                    className={`text-xs cursor-pointer pb-1 ${
                      signatureType === 'draw'
                        ? ' font-medium border-b-2 PRIMARY_500-BORDER'
                        : ' GREY_500-CLR'
                    }`}>
                    Draw
                  </button>
                  <button
                    onClick={() => {
                      setSignatureType('text');
                    }}
                    className={`ml-2 text-xs cursor-pointer pb-1 ${
                      signatureType === 'text'
                        ? ' font-medium border-b-2 PRIMARY_500-BORDER'
                        : ' GREY_500-CLR'
                    }`}>
                    Text
                  </button>
                  <button
                    onClick={() => {
                      setSignatureType('upload');
                    }}
                    className={`ml-2 text-xs cursor-pointer pb-1 ${
                      signatureType === 'upload'
                        ? ' font-medium border-b-2 PRIMARY_500-BORDER'
                        : ' GREY_500-CLR'
                    }`}>
                    Upload
                  </button>
                  <button
                    onClick={() => {
                      setSignatureType('recent');
                    }}
                    className={`ml-2 text-xs cursor-pointer pb-1 ${
                      signatureType === 'recent'
                        ? ' font-medium border-b-2 PRIMARY_500-BORDER'
                        : ' GREY_500-CLR'
                    }`}>
                    Recent
                  </button>
                </div>
                <div className={`${signatureType !== 'draw' ? 'hidden' : ''}`}>
                  <p className="text-xs font-bold mb-1 mt-2">Draw your signature</p>
                  <div className="w-full h-[90px] overflow-scroll rounded-[10px]">
                    <SignaturePad
                      ref={signaturePadRef}
                      height={90}
                      width={padWidth}
                      canvasProps={{
                        style: {
                          background: 'white',
                          borderRadius: '10px',
                          height: '90px',
                          // width: '100%',
                        },
                      }}
                      options={{ minWidth: 1, maxWidth: 2, penColor: '#000000', dotSize: 0.002 }}
                    />
                  </div>
                  <AppButton
                    text="Clear"
                    buttonStyles={{
                      padding: '1px',
                      alignSelf: 'flex-end',
                      borderRadius: '10px',
                      marginTop: '10px',
                    }}
                    onClick={handleClearSignature}
                  />
                </div>
                {signatureType === 'upload' && (
                  <div className="flex WHITE-BG h-48 rounded-2xl w-full mt-4 items-center flex-col  justify-center">
                    <input className="hidden" {...getUploadInputProps()} />
                    <div
                      {...getUploadRootProps()}
                      className="flex flex-col items-center justify-center">
                      <AppButton
                        type="SECONDARY"
                        text="Upload Your Signature"
                        icon={<UploadSimple size={20} color={COLORS.PRIMARY_500} />}
                      />
                      <div className="text-xs GREY_500-CLR">*Accepted file .jpg .jpeg .png</div>
                    </div>
                    <div className="text-sm font-normal mt-6">
                      {uploadedSignature !== '' && (
                        <div className="flex w-full items-center justify-center h-[44px] pt-[4px] pb-[4px] w-full">
                          <img className="signature-image" src={uploadedSignature} />
                        </div>
                      )}
                      {signatureFile.map((item, index) => (
                        <div key={index}>{item.name}</div>
                      ))}
                    </div>
                  </div>
                )}
                {signatureType === 'text' && (
                  <div className="mt-3">
                    <AppInputField
                      placeholder="Full Name"
                      label="Full Name"
                      value={fullName}
                      onTextChange={(text) => {
                        setFullName(text);
                        handleClearSignature();
                        setSignatureFile([]);
                        setUploadedSignature('');
                        setSelectedRecent('');
                        setRecentSignature('');
                      }}
                    />
                    <div className="signature-txt-conatainer">
                      <div className="signature-txt">{fullName}</div>
                    </div>

                    <canvas
                      className="hide-signature-canvas"
                      id="canvas-signature-text"
                      width="1600"
                      height="270"></canvas>
                  </div>
                )}
                <div className={`${signatureType === 'recent' ? 'mt-3' : 'hidden'}`}>
                  <div className="WHITE-BG w-full p-4 flex max-h-[300px] flex-wrap overflow-scroll">
                    {userSignatures.map((userSignature, i) => (
                      <div
                        onClick={() =>
                          handleSelectRecent(userSignature.signature, userSignature.id)
                        }
                        key={userSignature.id}
                        className={`cursor-pointer border-2 rounded-lg w-[100px] h-[100px] m-2 px-1 pt-4 pb-2 flex justify-between items-center flex-col relative ${
                          selectedRecent === userSignature.id
                            ? 'PRIMARY_500-BORDER'
                            : 'GREY_200-BORDER'
                        }`}>
                        {selectedRecent === userSignature.id && (
                          <div className="absolute left-[0.5px] top-[0.5px]">
                            <CheckCircle color={COLORS.PRIMARY_500} size={15} weight="fill" />
                          </div>
                        )}
                        <img
                          alt={`alt_signature_${i}`}
                          className="object-contain h-[50px] w-full"
                          src={userSignature.signature}
                        />
                        <div className="text-sm">
                          {moment(userSignature.date).format('MM/DD/YYYY')}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <p style={{ fontSize: '12px' }} className="mx-4 text-xs TEXT_SECONDARY-CLR  ">
              By selecting Accept & Sign, I agree that the signature will be the electronic
              representation of my signature for all purposes when I (or my agent) use them on
              legally binding contracts. It is just the same as a pen-and-paper signature.
            </p>
          </div>
        )}
        <div style={{ height: '0.01em' }} className="h-0.5 w-full bg-slate-400 my-4"></div>
        <div className="flex justify-end mx-3 mb-2">
          <AppButton
            text="Close"
            type="TERTIARY"
            onClick={handleCloseModal}
            buttonStyles={{ marginRight: '8px' }}
          />
          <AppButton
            text={buttonText ? buttonText : 'Accept & Sign'}
            // buttonStyles={{ marginLeft: '3px' }}
            onClick={handleGetSignature}
            isDisabled={buttonDisable()}
          />
        </div>
      </div>
    </Modal>
  );
};

export default CreateSignatureModal;
