import React, { useEffect, useState } from 'react';
import { Modal } from '@mui/material';
import d1Logo from '../../../assets/images/d1-logo.png';
import { AppInputField } from '../AppInputField/AppInputField';
import { AppButton } from '../AppButton/AppButton';
import { useAppDispatch } from '../../../redux/store';
import { addDistributionOneSupplierConnection } from '../../../redux/services/suppliersService';
import { useNavigate } from 'react-router-dom';
import { openMessageModal } from '../../../redux/globalSlices/genericSlice';

const AppDistributionOneConnectionModal = ({
  open,
  handleClose = () => {},
}: {
  open: boolean;
  handleClose: () => void;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [companyName, setCompanyName] = useState('');
  const [baseUrl, setBaseUrl] = useState('');
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({
    baseUrl: '',
    companyName: '',
    userName: '',
    password: '',
  });
  useEffect(() => {
    if (open) {
      setErrors({
        baseUrl: '',
        companyName: '',
        userName: '',
        password: '',
      });
      setBaseUrl('');
      setCompanyName('');
      setUserName('');
      setPassword('');
    }

    return () => {};
  }, [open]);

  const validateInputs = () => {
    const newErrors = {
      baseUrl: '',
      companyName: '',
      userName: '',
      password: '',
    };

    let isValid = true;

    if (!baseUrl) {
      newErrors.baseUrl = 'This field is required.';
      isValid = false;
    }
    if (!companyName) {
      newErrors.companyName = 'This field is required.';
      isValid = false;
    }
    if (!userName) {
      newErrors.userName = 'This field is required.';
      isValid = false;
    }
    if (!password) {
      newErrors.password = 'This field is required.';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleAuthorizeButtonClick = async () => {
    const isValid = validateInputs();
    if (!isValid) {
      return;
    }
    const res = await dispatch(
      addDistributionOneSupplierConnection({ baseUrl, companyName, userName, password })
    );
    console.log({ res });
    if (res && res.type === 'supplier/addDistributionOneSupplierConnection/fulfilled') {
      navigate('/customers?success=true');
      navigate(0);
    } else {
      let errorMessage = '';
      if (res.type === 'supplier/addDistributionOneSupplierConnection/rejected') {
        errorMessage = res.payload as any;
      }
      dispatch(
        openMessageModal({
          buttonText: 'Close',
          modalType: 'ERROR',
          title: 'Failed to connect to Distribution one platform',
          subTitle: errorMessage || '',
          onClose: () => {},
        })
      );
    }
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="flex min-h-80 w-[30vw] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg  flex-col py-4 px-3">
        <div className="flex items-center flex-col">
          <img className="w-16 object-contain rounded-full" src={d1Logo} />
          <p className="text-sm font-bold">Distribution one</p>
          <p className="text-xs">Connection</p>
        </div>
        <div className="flex flex-col">
          <div className="my-1.5">
            <AppInputField
              label="Base Url"
              placeholder="http://.."
              value={baseUrl}
              isRequired={true}
              errorText={errors.baseUrl ? errors.baseUrl : ''}
              onTextChange={(value) => {
                setBaseUrl(value);
              }}
            />
          </div>
          <div className="my-1.5">
            <AppInputField
              label="Company name"
              value={companyName}
              isRequired={true}
              errorText={errors.companyName ? errors.companyName : ''}
              onTextChange={(value) => {
                setCompanyName(value);
              }}
            />
          </div>
          <div className="my-1.5">
            <AppInputField
              label="User Name"
              value={userName}
              isRequired={true}
              errorText={errors.userName ? errors.userName : ''}
              onTextChange={(value) => {
                setUserName(value);
              }}
            />
          </div>
          <div className="my-1.5">
            <AppInputField
              label="Password"
              inputType="password"
              isRequired={true}
              errorText={errors.password ? errors.password : ''}
              value={password}
              onTextChange={(value) => {
                setPassword(value);
              }}
            />
          </div>
          <div className="flex justify-between w-full mt-4">
            <AppButton
              buttonStyles={{ width: '100px' }}
              text="Cancel"
              type="TERTIARY"
              onClick={handleClose}
            />
            <AppButton
              buttonStyles={{ width: '100px' }}
              text="Authorize"
              onClick={handleAuthorizeButtonClick}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AppDistributionOneConnectionModal;
