import { createSlice } from '@reduxjs/toolkit';
import { LienApiResponse, ReleaseHistoryApiResponse } from '@dill/dill-shared';
import {
  createLienReleaseExtraReducer,
  getLienBuyerReleasesExtraReducer,
  getSupplierLienReleasesExtraReducer,
  updateSupplierLienSignatureExtraReducer,
  updateReleaseSendEmailExtraReducer,
  deleteReleaseExtraReducer,
  getJobReleasesExtraReducer,
  forwardReleaseExtraReducer,
  manualCreateLienReleaseExtraReducer,
  createGroupedReleaseExtraReducer,
  getSupplierReleaseHistoryExtraReducer,
  updateReleaseBySupplierExtraReducer,
  updateReleaseByMainBuyerExtraReducer,
  getReleaseByMainBuyerExtraReducer,
  getReleaseBySupplierExtraReducer,
  getMainBuyerReleaseHistoryExtraReducer,
  getInvoicesUsedOnReleasesExtraReducer,
} from '../reducers/liensReducers';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface LiensInitialState {
  loadingList: string[];
  buyerLienReleases: LienApiResponse[];
  supplierLienReleases: LienApiResponse[];
  selectedSupplierLiens: LienApiResponse[];
  selectedBuyerLiens: LienApiResponse[];
  selectedLiensRelease: LienApiResponse | null;
  loadingErrorList: { [x: string]: string[] }[];
  jobReleases: LienApiResponse[];
  selectedJobReleases: LienApiResponse[];
  selectedReleaseHistory: ReleaseHistoryApiResponse[];
}

const initialState: LiensInitialState = {
  loadingList: [],
  buyerLienReleases: [],
  supplierLienReleases: [],
  selectedBuyerLiens: [],
  selectedSupplierLiens: [],
  selectedLiensRelease: null,
  loadingErrorList: [],
  jobReleases: [],
  selectedJobReleases: [],
  selectedReleaseHistory: [],
};

export const liensSlice = createSlice({
  name: 'liens',
  initialState,
  reducers: {
    resetLiensRootState: () => initialState,
    selectSupplierLiens: (state, action: PayloadAction<any[]>) => {
      const ii = [...state.selectedSupplierLiens.map((iu) => ({ ...iu }))];
      const filteredSupplierLiens = ii.filter(
        (liens) => !action.payload.map((yy: any) => yy.id).includes(liens.id)
      );
      state.selectedSupplierLiens = [...filteredSupplierLiens, ...action.payload];
    },
    selectBuyerLiens: (state, action: PayloadAction<any[]>) => {
      const ii = [...state.selectedBuyerLiens.map((iu) => ({ ...iu }))];
      const filteredBuyerLiens = ii.filter(
        (liens) => !action.payload.map((yy: any) => yy.id).includes(liens.id)
      );
      state.selectedBuyerLiens = [...filteredBuyerLiens, ...action.payload];
    },
    selectLiensRelease: (state, action: PayloadAction<LienApiResponse | null>) => {
      state.selectedLiensRelease = action.payload;
    },
    unSelectSupplierLiens: (state, action: PayloadAction<any[]>) => {
      const filteredSupplierLiens = state.selectedSupplierLiens.filter(
        (liens) => !action.payload.map((yy: any) => yy.id).includes(liens.id)
      );
      state.selectedSupplierLiens = [...filteredSupplierLiens];
    },
    unSelectBuyerLiens: (state, action: PayloadAction<any[]>) => {
      const filteredBuyerLiens = state.selectedBuyerLiens.filter(
        (liens) => !action.payload.map((yy: any) => yy.id).includes(liens.id)
      );
      state.selectedBuyerLiens = [...filteredBuyerLiens];
    },
    selectJobReleases: (state, action: PayloadAction<any[]>) => {
      const ii = [...state.selectedJobReleases.map((iu) => ({ ...iu }))];
      const filteredJobReleases = ii.filter(
        (liens) => !action.payload.map((yy: any) => yy.id).includes(liens.id)
      );
      state.selectedJobReleases = [...filteredJobReleases, ...action.payload];
    },
    unSelectJobReleases: (state, action: PayloadAction<any[]>) => {
      const filteredJobReleases = state.selectedJobReleases.filter(
        (liens) => !action.payload.map((yy: any) => yy.id).includes(liens.id)
      );
      state.selectedJobReleases = [...filteredJobReleases];
    },
    resetJobReleases: (state) => {
      state.selectedJobReleases = [];
      state.jobReleases = [];
    },
    resetSupplierLiens: (state) => {
      state.selectedSupplierLiens = [];
    },
  },
  extraReducers(builder) {
    createLienReleaseExtraReducer(builder);
    getLienBuyerReleasesExtraReducer(builder);
    getSupplierLienReleasesExtraReducer(builder);
    updateSupplierLienSignatureExtraReducer(builder);
    getReleaseBySupplierExtraReducer(builder);
    getReleaseByMainBuyerExtraReducer(builder);
    updateReleaseBySupplierExtraReducer(builder);
    updateReleaseByMainBuyerExtraReducer(builder);
    updateReleaseSendEmailExtraReducer(builder);
    deleteReleaseExtraReducer(builder);
    getJobReleasesExtraReducer(builder);
    forwardReleaseExtraReducer(builder);
    manualCreateLienReleaseExtraReducer(builder);
    createGroupedReleaseExtraReducer(builder);
    getSupplierReleaseHistoryExtraReducer(builder);
    getMainBuyerReleaseHistoryExtraReducer(builder);
    getInvoicesUsedOnReleasesExtraReducer(builder);
  },
});

export const {
  resetLiensRootState,
  selectSupplierLiens,
  unSelectSupplierLiens,
  selectBuyerLiens,
  unSelectBuyerLiens,
  resetSupplierLiens,
  selectLiensRelease,
  selectJobReleases,
  unSelectJobReleases,
  resetJobReleases,
} = liensSlice.actions;
