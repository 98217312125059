import { useEffect, useMemo, useState } from 'react';
import './SupplierWithdrawModal.scss';
import { Modal } from '@mui/material';
import { COLORS } from '../../../../utils/colors';
import { AppButton } from '../../../general/AppButton/AppButton';
import { Bank, Checks, Plus, CreditCard } from 'phosphor-react';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { AppRadioButton } from '../../../general/AppRadioButton/AppRadioButton';
import { DillPaymentMethod, formatMoney } from '@dill/dill-shared';
import {
  getSupplierTransactions,
  supplierWithdraw,
} from '../../../../redux/services/paymentsService';
import AppAddPaymentMethodModal from '../../../general/AppAddPaymentMethodModal/AppAddPaymentMethodModal';
import { AlertOctagon, Loader, X } from 'react-feather';
import { z } from 'zod';
import moment from 'moment';
import momentTz from 'moment-timezone';

const modalModes = ['INPUT_CAPTURE', 'LOADING', 'ERROR', 'SUCCESS'] as const;
const ModalModeSchema = z.enum(modalModes);
type ModalMode = z.infer<typeof ModalModeSchema>;
const SupplierWithdrawModal = ({
  open,
  title,
  handleClose = () => {},
}: {
  title?: string;
  open: boolean;
  handleClose: () => void;
}) => {
  const dispatch = useAppDispatch();
  const [isAppAddPaymentMethodModalOpen, setIsAppAddPaymentMethodModalOpen] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<DillPaymentMethod | null>(
    null
  );
  const { amountToWithraw, supplierPaymentMethods, loadingList } = useAppSelector(
    (state) => state.payments
  );
  const [withdrawAmount, setWithdrawAmount] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [modalMode, setModalMode] = useState<ModalMode>('INPUT_CAPTURE');
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const validTimezone = momentTz.tz.zone(timezone) ? timezone : 'America/Los_Angeles';

  useEffect(() => {
    if (open) {
      setErrorMessage('');
      setModalMode('INPUT_CAPTURE');
      setSelectedPaymentMethod(null);
      setWithdrawAmount(0);
    }

    return () => {};
  }, [open]);

  const fees = useMemo(() => {
    const number = 0.0075 * withdrawAmount;
    return number.toFixed(2);
  }, [withdrawAmount]);

  const handleWithdraw = async () => {
    // supplierWithdraw;
    setErrorMessage('');
    setModalMode('LOADING');
    let isError = false;
    if (withdrawAmount < 1) {
      setErrorMessage('Enter amount to withdraw');
      isError = true;
    }
    if (withdrawAmount >= parseFloat(amountToWithraw)) {
      setErrorMessage('Cannot withdraw amount greater than the balance');
      isError = true;
    }
    if (!selectedPaymentMethod) {
      setErrorMessage('First select a withdraw method');
      isError = true;
    }

    if (isError) {
      setModalMode('INPUT_CAPTURE');
    }
    if (!isError) {
      const supplierWithdrawResults = await dispatch(
        supplierWithdraw({
          amount: Number(Number(withdrawAmount).toFixed(2)),
          supplierPaymentMethodId: selectedPaymentMethod?.id || '',
        })
      );
      if (supplierWithdrawResults.type === 'payments/supplierWithdraw/rejected') {
        if (supplierWithdrawResults?.payload && supplierWithdrawResults?.payload?.errors) {
          setErrorMessage(supplierWithdrawResults?.payload.errors[0].msg || '');
        }

        setModalMode('ERROR');
      }
      if (supplierWithdrawResults.type === 'payments/supplierWithdraw/fulfilled') {
        setErrorMessage('');
        dispatch(
          getSupplierTransactions({
            startDate: moment(new Date()).format('MMM DD, YYYY'),
            endDate: moment(new Date()).subtract(3, 'months').format('MMM DD, YYYY'),
            timezone: validTimezone,
          })
        );
        setModalMode('SUCCESS');
      }
    }
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="supplier-withdraw-modal-content px-5 py-4">
        <AppAddPaymentMethodModal
          userType="supplier"
          open={isAppAddPaymentMethodModalOpen}
          handleClose={() => {
            setIsAppAddPaymentMethodModalOpen(false);
          }}
        />
        {modalMode === 'INPUT_CAPTURE' && (
          <div style={{ minWidth: '70vw', minHeight: '65vh' }} className="">
            <h3 className="text-lg font-medium">Withdraw</h3>
            <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-200"></hr>
            <div className="flex w-full columns-2">
              <div className="w-full">
                <div className="mb-5">
                  <p className="text-xs">Amount</p>
                  <div className="flex w-full bg-white border border-slate-300 rounded-lg px-3 py-2">
                    <p className="text-sm TEXT_PRIMARY-CLR ">$</p>
                    <input
                      type={'number'}
                      className={'w-full text-sm  ' + 'TEXT_PRIMARY-CLR'}
                      name="amount"
                      value={withdrawAmount}
                      onChange={(event) => {
                        const newValue = parseFloat(event.target.value);
                        if (newValue > parseFloat(amountToWithraw)) {
                          setErrorMessage('Cannot withdraw amount greater than the balance');
                        } else {
                          setErrorMessage('');
                        }
                        setWithdrawAmount(newValue);
                      }}
                    />
                    <p className="text-sm TEXT_PRIMARY-CLR ">USD</p>
                  </div>

                  <p className="text-xs PRIMARY_500-CLR mt-1 ">
                    Available {formatMoney(amountToWithraw, 2)}
                  </p>
                </div>
                <div className="overflow-y-scroll h-3/4">
                  <div className="flex items-center justify-between">
                    <p className="text-xs font-bold">Withdraw to</p>
                    <AppButton
                      type="FLAT"
                      text="New Method"
                      icon={<Plus color={COLORS.PRIMARY_500} />}
                      onClick={() => {
                        setIsAppAddPaymentMethodModalOpen(true);
                      }}
                    />
                  </div>
                  <div className="overflow-y-scroll h-72">
                    {supplierPaymentMethods.map((paymentMethod, i) => {
                      return (
                        <div key={i + 'oo'} className="w-full px-4 my-1">
                          <button
                            className={
                              'flex flex-row w-full rounded-xl WHITE-BG px-5 py-4 border  items-center cursor-pointer ' +
                              (paymentMethod.id === selectedPaymentMethod?.id
                                ? 'PRIMARY_500-BORDER'
                                : ' GREY_300-BORDER')
                            }
                            // Disable creditcard option since it is not available
                            disabled={paymentMethod.id === 'creditCard'}
                            onClick={() => {
                              setSelectedPaymentMethod(paymentMethod);
                            }}>
                            <div className="flex mr-2 items-center">
                              <AppRadioButton
                                isChecked={
                                  selectedPaymentMethod &&
                                  paymentMethod.id === selectedPaymentMethod.id
                                    ? true
                                    : false
                                }
                              />
                              <div className="payment-type-logo">
                                {paymentMethod.type === 'BANK_ACCOUNT' ? (
                                  <Bank
                                    size={30}
                                    style={{ display: 'flex', alignSelf: 'center' }}
                                  />
                                ) : (
                                  <CreditCard
                                    size={30}
                                    style={{ display: 'flex', alignSelf: 'center' }}
                                  />
                                )}
                              </div>
                            </div>
                            <div className="flex flex-col">
                              <p className="text-sm font-bold text-left">
                                {paymentMethod.paymentMethodName}
                              </p>
                              {paymentMethod.id === 'creditCard' && (
                                <p className="accountDetails">Coming soon</p>
                              )}
                            </div>
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="w-full mx-6">
                <div className="h-56 w-full bg-slate-100 flex flex-col px-8 pt-6 pb-4 rounded-lg">
                  <p className="text-sm">Total Summary</p>
                  <div className="flex justify-between mt-10 items-center my-1">
                    <p className="text-sm ">Withdrawal Amount</p>
                    <p className="text-sm font-bold">{formatMoney(withdrawAmount, 2)}</p>
                  </div>

                  <div className="flex justify-between items-center my-1">
                    <p className="text-sm">Fees</p>
                    <p className="text-sm font-bold">{formatMoney(fees, 2)}</p>
                  </div>
                  <div className="h-10 w-full bg-white mt-3 PRIMARY_500-CLR flex justify-between items-center px-3 rounded-md">
                    <p className="text-sm">Amount to Send</p>
                    <p className="text-sm font-bold">
                      {formatMoney(withdrawAmount - Number(fees), 2)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full  absolute left-0 bottom-3 flex flex-col">
              <hr className="h-px w-full my-2 bg-gray-200 border-0 dark:bg-gray-200"></hr>
              <p className="text-xs ERROR_500-CLR text-center">{errorMessage}</p>

              <div className="flex flex-row items-center justify-end w-full">
                <AppButton
                  text={'Close'}
                  type="TERTIARY"
                  buttonStyles={{ marginLeft: '10px', marginRight: '10px' }}
                  onClick={handleClose}
                />

                <AppButton
                  text="Withdraw"
                  buttonStyles={{ marginLeft: '10px', marginRight: '10px' }}
                  onClick={() => {
                    handleWithdraw();
                  }}
                  isLoading={loadingList.length > 0}
                />
              </div>
            </div>
          </div>
        )}
        {modalMode === 'LOADING' && (
          <div style={{ width: '400px' }} className="">
            <div className="px-6 pt-6 pb-8">
              <div className="flex flex-col justify-center content-center">
                <div className="loadingImage">
                  <Loader size={64} color={COLORS.PRIMARY_500} />
                </div>
                <div className="self-center font-bold text-2xl text-center my-2">
                  Payment is processing
                </div>
                <div className="self-center text-center text-sm font-light">
                  This may take a few minutes
                </div>
              </div>
            </div>
          </div>
        )}
        {modalMode === 'ERROR' && (
          <div style={{ width: '400px' }} className="">
            <div className="flex justify-between  ">
              <h3 className="font-medium text-lg mx-4"></h3>
              <AppButton
                type="FLAT"
                buttonStyles={{ padding: '0px', margin: '5px 2px' }}
                icon={<X color={COLORS.GREY_300} size={30} />}
                onClick={handleClose}
              />
            </div>
            <div className="px-6 pt-6">
              <div className="flex flex-col justify-center content-center">
                <div className="flex self-center">
                  <AlertOctagon size={64} color={COLORS.ERROR_500} />
                </div>
                <div className="self-center font-bold text-2xl text-center my-2">
                  Withdraw initiation failed
                </div>
                <div className="self-center text-center text-sm font-light">
                  Contact 972-339-0799 for customer support
                </div>
              </div>
            </div>
            <div className="flex justify-center content-center p-8">
              <AppButton
                text="Back to Dashboard"
                type="TERTIARY"
                buttonStyles={{
                  marginLeft: '10px',
                  marginRight: '10px',
                }}
                onClick={handleClose}
              />
              <AppButton
                text="Retry"
                type="SECONDARY"
                onClick={() => {
                  handleWithdraw();
                }}
              />
            </div>
          </div>
        )}
        {modalMode === 'SUCCESS' && (
          <div style={{ width: '400px' }} className="">
            <div className="flex justify-between ">
              <h3 className="font-medium text-lg mx-4"></h3>
              <AppButton
                type="FLAT"
                buttonStyles={{ padding: '0px', margin: '5px 2px' }}
                icon={<X color={COLORS.GREY_300} size={30} />}
                onClick={handleClose}
              />
            </div>
            <div className="px-6 pt-6">
              <div className="flex flex-col justify-center content-center">
                <div className="flex self-center">
                  <Checks size={64} color={COLORS.SUCCESS_600} />
                </div>
                <div className="self-center font-bold text-2xl text-center my-2">
                  Withdraw initiation succeeded!
                </div>
                <div className="self-center text-center text-sm font-light">
                  Your payment is already on the way
                </div>
              </div>
            </div>
            <div className="flex justify-center content-center p-8">
              <AppButton
                text="Back to Dashboard"
                type="TERTIARY"
                buttonStyles={{
                  marginLeft: '10px',
                  marginRight: '10px',
                }}
                onClick={handleClose}
              />
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default SupplierWithdrawModal;
