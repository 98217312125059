import React, { useEffect, useMemo, useState } from 'react';
import {
  MapPinLine,
  CurrencyCircleDollar,
  CircleWavyCheck,
  CheckCircle,
  UserFocus,
  Buildings,
  Warning,
  CalendarBlank,
  Calendar,
  CalendarX,
  CalendarCheck,
  WarningCircle,
  CircleDashed,
  MegaphoneSimple,
  Eye,
} from 'phosphor-react';
import { AlertTriangle, Edit3, ChevronDown, ChevronUp } from 'react-feather';
import { COLORS } from '../../../../utils/colors';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import {
  formatMoney,
  getLienDeadlineDate,
  getPreLienDeadlineDate,
  getStateAbbreviation,
  PreliminaryNotice,
  SupplierJobApiResponse,
  SupplierRelease,
} from '@dill/dill-shared';
import { downloadFile, flattenPDF, openDillToast } from '../../../../utils/helpers';
import { getSupplierJob, updateSupplierJob } from '../../../../redux/services/supplierJobsService';
import moment from 'moment';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import AppEditFirstDeliveryDateModal from '../../../general/AppEditFirstDeliveryDateModal';
import emptyListImg from '../../../../assets/images/emptyList.png';
import { AppInvoiceTable } from '../../../general/AppInvoiceTable/AppInvoiceTable';
import { Ellipsis } from 'react-css-spinners';
import AppReleaseStatusPill from '../../../general/AppReleaseStatusPill/AppReleaseStatusPill';
import { AppButton } from '../../../general/AppButton/AppButton';
import { AppPdfPreviewModal } from '../../../general/AppPdfPreviewModal/AppPdfPreviewModal';
import AppCustomDropdown from '../../../general/AppCustomDropdown/AppCustomDropdown';
import { AppNewSupplierJobModal } from '../../../general/AppNewSupplierJobModal/AppNewSupplierJobModal';
import { openMessageModal } from '../../../../redux/globalSlices/genericSlice';
import { AppDeleteModal } from '../../../general/AppDeleteModal/AppDeleteModal';
import AppCreateReleaseModal from '../../../general/AppCreateReleaseModal/AppCreateReleaseModal';
import CreateSignatureModal from '../../lienWaivers/supplier/CreateSignatureModal/CreateSignatureModal';
import { getSupplierLienTemplates } from '../../../../redux/services/leinTemplatesService';
import { getUserSignatures } from '../../../../redux/services/authService';

const SupplierJobDetails = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [currentSupplierJobId, setCurrentSupplierJobId] = useState<string>('');
  const [selectedSupplierJob, setSelectedSupplierJob] = useState<SupplierJobApiResponse | null>(
    null
  );
  const [dateModalType, setDateModalType] = useState<
    'firstDeliveryDate' | 'lastDeliveryDate' | 'noticeOfCompletionDate'
  >('firstDeliveryDate');
  const [editFirstDeliveryDate, setEditFirstDeliveryDate] = useState<boolean>(false);
  const [softFirstDeliveryDate, setSoftFirstDeliveryDate] = useState<Date | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [reloading, setReloading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [selectedDropdownRelease, setSelectedDropdownRelease] = useState<string>('');
  const [showPreviewPdf, setShowPreviewPdf] = useState(false);
  const [selectedReleaseUrl, setSelectedReleaseUrl] = useState('');
  const [selectedPreLienUrl, setSelectedPreLienUrl] = useState('');
  const [openDropdown, setOpenDropDown] = useState('');
  const [editJob, setEditJob] = useState<boolean>(false);
  const [confirmMarkAsActive, setConfirmMarkAsActive] = useState<boolean>(false);
  const [openCreateReleaseModal, setOpenCreateReleaseModal] = useState<boolean>(false);
  const [signature, setSignature] = useState('');
  const [isCreateSignatureModalOpen, setIsCreateSignatureModalOpen] = useState(false);
  const [selectedPreliminaryNotice, setSelectedPreliminaryNotice] =
    useState<PreliminaryNotice | null>(null);
  const [previewMode, setPreviewMode] = useState<'PRELIMINARY_NOTICE' | 'RELEASE'>('RELEASE');
  const [releasePreviewDetails, setReleasePreviewDetails] = useState(
    {} as {
      recipient: string;
      type: string;
      paymentAmount: number | null;
      throughDate: string | Date | null;
      job: string;
      status:
        | 'Declined'
        | 'Rejected'
        | 'Requested'
        | 'Signed'
        | 'Scheduled'
        | 'Pending'
        | 'Sent to GC';
      dueDate: string | Date;
    }
  );
  const { lienTemplates } = useAppSelector((state) => state.lienTemplates);
  const { userSignatures, user } = useAppSelector((state) => state.auth);

  const convertDateToPST = (date: Date) => {
    const dtString = moment.utc(date).format('YYYY-MM-DD');
    return new Date(`${dtString}T12:00:00`); // set to 12:00 PM
  };

  const getDeliveryDate = (
    softDeliveryDt: Date | null | undefined,
    invoiceDeliveryDate: Date | null | undefined
  ) => {
    let dt = null;

    if (invoiceDeliveryDate) {
      dt = convertDateToPST(invoiceDeliveryDate);
    } else if (softDeliveryDt) {
      dt = convertDateToPST(softDeliveryDt);
    }

    return dt;
  };

  const getDeliveryDateString = (
    softDeliveryDt: Date | null | undefined,
    invoiceDeliveryDate: Date | null | undefined
  ) => {
    const dt = getDeliveryDate(softDeliveryDt, invoiceDeliveryDate);

    return dt
      ? dt.toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
      : '';
  };

  const fetchSupplierJob = async (supplierJobId: string) => {
    setErrorMessage('');
    const result = await dispatch(getSupplierJob({ supplierJobId }));
    if (result.type === 'supplierJobs/getSupplierJob/fulfilled') {
      const payload = result.payload as any;
      if (payload?.data) {
        const job: SupplierJobApiResponse = payload.data;
        setSelectedSupplierJob(job);
        setLoading(false);
      }
    } else {
      // openDillToast({ message: 'Failed to fetch supplier job.', type: 'ERROR' });
      const payload = result.payload as string;
      setErrorMessage(payload);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    setErrorMessage('');
    setSelectedDropdownRelease('');
    const pathList = location.pathname.split('/');
    const supplierJobId = pathList[pathList.length - 1];
    setCurrentSupplierJobId(supplierJobId);
    fetchSupplierJob(supplierJobId);
    setPreviewMode('RELEASE');
  }, [location]);

  const supplierJobInitial = useMemo(() => {
    if (selectedSupplierJob) {
      const selectedSupplierJobName = selectedSupplierJob?.name ?? '';
      const selectedSupplierJobNameArray = selectedSupplierJobName.split(' ');
      if (selectedSupplierJobNameArray.length > 1) {
        const letter1 = selectedSupplierJobNameArray[0].substring(0, 1);
        const letter2 = selectedSupplierJobNameArray[1].substring(0, 1);
        return `${letter1}${letter2}`.toUpperCase();
      } else {
        const letter1 = selectedSupplierJobNameArray[0].substring(0, 1);
        return `${letter1}`.toUpperCase();
      }
    }
    return '';
  }, [selectedSupplierJob]);

  const supplierJobAddress = useMemo(() => {
    if (selectedSupplierJob) {
      const selectedAddress = `${
        selectedSupplierJob?.jobAddress ? `${selectedSupplierJob?.jobAddress}, ` : ''
      }${selectedSupplierJob?.jobCity ? `${selectedSupplierJob?.jobCity}, ` : ''}${
        selectedSupplierJob?.jobState ? `${selectedSupplierJob?.jobState} ` : ''
      }${selectedSupplierJob?.jobZipCode ? `${selectedSupplierJob?.jobZipCode}, ` : ''}`;
      return selectedAddress;
    }
    return '';
  }, [selectedSupplierJob]);

  const summaryDetails = useMemo(() => {
    if (selectedSupplierJob) {
      const firstDeliveryDate = getDeliveryDate(
        selectedSupplierJob.firstInvoiceDate,
        selectedSupplierJob.firstDeliveryDate
      );

      const lastDeliveryDate = getDeliveryDate(
        selectedSupplierJob.lastInvoiceDate,
        selectedSupplierJob.lastDeliveryDate
      );

      const supplierJobState = getStateAbbreviation(selectedSupplierJob.jobState) ?? 'CA';
      const state =
        selectedSupplierJob?.supplierId === 'krAwPCRGqgEX0CZhRZva'
          ? 'CA'
          : !selectedSupplierJob.jobState &&
            selectedSupplierJob?.supplierId === 'ddEOEMrTjpVr7aj4DQjD'
          ? 'CA'
          : supplierJobState;
      const lienDateDeadlineDate = getLienDeadlineDate(
        state,
        lastDeliveryDate,
        selectedSupplierJob.noticeOfCompletionDate
      );
      const isLienDateDeadlinePassed = lienDateDeadlineDate
        ? moment().isAfter(lienDateDeadlineDate)
        : false;

      const lienDeadlineDateString = lienDateDeadlineDate
        ? lienDateDeadlineDate.toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })
        : '';

      const preLienDateDeadlineDate = getPreLienDeadlineDate(state, firstDeliveryDate);
      const isPreLienDateDeadlinePassed = preLienDateDeadlineDate
        ? moment().isAfter(preLienDateDeadlineDate)
        : false;
      const preLienDateDeadlineDateString =
        preLienDateDeadlineDate &&
        preLienDateDeadlineDate.toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        });

      const totalOpenAR =
        selectedSupplierJob?.invoices?.reduce((acc, invoice) => {
          return acc + (typeof invoice.balance === 'number' ? invoice.balance : 0);
        }, 0) || 0;
      return [
        {
          key: 'firstDeliveryDate',
          icon: (
            <CalendarBlank
              color={COLORS.PRIMARY_500}
              size={30}
              className="mr-2 p-1 rounded-full PRIMARY_100-BG"
            />
          ),
          name: 'First Furnishing Date',
          value:
            getDeliveryDateString(
              selectedSupplierJob.firstInvoiceDate,
              selectedSupplierJob.firstDeliveryDate
            ) || '',
          isVerified: firstDeliveryDate && !selectedSupplierJob.firstDeliveryDate,
          allowEdit: true,
          isPastDue: false,
        },
        {
          key: 'lastDeliveryDate',
          icon: (
            <Calendar
              color={COLORS.PRIMARY_500}
              size={30}
              className="mr-2 p-1 rounded-full PRIMARY_100-BG"
            />
          ),
          name: 'Last Furnishing Date',
          value:
            getDeliveryDateString(
              selectedSupplierJob.lastInvoiceDate,
              selectedSupplierJob.lastDeliveryDate
            ) || '',
          isVerified: lastDeliveryDate && !selectedSupplierJob.lastDeliveryDate,
          allowEdit: true,
          isPastDue: false,
        },
        {
          key: 'preLienDeadline',
          icon: (
            <CalendarX
              color={COLORS.PRIMARY_500}
              size={30}
              className="mr-2 p-1 rounded-full PRIMARY_100-BG"
            />
          ),
          name: 'Pre-Lien Deadline',
          value: preLienDateDeadlineDateString,
          isVerified: false,
          allowEdit: false,
          isPastDue: preLienDateDeadlineDate && isPreLienDateDeadlinePassed,
        },
        {
          key: 'lienDeadline',
          icon: (
            <CalendarCheck
              color={COLORS.PRIMARY_500}
              size={30}
              className="mr-2 p-1 rounded-full PRIMARY_100-BG"
            />
          ),
          name: 'Lien Deadline',
          value: lienDeadlineDateString,
          isVerified: false,
          allowEdit: false,
          isPastDue: lienDateDeadlineDate && isLienDateDeadlinePassed && totalOpenAR > 0,
        },
        {
          key: 'openAr',
          icon: (
            <CurrencyCircleDollar
              color={COLORS.PRIMARY_500}
              size={30}
              className="mr-2 p-1 rounded-full PRIMARY_100-BG"
            />
          ),
          name: 'Open AR',
          value: totalOpenAR ? formatMoney(totalOpenAR, 2) : '$0.00',
          isVerified: false,
          allowEdit: false,
          isPastDue: false,
        },
      ];
    }
    return [];
  }, [selectedSupplierJob]);

  const subUsersInvoices = useMemo(() => {
    if (selectedSupplierJob && selectedSupplierJob.invoices) {
      const result = {
        paidInvoices: selectedSupplierJob.invoices.filter((invoice) => invoice.status === 'PAID'),
        unPaidInvoices: selectedSupplierJob.invoices.filter((invoice) => invoice.status !== 'PAID'),
      };
      return result;
    }

    return {
      paidInvoices: [],
      unPaidInvoices: [],
    };
  }, [selectedSupplierJob]);

  const groupReleasesByDate = (data: SupplierRelease[]) => {
    // Helper function to get the "throughDate" or "createdAt" date
    const getRelevantDate = (release: SupplierRelease): Date | null => {
      if (release?.fieldsData) {
        const throughDateField = release?.fieldsData.find((field) => field.id === 'throughDate');
        if (throughDateField?.value) {
          return new Date(throughDateField.value);
        }
      }
      return null;
    };

    // Group data by month and year
    const grouped: {
      [key: string]: {
        type: string;
        thoughtDate: string | null;
        amount: number | null;
        status:
          | 'Requested'
          | 'Declined'
          | 'Rejected'
          | 'Signed'
          | 'Scheduled'
          | 'Pending'
          | 'Sent to GC';
        invoices: string[];
        pdfUrl: string;
      }[];
    } = {};

    data.forEach((release) => {
      const paymentAmount = release?.fieldsData
        ? release?.fieldsData.find((field) => field.id === 'paymentAmount')
        : null;
      const date = getRelevantDate(release);
      const keyDate = date ? date : release?.createdAt ? new Date(release?.createdAt) : new Date();
      const key = keyDate.toLocaleString('default', { month: 'long', year: 'numeric' });

      if (!grouped[key]) {
        grouped[key] = [];
      }
      grouped[key].push({
        type: release?.type?.toLowerCase().split('_').join(' ') || '',
        thoughtDate: date ? moment(date).format('MM/DD/YYYY') : null,
        amount: paymentAmount?.value ?? null,
        status: release.status,
        invoices: release?.invoiceList
          ? release?.invoiceList.map(
              (invoice) =>
                `Invoice #${invoice.invoiceNumber} | ${formatMoney(invoice?.amount ?? '', 2)}`
            )
          : [],
        pdfUrl: release?.previewPdf?.url ?? '',
      });
    });

    // Convert grouped object into sorted array
    return Object.keys(grouped)
      .sort((a, b) => new Date(a).getTime() - new Date(b).getTime()) // Sort by date
      .map((key) => ({
        key,
        value: grouped[key],
      }));
  };

  const release = useMemo(() => {
    if (selectedSupplierJob && selectedSupplierJob.releases) {
      const groupedRelease = groupReleasesByDate(selectedSupplierJob.releases);
      return groupedRelease;
    }
    return [];
  }, [selectedSupplierJob]);

  const handleOpenDropdownRelease = (key: string) => {
    if (selectedDropdownRelease === key) {
      setSelectedDropdownRelease('');
    } else {
      setSelectedDropdownRelease(key);
    }
  };

  const handleDownloadRelease = async (pdfUrl: string) => {
    const createdAtTimeStamp = new Date().getTime();
    downloadFile(
      pdfUrl,
      `${selectedSupplierJob?.name ? `${selectedSupplierJob.name}-` : ''}${
        selectedSupplierJob?.buyer?.name ?? ''
      }-${createdAtTimeStamp}.pdf`
    );
  };

  const handleDownloadOrPreviewRelease = (
    data: {
      type: string;
      thoughtDate: string | null;
      amount: number | null;
      status:
        | 'Requested'
        | 'Declined'
        | 'Rejected'
        | 'Signed'
        | 'Scheduled'
        | 'Pending'
        | 'Sent to GC';
      invoices: string[];
      pdfUrl: string;
    },
    mode: 'preview' | 'download'
  ) => {
    setPreviewMode('RELEASE');
    if (mode === 'download') {
      handleDownloadRelease(data.pdfUrl);
      return;
    }
    setReleasePreviewDetails({
      recipient: '',
      type: data.type,
      paymentAmount: data.amount,
      throughDate: data.thoughtDate,
      job: selectedSupplierJob?.name ?? '',
      status: data.status,
      dueDate: '',
    });
    setShowPreviewPdf(true);
  };

  const handleMarkAsActive = async () => {
    const result = await dispatch(
      updateSupplierJob({
        supplierJobId: selectedSupplierJob?.id ?? '',
        supplierJobDetails: { archivedState: ['SUPPLIER_ARCHIVED'] },
      })
    );
    if (result.type === 'supplierJobs/updateSupplierJob/fulfilled') {
      dispatch(
        openMessageModal({
          buttonText: 'Close',
          modalType: 'SUCCESS',
          title: 'Successfully marked job as inactive!',
          subTitle: `This job has been archived and is no longer on the active job list.`,
          onClose: () => {
            if (currentSupplierJobId) {
              fetchSupplierJob(currentSupplierJobId);
            }
          },
        })
      );
    } else {
      dispatch(
        openMessageModal({
          buttonText: 'Close',
          modalType: 'ERROR',
          title: 'Failed to mark the job as inactive',
          subTitle: `Contact customer support.`,
          onClose: () => {},
        })
      );
    }
  };

  const handleAddSignature = (newSignature: string, isManual: boolean) => {
    setSignature(newSignature);
    setIsCreateSignatureModalOpen(false);
  };

  const handleDownloadPreliminaryNoticePdf = async (preliminaryNotice: PreliminaryNotice) => {
    if (preliminaryNotice?.pdf?.url) {
      const flattenedPDF = await flattenPDF(preliminaryNotice?.pdf?.url);
      if (flattenedPDF) {
        const createdAtTimeStamp = preliminaryNotice?.createdAt
          ? new Date(preliminaryNotice.createdAt).getTime()
          : new Date().getTime();
        downloadFile(
          flattenedPDF || '',
          `prelien-${selectedSupplierJob?.name ?? ''}-${
            selectedSupplierJob?.buyer?.name ?? ''
          }-${createdAtTimeStamp}.pdf`
        );
      }
    }
  };

  const handlePrelienPreviewPdf = async (preliminaryNotice: PreliminaryNotice) => {
    setShowPreviewPdf(true);
    setPreviewMode('PRELIMINARY_NOTICE');
    setSelectedPreLienUrl(preliminaryNotice?.pdf?.url ?? '');
  };

  return (
    <div>
      <AppNewSupplierJobModal
        open={editJob}
        editMode={true}
        selectedSupplierJob={selectedSupplierJob}
        handleClose={() => {
          setEditJob(false);
        }}
        onReload={() => {
          if (currentSupplierJobId) {
            fetchSupplierJob(currentSupplierJobId);
          }
        }}
      />
      <AppPdfPreviewModal
        open={showPreviewPdf}
        handleClose={() => {
          setShowPreviewPdf(false);
        }}
        invoice={''}
        pdfType={previewMode}
        pdfList={[
          {
            name: previewMode === 'PRELIMINARY_NOTICE' ? 'prelien-release' : 'lien-release',
            url: previewMode === 'PRELIMINARY_NOTICE' ? selectedPreLienUrl : selectedReleaseUrl,
            date: new Date(),
          },
        ]}
        handleDownloadPdf={() => {}}
        isSigned={false}
        showPendingMessage={false}
        releaseDetails={releasePreviewDetails}
        mode="Supplier"
        showHistoryButton={false}
      />
      <AppDeleteModal
        open={confirmMarkAsActive}
        handleClose={() => setConfirmMarkAsActive(false)}
        title={`Are you sure you want to mark ${selectedSupplierJob?.name} as a inactive?`}
        subTitle="This action will archive the job. This action can't be undone"
        buttonText="No, cancel"
        secondButtonText="Yes, I’m sure"
        onSecondButtonClick={() => {
          handleMarkAsActive();
          setConfirmMarkAsActive(false);
        }}
      />
      {selectedSupplierJob && (
        <AppCreateReleaseModal
          open={openCreateReleaseModal}
          handleClose={() => {
            setOpenCreateReleaseModal(false);
            setSignature('');
          }}
          selectedJob={selectedSupplierJob}
          onSignSignature={() => {
            setIsCreateSignatureModalOpen(true);
          }}
          signature={signature}
          onEditSupplierJob={() => {
            setEditJob(true);
          }}
          onReload={async () => {
            if (currentSupplierJobId) {
              setReloading(true);
              await fetchSupplierJob(currentSupplierJobId);
              setReloading(false);
              setOpenCreateReleaseModal(false);
            }
          }}
          reloading={reloading}
        />
      )}
      <CreateSignatureModal
        open={isCreateSignatureModalOpen}
        handleClose={() => {
          setIsCreateSignatureModalOpen(false);
        }}
        addSignature={handleAddSignature}
        onDownloadRealease={() => {}}
        defaultIsManualSign={false}
      />
      {loading && (
        <div className="w-full h-full p-4">
          <div
            className="flex w-full rounded-lg WHITE-BG p-4 items-center justify-center "
            style={{ height: `calc(95vh - 100px)` }}>
            <Ellipsis color="#3762FB" size={70} />
          </div>
        </div>
      )}
      {!loading && errorMessage && (
        <div className="w-full h-full p-4">
          <div
            className="flex w-full rounded-lg WHITE-BG p-4 items-center justify-center "
            style={{ height: `calc(95vh - 100px)` }}>
            <div className="flex flex-col items-center">
              <img className="w-3/6 object-contain" src={emptyListImg} alt="" />
              <div className="font-bold text-[18px]">Error loading supplier job details.</div>
              <div>{errorMessage}</div>
            </div>
          </div>
        </div>
      )}
      {!loading && !errorMessage && (
        <div className="w-full h-full p-4">
          <AppEditFirstDeliveryDateModal
            open={editFirstDeliveryDate}
            handleClose={() => {
              setSoftFirstDeliveryDate(null);
              setEditFirstDeliveryDate(false);
            }}
            dateType={dateModalType}
            selectedSupplierJob={selectedSupplierJob}
            softDeliveryDate={softFirstDeliveryDate}
            onReload={() => {
              if (currentSupplierJobId) {
                fetchSupplierJob(currentSupplierJobId);
              }
            }}
          />
          <div className="w-full rounded-lg WHITE-BG p-4">
            <div className="flex items-center">
              {supplierJobInitial && (
                <div className="flex PRIMARY_100-BG PRIMARY_500-CLR h-[70px] w-[70px] text-base rounded-full items-center justify-center font-medium mr-2">
                  {supplierJobInitial}
                </div>
              )}
              <div className="w-full flex justify-between items-center">
                <div>
                  <div className="flex items-center mb-1">
                    <div className="text-[24px] font-bold">{selectedSupplierJob?.name ?? ''}</div>
                    <div>
                      <CircleWavyCheck
                        size={20}
                        className="mx-2"
                        color={COLORS.INFO_600}
                        weight="fill"
                      />
                    </div>
                    {(
                      selectedSupplierJob?.preLienStatus
                        ? selectedSupplierJob?.preLienStatus
                        : selectedSupplierJob?.preLienNotice
                    ) ? (
                      <div className="flex SUCCESS_500-BG items-center px-3 py-1 rounded-full">
                        <CheckCircle size={18} className="mr-2" color={COLORS.WHITE} />
                        <div className="text-[14px] WHITE-CLR font-semibold">Done</div>
                      </div>
                    ) : (
                      <div className="flex ERROR_500-BG items-center px-3 py-1 rounded-full">
                        <Warning size={18} className="mr-2" color={COLORS.WHITE} />
                        <div className="text-[14px] WHITE-CLR font-semibold">Not Yet</div>
                      </div>
                    )}
                  </div>

                  <div className="flex">
                    {selectedSupplierJob?.buyer?.name && (
                      <div className="flex items-center GREY_500-CLR font-semibold text-sm">
                        <UserFocus size={20} className="mr-1" />
                        <div>{selectedSupplierJob?.buyer?.name}</div>
                      </div>
                    )}
                    {selectedSupplierJob?.owner && (
                      <div className="flex items-center GREY_500-CLR font-semibold text-sm">
                        <div className="PRIMARY_200-BG mr-2 rounded-full ml-2 h-[5px] w-[5px]" />
                        <Buildings size={20} className="mr-1" />
                        <div>{selectedSupplierJob?.owner}</div>
                      </div>
                    )}
                    {supplierJobAddress && (
                      <div className="flex items-center GREY_500-CLR font-semibold text-sm">
                        <div className="PRIMARY_200-BG mr-2 rounded-full ml-2 h-[5px] w-[5px]" />
                        <MapPinLine size={20} className="mr-1" />
                        <div>{supplierJobAddress}</div>
                      </div>
                    )}
                  </div>
                </div>
                {selectedSupplierJob && (
                  <div className="px-4 flex">
                    <AppButton
                      buttonStyles={{ width: '100%', marginLeft: '8px', height: '35px' }}
                      text="Create Lien Release"
                      type="PRIMARY"
                      onClick={async () => {
                        if (lienTemplates.length === 0) {
                          await dispatch(getSupplierLienTemplates());
                        }
                        if (userSignatures.length === 0) {
                          await dispatch(getUserSignatures());
                        }
                        setOpenCreateReleaseModal(true);
                      }}
                    />
                    <AppCustomDropdown
                      inputFieldStyles={{ width: '35px', marginLeft: '4px' }}
                      onSelect={(val) => {
                        if (val === 'edit') {
                          setEditJob(true);
                        }
                        if (val === 'mark-as-inactive') {
                          setConfirmMarkAsActive(true);
                        }
                        if (val === 'createPreliminaryNotice') {
                          navigate(`/preliminaryNotices/overview/addPreliminaryNotice`, {
                            state: { jobId: selectedSupplierJob.id },
                          });
                        }
                      }}
                      options={
                        selectedSupplierJob.archivedState?.includes('SUPPLIER_ARCHIVED')
                          ? [
                              {
                                value: 'edit',
                                label: `${
                                  user?.userSupplier?.ownerVerificationEnabled
                                    ? 'Edit & Verify'
                                    : 'Edit'
                                }`,
                                icon: <Edit3 size={16} />,
                              },
                              {
                                value: 'createPreliminaryNotice',
                                label: 'Create Preliminary Notice',
                                icon: <MegaphoneSimple size={16} />,
                              },
                            ]
                          : [
                              {
                                value: 'edit',
                                label: `${
                                  user?.userSupplier?.ownerVerificationEnabled
                                    ? 'Edit & Verify'
                                    : 'Edit'
                                }`,
                                icon: <Edit3 size={16} />,
                              },
                              {
                                value: 'mark-as-inactive',
                                label: 'Mark as Inactive',
                                icon: <CircleDashed size={16} />,
                              },
                              {
                                value: 'createPreliminaryNotice',
                                label: 'Create Preliminary Notice',
                                icon: <MegaphoneSimple size={16} />,
                              },
                            ]
                      }
                      width="220px"
                      isDisabled={status === 'Inactive'}
                      open={openDropdown === selectedSupplierJob.id}
                      onOpen={() => setOpenDropDown(selectedSupplierJob.id)}
                      onClose={() => setOpenDropDown('')}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="mt-4 flex w-full overflow-x-auto">
              {summaryDetails &&
                summaryDetails.length > 0 &&
                summaryDetails.map((summary) => {
                  return (
                    <div
                      key={summary.key}
                      className="GREY_200-BORDER border min-w-[180px] p-4 rounded-lg mr-4">
                      <div className="flex items-center mb-2">
                        {summary.icon}
                        <div className="text-xs GREY_500-CLR font-medium tracking-wider uppercase">
                          {summary.name}
                        </div>
                      </div>
                      <div
                        className={`flex items-center ${summary.isPastDue ? 'text-red-500' : ''}`}>
                        {summary.isVerified && (
                          <div
                            data-tooltip-id="soft-delivery-date-tooltip"
                            className="w-20px h-20px flex justify-center items-center rounded-full mr-2">
                            <AlertTriangle size={18} color="#FDB022" />
                          </div>
                        )}
                        {summary.isPastDue && (
                          <WarningCircle
                            data-tooltip-id={`deadline-tooltip`}
                            size={18}
                            className="mr-1"
                          />
                        )}
                        <div className="font-bold text-[18px] ">{summary.value}</div>
                        {summary.allowEdit && (
                          <button
                            className="ml-2"
                            onClick={(e) => {
                              e.preventDefault();
                              if (selectedSupplierJob) {
                                if (summary.value) {
                                  const date = new Date(summary.value);
                                  setSoftFirstDeliveryDate(date);
                                }
                                const key = summary.key as 'firstDeliveryDate' | 'lastDeliveryDate';
                                setDateModalType(key);
                                setSelectedSupplierJob(selectedSupplierJob);
                                setEditFirstDeliveryDate(true);
                              }
                            }}>
                            <Edit3 size={16} />
                          </button>
                        )}
                      </div>
                      <ReactTooltip
                        id={'soft-delivery-date-tooltip'}
                        place="top"
                        variant="dark"
                        opacity={'100%'}
                        style={{
                          background: '#222',
                          width: '300px',
                          zIndex: 99999,
                        }}>
                        <span>
                          Please confirm delivery date. This is a best guess based on the invoice
                          date.
                        </span>
                      </ReactTooltip>
                      <ReactTooltip
                        id={`deadline-tooltip`}
                        place="top"
                        variant="dark"
                        opacity={'100%'}
                        style={{
                          background: '#222',
                          zIndex: 99999,
                        }}>
                        <div className="flex flex-col">
                          <span className="text-xs capitalize">Overdue</span>
                        </div>
                      </ReactTooltip>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="flex mt-4">
            <div className="w-full rounded-lg WHITE-BG mr-2">
              <div className="p-4 border-b GREY_200-BORDER font-bold">Invoices</div>
              <div className="p-4 w-full overflow-x-scroll">
                {selectedSupplierJob?.invoices && selectedSupplierJob?.invoices.length > 0 ? (
                  <AppInvoiceTable
                    invoices={subUsersInvoices}
                    onSelectInvoice={() => {}}
                    paidInvoicesWeeksAgo={8}
                    tableStyle={{ height: `calc(100vh - 600px)`, maxHeight: `calc(100vh - 600px)` }}
                  />
                ) : (
                  <div className="flex flex-col items-center my-6">
                    <img className="w-3/6 object-contain" src={emptyListImg} alt="" />
                    <p>No invoices</p>
                  </div>
                )}
              </div>
            </div>
            <div
              className="w-full ml-4 overflow-scroll pb-2"
              style={{ height: `calc(100vh - 380px)` }}>
              {selectedSupplierJob?.preLienNotice && (
                <div className="w-full rounded-lg WHITE-BG mb-4">
                  <div className="p-4 border-b GREY_200-BORDER font-bold">Preliminary Notice</div>
                  <div>
                    <div className="flex justify-between w-full rounded-lg WHITE-BG mt-2 p-4">
                      <div className="flex flex-col">
                        <div className="grid grid-cols-2 gap-4">
                          <div className="flex w-full flex-col">
                            <p className="text-xs font-semibold mb-1 TEXT_SECONDARY-CLR">Status</p>
                            <AppReleaseStatusPill status={'Signed'} />
                          </div>
                          <div className="flex w-full flex-col">
                            <p className="text-xs font-semibold mb-1 TEXT_SECONDARY-CLR">
                              Created At
                            </p>
                            <p className="text-xs font-bold capitalize">
                              {selectedSupplierJob?.preLienNotice.createdAt
                                ? moment(selectedSupplierJob?.preLienNotice.createdAt).format(
                                    'MM/DD/YYYY'
                                  )
                                : ''}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-row">
                        <AppButton
                          type="TERTIARY"
                          buttonStyles={{ marginRight: '10px', width: '35px', height: '35px' }}
                          icon={<Eye size={18} />}
                          onClick={(e: any) => {
                            if (selectedSupplierJob?.preLienNotice) {
                              setSelectedPreliminaryNotice(selectedSupplierJob?.preLienNotice);
                              handlePrelienPreviewPdf(selectedSupplierJob?.preLienNotice);
                            }
                          }}
                        />
                        <AppButton
                          text={'Download'}
                          buttonStyles={{ marginBottom: '10px', height: '35px' }}
                          onClick={(e: any) => {
                            if (selectedSupplierJob?.preLienNotice) {
                              handleDownloadPreliminaryNoticePdf(
                                selectedSupplierJob?.preLienNotice
                              );
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="rounded-lg WHITE-BG w-full">
                <div className="p-4 border-b GREY_200-BORDER font-bold">Lien Release History</div>
                <div className="p-4">
                  {release.length === 0 && (
                    <div className="flex flex-col items-center my-6">
                      <img className="w-3/6 object-contain" src={emptyListImg} alt="" />
                      <p>No Release Found</p>
                    </div>
                  )}
                  {release.length > 0 && (
                    <div>
                      {release.map((item, i) => (
                        <div
                          key={`supplier_release_job_details_${i}_${item.key}`}
                          className="GREY_100-BG rounded-lg p-4 mb-2"
                          onClick={(e) => {
                            if (e.defaultPrevented) return;
                            handleOpenDropdownRelease(item.key);
                          }}>
                          <div className="flex justify-between items-center">
                            <div className="font-bold">{item.key}</div>
                            {selectedDropdownRelease === item.key ? (
                              <ChevronUp size={24} color={COLORS.GREY_500} />
                            ) : (
                              <ChevronDown size={24} color={COLORS.GREY_500} />
                            )}
                          </div>
                          {item.value && selectedDropdownRelease === item.key && (
                            <div>
                              {item.value.map((itemDetails, index) => (
                                <div
                                  key={`${item.key}_release_details_${index}`}
                                  className="flex justify-between w-full rounded-lg WHITE-BG mt-2 p-4">
                                  <div className="flex flex-col">
                                    <div className="grid grid-cols-2 gap-4">
                                      <div className="flex w-full flex-col">
                                        <p className="text-xs font-semibold mb-1 TEXT_SECONDARY-CLR">
                                          Type
                                        </p>
                                        <p className="text-xs font-bold capitalize">
                                          {itemDetails.type}
                                        </p>
                                      </div>
                                      {itemDetails.thoughtDate && (
                                        <div className="flex w-full flex-col">
                                          <p className="text-xs font-semibold mb-1 TEXT_SECONDARY-CLR">
                                            Through Date
                                          </p>
                                          <p className="text-xs font-bold capitalize">
                                            {itemDetails.thoughtDate}
                                          </p>
                                        </div>
                                      )}
                                      {itemDetails.amount ? (
                                        <div className="flex w-full flex-col">
                                          <p className="text-xs font-semibold mb-1 TEXT_SECONDARY-CLR">
                                            Amount
                                          </p>
                                          <p className="text-xs font-bold capitalize">{`${formatMoney(
                                            itemDetails.amount,
                                            2
                                          )}`}</p>
                                        </div>
                                      ) : null}
                                      <div className="flex w-full flex-col">
                                        <p className="text-xs font-semibold mb-1 TEXT_SECONDARY-CLR">
                                          Status
                                        </p>
                                        <AppReleaseStatusPill status={itemDetails.status} />
                                      </div>
                                    </div>
                                    {itemDetails.invoices.length > 0 && (
                                      <div className="flex flex-col mt-4 w-5/6">
                                        <p className="text-xs font-semibold mb-1 TEXT_SECONDARY-CLR">
                                          Invoices
                                        </p>
                                        <div className="flex flex-wrap">
                                          {itemDetails.invoices.map((invoice, j) => (
                                            <div
                                              key={`${item.key}_invoice_${index}_${j}`}
                                              className="GREY_100-BG px-2 py-1 rounded-full w-fit text-xs TEXT_SECONDARY-CLR mb-2">
                                              {invoice}
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  <div className="flex flex-col">
                                    <AppButton
                                      text={'Download'}
                                      buttonStyles={{ marginBottom: '10px' }}
                                      onClick={(e: any) => {
                                        e.preventDefault();
                                        setSelectedReleaseUrl(itemDetails?.pdfUrl);
                                        handleDownloadOrPreviewRelease(itemDetails, 'download');
                                      }}
                                    />
                                    <AppButton
                                      type="SECONDARY"
                                      text={'Preview'}
                                      buttonStyles={{ marginBottom: '10px', width: '100%' }}
                                      onClick={(e: any) => {
                                        e.preventDefault();
                                        setSelectedReleaseUrl(itemDetails?.pdfUrl);
                                        handleDownloadOrPreviewRelease(itemDetails, 'preview');
                                      }}
                                    />
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SupplierJobDetails;
