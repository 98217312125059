import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppServerResponse } from '../../utils/interfaces/generalInterfaces';
import axios from 'axios';
import { BASE_API_URL } from '../../utils/constants';
import { logoutUser } from './authService';
import { myFirebaseAuth } from '../../firebase/config';
import { SupplierJobUpdate, SupplierJobCreate } from '@dill/dill-shared';

export const createSupplierJob = createAsyncThunk(
  'supplierJobs/createSupplierJob',
  async (
    {
      supplierJobDetails,
    }: {
      supplierJobDetails: SupplierJobCreate;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const response = await axios.post<any>(
        `${BASE_API_URL}/suppliers/supplierJobs/createSupplierJob`,
        {
          ...supplierJobDetails,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const updateSupplierJob = createAsyncThunk(
  'supplierJobs/updateSupplierJob',
  async (
    {
      supplierJobDetails,
      supplierJobId,
    }: {
      supplierJobDetails: SupplierJobUpdate;
      supplierJobId: string;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.put<any>(
        `${BASE_API_URL}/suppliers/supplierJobs/updateSupplierJob/${supplierJobId}`,
        { ...supplierJobDetails },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const getSupplierJobs = createAsyncThunk(
  'supplierJobs/getSupplierJobs',
  async (
    {
      includeInvoices,
      includePreLiens,
      isFetchAll,
      previousLastDocumentId,
      pageSize,
      sortBy,
      sortOrder,
    }: {
      includeInvoices: boolean;
      includePreLiens: boolean;
      isFetchAll?: boolean;
      previousLastDocumentId?: string;
      pageSize?: number;
      sortBy: string;
      sortOrder: 'asc' | 'desc';
      isHideLoadingIndicator?: boolean;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      let params: any = {};
      if (previousLastDocumentId) {
        params = { ...params, previousLastDocumentId };
      }
      if (sortBy) {
        params = { ...params, sortBy };
      }
      if (pageSize) {
        params = { ...params, pageSize };
      }
      if (sortOrder) {
        params = { ...params, sortOrder };
      }
      if (includeInvoices !== undefined) {
        params = { ...params, includeInvoices };
      }
      if (includePreLiens !== undefined) {
        params = { ...params, includePreLiens };
      }
      if (isFetchAll !== undefined) {
        params = { ...params, isFetchAll };
      }

      const url = BASE_API_URL + '/suppliers/supplierJobs/getSupplierJobs';

      const response = await axios.get<AppServerResponse>(url, {
        params,
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
      return response.data;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      } else if (error?.response?.data?.errors && error?.response?.data?.errors.length > 0) {
        return thunkApi.rejectWithValue(error?.response?.data?.errors[0]?.msg);
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const getSupplierJob = createAsyncThunk(
  'supplierJobs/getSupplierJob',
  async ({ supplierJobId }: { supplierJobId: string }, thunkApi) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const response = await axios.get<AppServerResponse>(
        BASE_API_URL + `/suppliers/supplierJobs/getSupplierJob/${supplierJobId}`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      if (error?.response?.data?.errors && error?.response?.data?.errors.length > 0) {
        return thunkApi.rejectWithValue(error?.response?.data?.errors[0]?.msg);
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const recursiveGetSupplierJobs = createAsyncThunk(
  'supplierJobs/recursiveGetSupplierJobs',
  async (
    {
      includeInvoices,
      includePreLiens,
      isFetchAll,
      previousLastDocumentId,
      pageSize,
      sortBy,
      sortOrder,
    }: {
      includeInvoices?: boolean;
      includePreLiens?: boolean;
      isFetchAll?: boolean;
      previousLastDocumentId?: string;
      pageSize?: number;
      sortBy: string;
      sortOrder: 'asc' | 'desc';
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      let params: any = {};
      if (previousLastDocumentId) {
        params = { ...params, previousLastDocumentId };
      }
      if (sortBy) {
        params = { ...params, sortBy };
      }
      if (pageSize) {
        params = { ...params, pageSize };
      }
      if (sortOrder) {
        params = { ...params, sortOrder };
      }
      if (includeInvoices !== undefined) {
        params = { ...params, includeInvoices };
      }
      if (includePreLiens !== undefined) {
        params = { ...params, includePreLiens };
      }
      if (isFetchAll !== undefined) {
        params = { ...params, isFetchAll };
      }

      const url = BASE_API_URL + '/suppliers/supplierJobs/getSupplierJobs';

      const response = await axios.get<AppServerResponse>(url, {
        params,
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
      return response.data;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      } else if (error?.response?.data?.errors && error?.response?.data?.errors.length > 0) {
        return thunkApi.rejectWithValue(error?.response?.data?.errors[0]?.msg);
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const bulkUpdateSupplierJobs = createAsyncThunk(
  'supplierJobs/bulkUpdateSupplierJobs',
  async (
    {
      supplierJobDetails,
      supplierJobId,
    }: {
      supplierJobDetails: SupplierJobUpdate;
      supplierJobId: string;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.put<any>(
        `${BASE_API_URL}/suppliers/supplierJobs/bulkUpdateSupplierJobs/${supplierJobId}`,
        { ...supplierJobDetails },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
