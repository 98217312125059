import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { BuyerPaymentTable } from '../BuyerPaymentTable/BuyerPaymentTable';
import { BuyerPaymentMethods } from '../BuyerPaymentMethods/BuyerPaymentMethods';
import {
  getBuyerTransactions,
  getBuyerTransactionsFiles,
  getMainBuyerPaymentMethods,
} from '../../../../redux/services/paymentsService';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useParamMainBuyerId } from '../../../../utils/hooks/useParamMainBuyerId';
import { Calendar, Export } from 'phosphor-react';
import moment from 'moment';
import momentTz from 'moment-timezone';
import AppDateRangePickerModal from '../../../general/AppDateRangePickerModal/AppDateRangePickerModal';
import { AppButton } from '../../../general/AppButton/AppButton';
import { COLORS } from '../../../../utils/colors';

export const BuyerPayments = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.auth);
  const [selectedMainBuyerId] = useParamMainBuyerId();
  const [dateRange, setDateRange] = useState<{
    startDate?: Date;
    endDate?: Date;
  }>({
    startDate: moment(new Date()).subtract(3, 'months').toDate(),
    endDate: new Date(),
  });
  const [isAppDateRangePickerModalOpen, setIsAppDateRangePickerModalOpen] = useState(false);
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const validTimezone = momentTz.tz.zone(timezone) ? timezone : 'America/Los_Angeles';
  useEffect(() => {
    const mainRoles = user?.roles.filter((role) => role !== 'DILL_ADMIN');
    if (mainRoles?.includes('BUYER') && selectedMainBuyerId) {
      dispatch(
        getBuyerTransactions({
          mainBuyerId: selectedMainBuyerId,
          startDate: dateRange?.startDate
            ? moment(dateRange?.startDate).format('MMM DD, YYYY')
            : '',
          endDate: dateRange?.endDate ? moment(dateRange?.endDate).format('MMM DD, YYYY') : '',
          timezone: validTimezone,
        })
      );
      dispatch(getMainBuyerPaymentMethods({ mainBuyerId: selectedMainBuyerId }));
    }
  }, [selectedMainBuyerId, location.pathname, user?.roles]);

  useEffect(() => {
    if (dateRange && selectedMainBuyerId) {
      dispatch(
        getBuyerTransactions({
          mainBuyerId: selectedMainBuyerId || '',
          startDate: dateRange?.startDate
            ? moment(dateRange?.startDate).format('MMM DD, YYYY')
            : '',
          endDate: dateRange?.endDate ? moment(dateRange?.endDate).format('MMM DD, YYYY') : '',
          timezone: validTimezone,
        })
      );
    }

    return () => {};
  }, [dateRange]);
  const handleExportTransactions = async () => {
    await dispatch(
      getBuyerTransactionsFiles({
        mainBuyerId: selectedMainBuyerId || '',
        startDate: dateRange?.startDate ? moment(dateRange?.startDate).format('MMM DD, YYYY') : '',
        endDate: dateRange?.endDate ? moment(dateRange?.endDate).format('MMM DD, YYYY') : '',
      })
    );
  };

  return (
    <div className="w-full h-full p-8">
      <AppDateRangePickerModal
        open={isAppDateRangePickerModalOpen}
        selectedDateRange={dateRange}
        handleClose={() => {
          setIsAppDateRangePickerModalOpen(false);
        }}
        onSelectDateRange={(selection) => {
          setIsAppDateRangePickerModalOpen(false);
          setDateRange(selection);
        }}
      />
      <div className="flex justify-between">
        <div className="flex items-center">
          <div className="text-[24px] font-semibold items-center">Payment History</div>
          <div className="flex flex-row items-center">
            <button
              onClick={() => setIsAppDateRangePickerModalOpen(true)}
              className="flex flex-row items-center GREY_300-BORDER border ml-2 rounded py-1 px-2 GREY_100-BG hover:bg-slate-200">
              <Calendar className="text-slate-500 mr-2" size={20} />
              <div className="text-sm TEXT_PRIMARY-CLR">{`${
                dateRange?.startDate ? moment(dateRange?.startDate).format('MMM DD, YYYY') : ''
              }-${
                dateRange?.endDate ? moment(dateRange?.endDate).format('MMM DD, YYYY') : ''
              }`}</div>
            </button>
          </div>
        </div>
        <div className="">
          {/* <div className="mx-1">
            <AppButton
              type="TERTIARY"
              text="Export Data"
              onClick={() => {
                handleExportTransactions();
              }}
              icon={<Export color={COLORS.GREY_500} size={19} />}
            />
          </div> */}
        </div>
      </div>
      <div className="h-3/6">
        <BuyerPaymentTable />
      </div>
      <BuyerPaymentMethods />
    </div>
  );
};
