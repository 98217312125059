import { useEffect, useMemo, useState } from 'react';

import * as XLSX from 'xlsx';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ArrowDownLeft,
  ArrowURightDown,
  Bank,
  Calendar,
  Coins,
  CreditCard,
  Export,
} from 'phosphor-react';

import { DillTransaction, formatMoney } from '@dill/dill-shared';

import { COLORS } from '../../../../utils/colors';
import { AppButton } from '../../../general/AppButton/AppButton';
import emptyListImg from '../../../../assets/images/emptyList.png';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { AppPaidInvoicesModal } from '../../../general/AppPaidInvoicesModal/AppPaidInvoicesModal';
import {
  getSupplierPaymentsFiles,
  getSupplierTransactions,
  getTransactionById,
} from '../../../../redux/services/paymentsService';
import AppTransactionStatusPill from '../../../general/AppTransactionStatusPill/AppTransactionStatusPill';
import { SupplierAddWillCallPaymentModal } from '../SupplierAddWillCallPaymentModal/SupplierAddWillCallPaymentModal';

import './SupplierPaymentTable.scss';
import { AppRangeCalender } from '../../../general/AppRangeCalender/AppRangeCalender';
import { DateRangePicker } from 'react-date-range';
import AppDateRangePickerModal from '../../../general/AppDateRangePickerModal/AppDateRangePickerModal';
import { getFirstLetters } from '../../../../utils/helpers';

export const SupplierPaymentTable = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [openPaidInvoiceDetails, setOpenPaidInvoiceDetails] = useState(false);
  const [isSupplierAddWillCallPaymentModal, setIsSupplierAddWillCallPaymentModal] = useState(false);
  const [reciepient, setReciepient] = useState('' as string);
  const [sender, setSender] = useState('' as string);
  const [selectedStatus, setSelectedStatus] = useState('' as string);
  const [selectedDate, setSelectedDate] = useState('' as string);
  const [selectedPaymentName, setSelectedPaymentName] = useState('' as string);
  const { supplierTransactions } = useAppSelector((state) => state.payments);
  const [transactionError, setTransactionError] = useState('' as string | undefined | null);
  const [paymentType, setPaymentType] = useState('' as string);
  const [paymentAmount, setPaymentAmount] = useState(0 as number);
  const [partialPaymentReason, setPartialPaymentReason] = useState('' as string | undefined | null);
  const [isAppDateRangePickerModalOpen, setIsAppDateRangePickerModalOpen] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState<DillTransaction | null>(null);
  const [dateRange, setDateRange] = useState<{
    startDate?: Date;
    endDate?: Date;
  }>({
    startDate: moment(new Date()).subtract(3, 'months').toDate(),
    endDate: new Date(),
  });
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const validTimezone = momentTz.tz.zone(timezone) ? timezone : 'America/Los_Angeles';

  const columns = useMemo(() => {
    const currentColumns = [
      { id: 'name', name: '', width: 150, cellType: 'VIEW_TEXT' },
      { id: 'amount', name: 'Amount', width: 100, cellType: 'VIEW_TEXT' },
      { id: 'createdAt', name: 'Date', width: 100, cellType: 'VIEW_TEXT' },
      { id: 'transactionStatus', name: 'Transaction Status', width: 100, cellType: 'VIEW_TEXT' },
      {
        id: 'reconciliationStatus',
        name: 'Reconciliation Status',
        width: 100,
        cellType: 'VIEW_TEXT',
      },
      { id: 'method', name: 'Method', width: 150, cellType: 'VIEW_TEXT' },
      { id: 'viewDetails', name: '', width: 100, cellType: 'VIEW_TEXT' },
    ];
    return currentColumns;
  }, []);
  const totalWidth = useMemo(
    () =>
      columns.reduce((curr, prev) => {
        return curr + prev.width;
      }, 0),
    [columns]
  );

  const handleShowPaidInvoices = async (
    receiver: string,
    senderName: string,
    transactionStatus: string,
    transactionErrorMsg: string | undefined | null,
    paymentTransactionType: string,
    transactionAmount: number,
    partialPayment: string | undefined | null,
    payment: DillTransaction
  ) => {
    const transactionResults = await dispatch(getTransactionById({ transactionId: payment.id }));
    let finalTransaction = payment;
    if (transactionResults.type === 'payments/getTransactionById/fulfilled') {
      const res = transactionResults?.payload as any;
      finalTransaction = res.data as DillTransaction;
    }
    setReciepient(receiver);
    if (payment.type === 'WILL_CALL_PAYMENT') {
      setSender('WILL CALL');
    } else {
      setSender(senderName);
    }
    setSelectedStatus(transactionStatus);
    setTransactionError(transactionErrorMsg);
    setPaymentType(paymentTransactionType);
    setPaymentAmount(transactionAmount);
    setPartialPaymentReason(partialPayment);
    setSelectedTransaction(finalTransaction);
    setSelectedDate(moment(payment.createdAt).format('MMM DD, YYYY'));
    setSelectedPaymentName(payment?.paymentMethod?.paymentMethodName ?? '');
    setOpenPaidInvoiceDetails(true);
  };

  useEffect(() => {
    if (location.pathname.startsWith('/payments')) {
      const params = new URLSearchParams(location.search);
      const transactionId = params.get('transactionId');
      if (transactionId) {
        const transaction = supplierTransactions.find((trans) => trans.id === transactionId);
        if (transaction) {
          handleShowPaidInvoices(
            transaction?.recipient?.name ?? '',
            transaction?.buyer?.name ?? '',
            transaction.transactionStatus,
            transaction.transactionErrorMessage,
            transaction.type,
            transaction.totalAmountPaid,
            transaction.partialPaymentReason,
            transaction
          );
        }
      }
    }
  }, [location.pathname, supplierTransactions]);

  const handleExportTransactions = async () => {
    await dispatch(
      getSupplierPaymentsFiles({
        startDate: dateRange?.startDate ? moment(dateRange?.startDate).format('MMM DD, YYYY') : '',
        endDate: dateRange?.endDate ? moment(dateRange?.endDate).format('MMM DD, YYYY') : '',
      })
    );
  };

  const handleViewMainPayment = (val: string) => {
    const transaction = supplierTransactions.find((trans) => trans.id === val);
    if (transaction) {
      navigate(`/payments?transactionId=${val}`);
      handleShowPaidInvoices(
        transaction?.recipient?.name ?? '',
        transaction?.buyer?.name ?? '',
        transaction.transactionStatus,
        transaction.transactionErrorMessage,
        transaction.type,
        transaction.totalAmountPaid,
        transaction.partialPaymentReason,
        transaction
      );
    }
  };
  useEffect(() => {
    if (dateRange) {
      dispatch(
        getSupplierTransactions({
          startDate: dateRange?.startDate
            ? moment(dateRange?.startDate).format('MMM DD, YYYY')
            : '',
          endDate: dateRange?.endDate ? moment(dateRange?.endDate).format('MMM DD, YYYY') : '',
          timezone: validTimezone,
        })
      );
    }

    return () => {};
  }, [dateRange]);

  return (
    <div className=" h-full">
      <AppPaidInvoicesModal
        open={openPaidInvoiceDetails}
        handleClose={() => {
          setOpenPaidInvoiceDetails(false);
          if (location.pathname.startsWith('/payments')) {
            navigate(`/payments`);
          }
        }}
        paymentTo={reciepient}
        paymentFrom={sender}
        status={selectedStatus}
        accountType="supplier"
        transactionError={transactionError}
        paymentType={paymentType}
        transactionAmount={paymentAmount}
        partialPaymentReason={partialPaymentReason}
        selectedTransaction={selectedTransaction}
        selectedDate={selectedDate}
        selectedPaymentName={selectedPaymentName}
        onViewMainPayment={handleViewMainPayment}
      />
      <SupplierAddWillCallPaymentModal
        open={isSupplierAddWillCallPaymentModal}
        handleClose={() => {
          setIsSupplierAddWillCallPaymentModal(false);
        }}
        handlePaymentDone={() => {
          setIsSupplierAddWillCallPaymentModal(false);
          dispatch(
            getSupplierTransactions({
              startDate: dateRange?.startDate
                ? moment(dateRange?.startDate).format('MMM DD, YYYY')
                : '',
              endDate: dateRange?.endDate ? moment(dateRange?.endDate).format('MMM DD, YYYY') : '',
              timezone: validTimezone,
            })
          );
        }}
      />
      <AppDateRangePickerModal
        open={isAppDateRangePickerModalOpen}
        selectedDateRange={dateRange}
        handleClose={() => {
          setIsAppDateRangePickerModalOpen(false);
        }}
        onSelectDateRange={(selection) => {
          setIsAppDateRangePickerModalOpen(false);
          setDateRange(selection);
        }}
      />

      <div className="flex justify-between">
        <div className="flex items-center">
          <div className="text-[24px] font-semibold items-center">Transaction History</div>
          <div className="flex flex-row items-center">
            <button
              onClick={() => setIsAppDateRangePickerModalOpen(true)}
              className="flex flex-row items-center GREY_300-BORDER border ml-2 rounded py-1 px-2 GREY_100-BG hover:bg-slate-200">
              <Calendar className="text-slate-500 mr-2" size={20} />
              <div className="text-sm TEXT_PRIMARY-CLR">{`${
                dateRange?.startDate ? moment(dateRange?.startDate).format('MMM DD, YYYY') : ''
              }-${
                dateRange?.endDate ? moment(dateRange?.endDate).format('MMM DD, YYYY') : ''
              }`}</div>
            </button>
          </div>
        </div>
        <div className="flex">
          <div className="mx-1">
            <AppButton
              text="Will Call"
              onClick={() => {
                setIsSupplierAddWillCallPaymentModal(true);
              }}
            />
          </div>
          <div className="mx-1">
            <AppButton
              type="TERTIARY"
              text="Export Data"
              onClick={() => {
                handleExportTransactions();
              }}
              icon={<Export color={COLORS.GREY_500} size={19} />}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col overflow-x-scroll h-5/6  w-full border rounded-lg WHITE-BG">
        <div className="flex w-full h-full flex-col overflow-y-scroll ">
          <div className="flex border-b px-5 items-center w-full sticky top-0 bg-zinc-50 py-3 z-10">
            {columns.map((column) => {
              return (
                <div
                  key={column.id}
                  style={{ flex: column.width / totalWidth }}
                  className="flex items-center w-full h-full   justify-center ">
                  {column.cellType !== 'SELECT' && column.id !== 'method' && (
                    <p className="TEXT_SECONDARY-CLR text-xs text-left w-full">{column.name}</p>
                  )}
                  {column.id === 'method' && (
                    <p className="TEXT_SECONDARY-CLR text-xs text-left ml-4 w-full">
                      {column.name}
                    </p>
                  )}
                </div>
              );
            })}
          </div>
          {supplierTransactions.length < 1 && (
            <div className="w-full h-full flex items-center justify-center">
              <div className="flex flex-col items-center">
                <img className="w-3/6 object-contain" src={emptyListImg} alt="" />
                <p>No Payments</p>
              </div>
            </div>
          )}
          {supplierTransactions.length > 0 &&
            [...supplierTransactions].map((payment, paymentIndex) => {
              return (
                <div key={paymentIndex + 'oo'} className={'flex  px-5 my-2'}>
                  {columns.map((column) => {
                    type ObjectKey = keyof typeof payment;
                    const columnKey = column.id as ObjectKey;
                    let value = payment[columnKey]?.toString();
                    const methodType =
                      payment.type === 'DILL_FACILITATION_FEE_PAYMENT_BUYER' ||
                      payment.type === 'DILL_FACILITATION_FEE_PAYMENT_SUPPLIER'
                        ? 'FEES'
                        : payment.type;
                    const invoices = payment.invoicePayments;
                    const name = payment?.recipient?.name ?? '';
                    if (column.id === 'amount') {
                      const total = payment.totalAmountPaid;
                      if (
                        methodType === 'PAYMENT' ||
                        methodType === 'WILL_CALL_PAYMENT' ||
                        methodType === 'CREDIT_PAYMENT'
                      ) {
                        value = `+${formatMoney(total - (payment?.facilitationFee ?? 0), 2)}`;
                      } else {
                        value = `-${formatMoney(total, 2)}`;
                      }
                    }
                    if (column.id === 'name') {
                      if (
                        payment.type === 'DILL_FACILITATION_FEE_PAYMENT_BUYER' ||
                        payment.type === 'DILL_FACILITATION_FEE_PAYMENT_SUPPLIER'
                      ) {
                        value = 'Dill';
                      } else if (payment.type === 'PAYMENT' || payment.type === 'CREDIT_PAYMENT') {
                        value = payment?.buyer?.name.toLocaleLowerCase();
                      } else {
                        value = name;
                      }
                    }
                    if (column.id === 'updatedAt') {
                      value = moment(value).format('MM/DD/YYYY');
                    }
                    if (column.id === 'createdAt') {
                      value = moment(value).format('MM/DD/YYYY');
                    }
                    return (
                      <div
                        key={column.id}
                        style={{ flex: column.width / totalWidth }}
                        className="flex items-center w-full h-full  ">
                        {column.cellType === 'VIEW_TEXT' &&
                          column.id !== 'viewDetails' &&
                          column.id !== 'name' &&
                          column.id !== 'method' &&
                          column.id !== 'reconciliationStatus' &&
                          column.id !== 'transactionStatus' && (
                            <p className={'text-xs text-left w-full'}>{value}</p>
                          )}
                        {column.cellType === 'VIEW_TEXT' && column.id === 'name' && (
                          <div className="w-full flex items-center">
                            <div className="letter-container">{getFirstLetters(value)}</div>
                            <div>
                              <div className="name-text">{value}</div>
                              <div className="flex items-center">
                                {methodType === 'PAYMENT' ||
                                methodType === 'WILL_CALL_PAYMENT' ||
                                methodType === 'CREDIT_PAYMENT' ? (
                                  <ArrowDownLeft color={COLORS.SUCCESS_500} size={10} />
                                ) : methodType === 'FEES' ? (
                                  <ArrowURightDown color={COLORS.WARNING_500} size={10} />
                                ) : (
                                  <ArrowDownLeft
                                    color={COLORS.ERROR_500}
                                    size={10}
                                    className="rotate-180"
                                  />
                                )}
                                <div className="action-type-text">
                                  {`${
                                    methodType === 'PAYMENT' ||
                                    methodType === 'CREDIT_PAYMENT' ||
                                    methodType === 'WILL_CALL_PAYMENT'
                                      ? 'RECEIVED'
                                      : methodType.replaceAll('_', ' ')
                                  }`}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {column.cellType === 'VIEW_TEXT' && column.id === 'method' && (
                          <div>
                            {methodType !== 'FEES' && (
                              <div className="w-full flex">
                                <div className="payment-type-logo">
                                  {payment?.paymentMethod?.type === 'BANK_ACCOUNT' ? (
                                    <Bank
                                      size={24}
                                      style={{ display: 'flex', alignSelf: 'center' }}
                                    />
                                  ) : payment.type === 'CREDIT_PAYMENT' ? (
                                    <Coins
                                      size={24}
                                      style={{ display: 'flex', alignSelf: 'center' }}
                                    />
                                  ) : (
                                    <CreditCard
                                      size={24}
                                      style={{ display: 'flex', alignSelf: 'center' }}
                                    />
                                  )}
                                </div>
                                <div>
                                  {payment?.paymentMethod?.type === 'BANK_ACCOUNT' ? (
                                    <div className="text-xs font-bold">Bank Transfer</div>
                                  ) : payment.type === 'CREDIT_PAYMENT' ? (
                                    <div className="text-xs font-bold">Credits</div>
                                  ) : (
                                    <div className=" text-xs font-bold">Credit/Debit Card</div>
                                  )}
                                  <div className="payment-method-details">{`${
                                    payment?.paymentMethod?.paymentMethodName ?? ''
                                  }`}</div>
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                        {column.cellType === 'VIEW_TEXT' && column.id === 'transactionStatus' && (
                          <div className="w-full  mr-6">
                            <AppTransactionStatusPill status={payment.transactionStatus} />
                          </div>
                        )}
                        {column.cellType === 'VIEW_TEXT' &&
                          column.id === 'reconciliationStatus' && (
                            <div className="w-full  mr-6">
                              <AppTransactionStatusPill status={payment.reconciliationStatus} />
                            </div>
                          )}
                        {column.cellType === 'VIEW_TEXT' && column.id === 'viewDetails' && (
                          <div className="flex">
                            {methodType !== 'FEES' && (
                              <AppButton
                                text="Details"
                                type="TERTIARY"
                                buttonStyles={{
                                  padding: '8px',
                                }}
                                onClick={() => {
                                  handleShowPaidInvoices(
                                    name,
                                    payment?.buyer?.name ?? '',
                                    payment.transactionStatus,
                                    payment.transactionErrorMessage,
                                    payment.type,
                                    payment.totalAmountPaid,
                                    payment.partialPaymentReason,
                                    payment
                                  );
                                  if (location.pathname.startsWith('/payments')) {
                                    navigate(`/payments?transactionId=${payment.id}`);
                                  }
                                }}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};
