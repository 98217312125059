import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppServerResponse } from '../../utils/interfaces/generalInterfaces';
import axios from 'axios';
import { BASE_API_URL } from '../../utils/constants';
import { logoutUser } from './authService';
import { myFirebaseAuth } from '../../firebase/config';
import { LienApiPostBody, LienUpdate, GroupedCSVRelease, LienStatus } from '@dill/dill-shared';
import { firebaseFileUploadHelper } from '../../utils/helpers';

export const createLienRelease = createAsyncThunk(
  'liens/createLienRelease',
  async (
    {
      mainBuyerId,
      details: { releaseDetails, storagePathName, releasePdf, attachmentsFiles },
    }: {
      mainBuyerId: string;
      details: {
        releaseDetails: LienApiPostBody;
        storagePathName: string;
        releasePdf: string;
        attachmentsFiles: File[];
      };
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const downloadUrl = await firebaseFileUploadHelper({
        blobUrl: releasePdf,
        fileNameWithExtension: `${new Date().getTime()}-unsigned.pdf`,
        storagePath: storagePathName,
      });

      const attachedFile: { name: string; url: string }[] = [];
      if (attachmentsFiles.length > 0) {
        for (let index = 0; index < attachmentsFiles.length; index++) {
          const element = attachmentsFiles[index];
          const blobUrl = URL.createObjectURL(element);
          const fileUrl = await firebaseFileUploadHelper({
            blobUrl,
            fileNameWithExtension: `${new Date().getTime()}-attachment-${index + 1}.pdf`,
            storagePath: storagePathName,
          });
          if (fileUrl) {
            attachedFile.push({
              name: element.name,
              url: fileUrl,
            });
          }
        }
      }

      const releaseData = {
        ...releaseDetails,
        previewPdfUrl: downloadUrl ?? '',
        releaseAttachments: attachedFile.length > 0 ? attachedFile : null,
      };

      const response = await axios.post<AppServerResponse>(
        `${BASE_API_URL}/mainBuyers/${mainBuyerId}/releases/createRelease`,
        { ...releaseData },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      console.log(error?.message);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const manualCreateLienRelease = createAsyncThunk(
  'liens/manualCreateLienRelease',
  async (
    {
      releaseDetails,
      storagePathName,
      releasePdf,
      signedPdfFileName,
      timezone,
      mainBuyerId,
    }: {
      releaseDetails: LienApiPostBody;
      storagePathName: string;
      releasePdf: string;
      signedPdfFileName: string;
      timezone: string;
      mainBuyerId: string;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const downloadUrl = await firebaseFileUploadHelper({
        blobUrl: releasePdf,
        fileNameWithExtension: `${new Date().getTime()}-signed.pdf`,
        storagePath: storagePathName,
      });

      const releaseData = {
        ...releaseDetails,
        signedPdfUrl: downloadUrl || '',
        previewPdfUrl: downloadUrl || '',
        signedPdfFileName: signedPdfFileName || '',
        timezone,
        releaseAttachments: null,
      };

      const response = await axios.post<AppServerResponse>(
        `${BASE_API_URL}/mainBuyers/${mainBuyerId}/releases/manualCreateRelease`,
        { ...releaseData },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      console.log(error?.message);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const getLienBuyerReleases = createAsyncThunk(
  'liens/getLienBuyerReleases',
  async (
    {
      startDate,
      endDate,
      mainBuyerId,
    }: { startDate: string; endDate: string; mainBuyerId: string },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const response = await axios.get<AppServerResponse>(
        `${BASE_API_URL}/mainBuyers/${mainBuyerId}/releases/getReleases`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
          params: {
            startDate,
            endDate,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      } else if (error?.response?.data?.errors && error?.response?.data?.errors.length > 0) {
        return thunkApi.rejectWithValue(error?.response?.data?.errors[0]?.msg);
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const getSupplierLienReleases = createAsyncThunk(
  'liens/getSupplierLienReleases',
  async (data, thunkApi) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const response = await axios.get<AppServerResponse>(
        `${BASE_API_URL}/suppliers/releases/getReleases`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error?.message);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      } else if (error?.response?.data?.errors && error?.response?.data?.errors.length > 0) {
        return thunkApi.rejectWithValue(error?.response?.data?.errors[0]?.msg);
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const updateSupplierLienSignature = createAsyncThunk(
  'liens/updateSupplierLienSignature',
  async (
    {
      lienId,
      lienDetails,
      filename,
      fieldsData,
    }: {
      lienId: string;
      lienDetails: {
        signedPdfUrl: string;
        signedTime: Date;
        timezone: string;
      };
      filename: string;
      fieldsData: any;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const downloadUrl = await firebaseFileUploadHelper({
        blobUrl: lienDetails.signedPdfUrl,
        fileNameWithExtension: `${new Date().getTime()}-signed.pdf`,
        storagePath: filename,
      });
      if (!downloadUrl) {
        return null;
      }

      const response = await axios.put<AppServerResponse>(
        BASE_API_URL + `/suppliers/releases/supplierLienSignature/${lienId}`,
        {
          signedPdfUrl: downloadUrl,
          signedTime: lienDetails.signedTime,
          fieldsData,
          timezone: lienDetails.timezone,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const getReleaseBySupplier = createAsyncThunk(
  'releases/getReleaseBySupplier',
  async ({ releaseId }: { releaseId: string }, thunkApi) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const response = await axios.get<AppServerResponse>(
        BASE_API_URL + `/suppliers/releases/getRelease/${releaseId}`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      if (error?.response?.data?.errors && error?.response?.data?.errors.length > 0) {
        return thunkApi.rejectWithValue(error?.response?.data?.errors[0]?.msg);
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
export const getReleaseByMainBuyer = createAsyncThunk(
  'releases/getReleaseByMainBuyer',
  async ({ mainBuyerId, releaseId }: { mainBuyerId: string; releaseId: string }, thunkApi) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const response = await axios.get<AppServerResponse>(
        BASE_API_URL + `/mainBuyers/${mainBuyerId}/releases/getRelease/${releaseId}`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      if (error?.response?.data?.errors && error?.response?.data?.errors.length > 0) {
        return thunkApi.rejectWithValue(error?.response?.data?.errors[0]?.msg);
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const updateReleaseBySupplier = createAsyncThunk(
  'liens/updateSupplierRelease',
  async (
    {
      releaseId,
      releaseDetails,
      timezone,
      storagePathName,
      releasePdfUrl,
      signedPdfName,
      mainBuyerId,
    }: {
      releaseId: string;
      releaseDetails: LienUpdate;
      timezone?: string;
      storagePathName?: string;
      releasePdfUrl?: string;
      signedPdfName?: string;
      mainBuyerId: string;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      let releaseData = releaseDetails;
      if (storagePathName && releasePdfUrl && signedPdfName) {
        const uploadUrl = await firebaseFileUploadHelper({
          blobUrl: releasePdfUrl,
          fileNameWithExtension: `${new Date().getTime()}-signed.pdf`,
          storagePath: storagePathName,
        });
        releaseData = {
          ...releaseDetails,
          signedPdf: {
            name: signedPdfName,
            url: uploadUrl ?? '',
          },
        };
      }

      const response = await axios.put<AppServerResponse>(
        BASE_API_URL + `/suppliers/releases/updateRelease/${releaseId}`,
        {
          ...releaseData,
          timezone,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
export const updateReleaseByMainBuyer = createAsyncThunk(
  'liens/updateReleaseByMainBuyer',
  async (
    {
      releaseId,
      releaseDetails,
      timezone,
      storagePathName,
      releasePdfUrl,
      signedPdfName,
      mainBuyerId,
    }: {
      releaseId: string;
      releaseDetails: LienUpdate;
      timezone?: string;
      storagePathName?: string;
      releasePdfUrl?: string;
      signedPdfName?: string;
      mainBuyerId: string;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      let releaseData = releaseDetails;
      if (storagePathName && releasePdfUrl && signedPdfName) {
        const uploadUrl = await firebaseFileUploadHelper({
          blobUrl: releasePdfUrl,
          fileNameWithExtension: `${new Date().getTime()}-signed.pdf`,
          storagePath: storagePathName,
        });
        releaseData = {
          ...releaseDetails,
          signedPdf: {
            name: signedPdfName,
            url: uploadUrl ?? '',
          },
        };
      }

      const response = await axios.put<AppServerResponse>(
        BASE_API_URL + `/mainBuyers/${mainBuyerId}/releases/updateRelease/${releaseId}`,
        {
          ...releaseData,
          timezone,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const updateReleaseSendEmail = createAsyncThunk(
  'liens/updateReleaseSendEmail',
  async (
    {
      mainBuyerId,
      releaseId,
      releaseDetails,
      storagePathName,
      releasePdf,
      attachmentsFiles,
      existingAttachentUrl,
    }: {
      mainBuyerId: string;
      releaseId: string;
      releaseDetails: LienUpdate;
      storagePathName: string;
      releasePdf: string;
      existingAttachentUrl: { name: string; url: string }[];
      attachmentsFiles: File[];
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const downloadUrl = await firebaseFileUploadHelper({
        blobUrl: releasePdf,
        fileNameWithExtension: `${new Date().getTime()}-unsigned.pdf`,
        storagePath: storagePathName,
      });

      const attachedFile: { name: string; url: string }[] = [];
      if (attachmentsFiles.length > 0) {
        for (let index = 0; index < attachmentsFiles.length; index++) {
          const element = attachmentsFiles[index];
          const blobUrl = URL.createObjectURL(element);
          const fileUrl = await firebaseFileUploadHelper({
            blobUrl,
            fileNameWithExtension: `${new Date().getTime()}-attachment-${index + 1}.pdf`,
            storagePath: storagePathName,
          });
          if (fileUrl) {
            attachedFile.push({
              name: element.name,
              url: fileUrl,
            });
          }
        }
      }
      const finalAttachedFiles = attachedFile.concat(existingAttachentUrl);
      const response = await axios.put<AppServerResponse>(
        BASE_API_URL + `/mainBuyers/${mainBuyerId}/releases/updateReleaseSendEmail/${releaseId}`,
        {
          ...releaseDetails,
          previewPdf: {
            name: '',
            url: downloadUrl ?? '',
          },
          releaseAttachments: finalAttachedFiles.length > 0 ? finalAttachedFiles : null,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const getJobReleases = createAsyncThunk(
  'liens/getJobReleases',
  async (
    {
      jobId,
      mainBuyerId,
    }: {
      jobId: string;
      mainBuyerId: string;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const response = await axios.get<AppServerResponse>(
        `${BASE_API_URL}/mainBuyers/${mainBuyerId}/releases/getJobReleases`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
          params: {
            jobId,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      } else if (error?.response?.data?.errors && error?.response?.data?.errors.length > 0) {
        return thunkApi.rejectWithValue(error?.response?.data?.errors[0]?.msg);
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const deleteRelease = createAsyncThunk(
  'liens/deleteRelease',
  async (
    {
      releaseId,
      mainBuyerId,
    }: {
      releaseId: string;
      mainBuyerId: string;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.delete<AppServerResponse>(
        BASE_API_URL + `/mainBuyers/${mainBuyerId}/releases/deleteRelease/${releaseId}`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const forwardRelease = createAsyncThunk(
  'liens/forwardRelease',
  async (
    {
      mainBuyerId,
      mailTo,
      notes,
      subject,
      attachements,
      releaseIds,
      cc,
    }: {
      mainBuyerId: string;
      mailTo: string[];
      subject: string;
      notes: string;
      cc: string;
      attachements: { url: string; name: string }[];
      releaseIds: string[];
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.post<AppServerResponse>(
        BASE_API_URL + `/mainBuyers/${mainBuyerId}/releases/forwardRelease`,
        { mailTo, notes, subject, attachements, releaseIds, cc },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      console.log(error);
      console.log(error?.message);
      let errorMessage = '';
      if (error?.response?.data?.errors) {
        errorMessage = [...error?.response?.data?.errors].reduce((prev, curr) => {
          if (curr.msg) {
            return `${prev} ${curr.msg}`;
          }
          return prev;
        }, '');
      } else {
        errorMessage = error?.message;
      }
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);

export const createGroupedRelease = createAsyncThunk(
  'liens/createGroupedRelease',
  async (
    {
      mainBuyerId,
      releaseDetails,
    }: {
      mainBuyerId: string;
      releaseDetails: GroupedCSVRelease[];
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const updateReleaseDetails = [];

      for (let index = 0; index < releaseDetails.length; index++) {
        const release = releaseDetails[index];

        const downloadUrl = await firebaseFileUploadHelper({
          blobUrl: release.previewPdfUrl,
          fileNameWithExtension: `${new Date().getTime()}-unsigned.pdf`,
          storagePath: release.storagePathName,
        });
        updateReleaseDetails.push({ ...release, previewPdfUrl: downloadUrl ?? '' });
      }

      const response = await axios.post<AppServerResponse>(
        `${BASE_API_URL}/mainBuyers/${mainBuyerId}/releases/createGroupReleases`,
        { updateReleaseDetails },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      console.log(error?.message);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const getSupplierReleaseHistory = createAsyncThunk(
  'releases/getSupplierReleaseHistory',
  async ({ releaseId }: { releaseId: string }, thunkApi) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const response = await axios.get<AppServerResponse>(
        BASE_API_URL + `/suppliers/releases/releaseHistory/${releaseId}`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      if (error?.response?.data?.errors && error?.response?.data?.errors.length > 0) {
        return thunkApi.rejectWithValue(error?.response?.data?.errors[0]?.msg);
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
export const getMainBuyerReleaseHistory = createAsyncThunk(
  'releases/getMainBuyerReleaseHistory',
  async ({ releaseId, mainBuyerId }: { releaseId: string; mainBuyerId: string }, thunkApi) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const response = await axios.get<AppServerResponse>(
        BASE_API_URL + `/mainBuyers/${mainBuyerId}/releases/releaseHistory/${releaseId}`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      if (error?.response?.data?.errors && error?.response?.data?.errors.length > 0) {
        return thunkApi.rejectWithValue(error?.response?.data?.errors[0]?.msg);
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const getInvoicesUsedOnReleases = createAsyncThunk(
  'releases/getInvoicesUsedOnReleases',
  async (
    {
      type,
      mainBuyerId,
      buyerJobId,
      supplierJobId,
      buyerId,
    }: {
      type: 'MAIN_BUYER' | 'SUPPLIER';
      buyerJobId?: string;
      mainBuyerId?: string;
      supplierJobId?: string;
      buyerId?: string;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const response = await axios.get<AppServerResponse>(
        BASE_API_URL + `/generics/releases/getInvoicesUsedOnReleases`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
          params: { type, mainBuyerId, buyerJobId, supplierJobId, buyerId },
        }
      );

      return response.data;
    } catch (error: any) {
      if (error?.response?.data?.errors && error?.response?.data?.errors.length > 0) {
        return thunkApi.rejectWithValue(error?.response?.data?.errors[0]?.msg);
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
