import { Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import {
  RenderCurrentPageLabelProps,
  pageNavigationPlugin,
} from '@react-pdf-viewer/page-navigation';
import React, { useEffect, useMemo, useState, useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { AppButton } from '../../../../general/AppButton/AppButton';
import { AppInputField } from '../../../../general/AppInputField/AppInputField';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { AppDropDown } from '../../../../general/AppDropDown/AppDropDown';
import { AppDropDown2 } from '../../../../general/AppDropDown2/AppDropDown2';
import axios from 'axios';
import { PDFDocument } from 'pdf-lib';
import moment from 'moment';
import AppDatePicker from '../../../../general/AppDatePicker/AppDatePicker';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import { getLienTemplates } from '../../../../../redux/services/leinTemplatesService';
import {
  Job,
  LienTemplate,
  UserLinkApiResponse,
  FromFieldTypeReleaseTemplate,
  ReleaseTemplateField,
  ReleaseFieldData,
  Lien,
  LienApiResponse,
  formatMoney,
  PDFFieldReleaseTemplate,
  QuickbooksInvoice,
} from '@dill/dill-shared';
import {
  removePDFFields,
  structureTemplateNewFieldFromTypePDFData,
  structureTemplateNonNewFieldFromTypePDFData,
  populatePagesPdfWithFieldsData,
} from '../../../../../utils/helpers';
import { AppConfirmationModal } from '../../../../general/AppConfirmationModal/AppConfirmationModal';
import successChecksCircle from '../../../../../assets/svg/success_checks_circle.svg';
import errorCircle from '../../../../../assets/svg/error_circle.svg';
import sendCircle from '../../../../../assets/svg/send_circle.svg';
import { AppSuccessModal } from '../../../../general/AppSuccessModal/AppSuccessModal';
import { getJobs } from '../../../../../redux/services/jobsService';
import {
  getSubSuppliers,
  updateSubSupplier,
} from '../../../../../redux/services/subSuppliersService';
import {
  updateReleaseSendEmail,
  getReleaseByMainBuyer,
  getInvoicesUsedOnReleases,
} from '../../../../../redux/services/liensService';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ChevronDown, Plus } from 'react-feather';
import { COLORS } from '../../../../../utils/colors';
import AppScheduleModal from '../../../../general/AppScheduleModal/AppScheduleModal';
import AppFilePicker from '../../../../general/AppFilePicker/AppFilePicker';
import { AppCheckBox } from '../../../../general/AppCheckBox/AppCheckBox';
import { useTrivialValueDebounce } from '../../../../../utils/hooks/useDebounce';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { CheckCircle, Notepad, Warning } from 'phosphor-react';
import AppReleaseStatusPill from '../../../../general/AppReleaseStatusPill/AppReleaseStatusPill';
import { AppNewJobModal } from '../../../../general/AppNewJobModal/AppNewJobModal';
import { InvoiceListButtonStyle, InvoiceReference } from '../../../../../constants/invoice';
import EditInvoiceList from '../../../payments/EditInvoiceList';
import { useParamMainBuyerId } from '../../../../../utils/hooks/useParamMainBuyerId';
import { AppInvoiceTable } from '../../../../general/AppInvoiceTable/AppInvoiceTable';
import { getUserByBuyers } from '../../../../../redux/services/authService';
import { getGroupedBuyersSuppliers } from '../../../../../redux/services/buyersService';

const BuyerEditLienRelease = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const params = useParams();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { lienTemplates } = useAppSelector((state) => state.lienTemplates);
  const { jobs } = useAppSelector((state) => state.jobs);
  const { selectedMainBuyer, selectedBuyerSuppliers } = useAppSelector((state) => state.buyers);
  const { subSuppliers } = useAppSelector((state) => state.subSuppliers);
  const { user } = useAppSelector((state) => state.auth);
  const { selectedLiensRelease } = useAppSelector((state) => state.liens);

  const [templatePdfBytes, setTemplatePdfBytes] = useState<any>(null);
  const [selectedTemplate, setSelectedTemplate] = useState<LienTemplate | null>(null);
  const [selectedTemplateFields, setSelectedTemplateFields] = useState<
    ReleaseTemplateField[] | null
  >(null);
  const [selectedJob, setSelectedJob] = useState<Job | null>(null);
  const [selectedSupplierUserPosition, setSelectedSupplierUserPosition] = useState<number | null>(
    null
  );
  const [selectedMainBuyerId] = useParamMainBuyerId();
  const [errorFields, setErrorFields] = useState<string[]>([]);
  const [notes, setNotes] = useState('');
  const [emailRemainderCount, setEmailRemainderCount] = useState(3);
  const [dueDate, setDueDate] = useState<Date | null>(null);
  const [scheduledDate, setScheduledDate] = useState<Date | null>(null);
  const [isConfirmCreateLeanReleaseModalOpen, setIsConfirmCreateLeanReleaseModalOpen] =
    useState(false);
  const [isErrorCreateLeanReleaseModalOpen, setIsErrorEditLeanReleaseModalOpen] = useState(false);
  const [isLienReleaseCreateSuccessModalOpen, setIsLienReleaseEditSuccessModalOpen] =
    useState(false);
  const [isWetSignature, setIsWetSignature] = useState(false);
  const [urlFiles, setUrlFiles] = useState<{ name: string; url: string }[]>([]);
  const zoomPluginInstance = zoomPlugin();
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;
  const { CurrentPageLabel } = pageNavigationPluginInstance;

  const [currentPdfUrl, setCurrentPdfUrl] = useState('');
  const [originalPdfUrl, setOriginalPdfUrl] = useState('');
  const [flattenedPdfUrl, setFlattenedPdfUrl] = useState('');
  const [showSchedule, setShowSchedule] = useState(false);
  const [openScheduleModal, setOpenScheduleModal] = useState(false);
  const [debouncedPopulatePdfChange, triggerDebouncedPopulatePdf] = useTrivialValueDebounce(200);
  const [openScheduleSuccessModal, setOpenScheduleSuccessModal] = useState(false);
  const [openScheduleErrorModal, setOpenScheduleErrorModal] = useState(false);
  const [openJobModal, setOpenJobModal] = useState(false);
  const [isEditJob, setIsEditJob] = useState(false);
  const [missingJobDetails, setMissingJobDetails] = useState(false);
  const [refresh, setRefresh] = useState({});
  const [files, setFiles] = useState<File[]>([]);
  const [formFieldsData, setFormFieldsData] = useState<{
    [x: string]: ReleaseFieldData;
  }>({});
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [showErrorAbove, setShowErrorAbove] = useState(false);
  const [showInvoiceListNotes, setShowInvoiceListNotes] = useState(false);
  const [invoiceList, setInvoiceList] = useState<InvoiceReference[]>([]);
  const [selectedSubSupplierUser, setSelectedSubSupplierUser] = useState<{
    subSupplierName: string;
    subSupplierId: string;
    syncSupplierId: string;
    notes: string;
    user: UserLinkApiResponse;
  } | null>(null);
  const [selectedInvoices, setSelectedInvoices] = useState<QuickbooksInvoice[]>([]);
  const [signedBy, setSignedBy] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(0);

  const [usedInvoices, setUsedInvoices] = useState<
    { type: string; invoiceNumber: string; date: string }[]
  >([]);

  const todayDate = new Date();
  const nextDay = new Date();

  const onShowInvoiceClick = () => {
    setShowInvoiceListNotes(!showInvoiceListNotes);
  };

  nextDay.setDate(todayDate.getDate() + 1);
  const templates = useMemo(() => {
    if (lienTemplates.length > 0) {
      const processedLienTemplates = lienTemplates.filter(
        (template) => template.status === 'processed'
      );
      return processedLienTemplates;
    }
    return [];
  }, [lienTemplates]);

  const allInvoices = useMemo(() => {
    if (selectedBuyerSuppliers && selectedSubSupplierUser) {
      const unPaidInvoices = [];
      const paidInvoices = [];
      for (let index = 0; index < selectedBuyerSuppliers.length; index++) {
        const selectedBuyerSupplier = selectedBuyerSuppliers[index];
        if (selectedBuyerSupplier.id === selectedSubSupplierUser.syncSupplierId) {
          const invoices = selectedBuyerSupplier.invoices;
          if (invoices) {
            const filteredPaid = [...invoices.paidInvoices.invoices];
            paidInvoices.push(...filteredPaid);
            const todayInvoices = invoices.todayInvoices.invoices;
            const currentInvoices = invoices.currentInvoices.invoices;
            const pastDueInvoices = invoices.pastDueInvoices.invoices;
            const pendingPayInvoices = invoices.pendingPayInvoices.invoices;
            const filterUnpaidInvoice = [
              ...todayInvoices,
              ...currentInvoices,
              ...pastDueInvoices,
              ...pendingPayInvoices,
            ];
            unPaidInvoices.push(...filterUnpaidInvoice);
          }
        }
      }
      const result = paidInvoices.concat(unPaidInvoices);
      return result;
    }
    return [];
  }, [selectedBuyerSuppliers, selectedSubSupplierUser]);

  const subSupplierUsers = useMemo(() => {
    if (subSuppliers) {
      return subSuppliers
        .filter((item) => item.id === (selectedLiensRelease?.subSupplier?.id ?? ''))
        .reduce(
          (
            prev: {
              subSupplierName: string;
              notes: string;
              subSupplierId: string;
              syncSupplierId: string;
              user: UserLinkApiResponse;
            }[],
            curr
          ) => {
            return [
              ...prev,
              ...curr.users
                .filter((userLink) => {
                  if (
                    userLink.subUser &&
                    userLink.subUser.roles &&
                    userLink.subUser.roles.includes('REVIEWER')
                  ) {
                    return true;
                  }
                  return !userLink?.subUser?.roles;
                })
                .map((userLink) => {
                  return {
                    subSupplierId: curr.id,
                    subSupplierName: curr.name,
                    syncSupplierId: curr.syncSupplierId ?? '',
                    notes: curr.notes ?? '',
                    user: userLink,
                  };
                }),
            ];
          },
          []
        );
    }
    return [];
  }, [subSuppliers, selectedLiensRelease]);

  const fetchReleaseData = async () => {
    if (selectedMainBuyerId) {
      const pathlist = location.pathname.split('/');
      const releaseId = pathlist[pathlist.length - 1];
      await dispatch(getReleaseByMainBuyer({ releaseId, mainBuyerId: selectedMainBuyerId }));
    }
  };

  const fetchUsedInvoices = async ({
    mainBuyerId,
    buyerJobId,
  }: {
    mainBuyerId: string;
    buyerJobId: string;
  }) => {
    const usedInvoicesRes: any = await dispatch(
      getInvoicesUsedOnReleases({
        type: 'MAIN_BUYER',
        mainBuyerId,
        buyerJobId,
      })
    );
    if (usedInvoicesRes.type === 'releases/getInvoicesUsedOnReleases/fulfilled') {
      if (
        usedInvoicesRes?.payload &&
        usedInvoicesRes?.payload?.usedInvoices &&
        usedInvoicesRes.payload.usedInvoices.length > 0
      ) {
        const invoices: { type: string; invoiceNumber: string; date: string }[] =
          usedInvoicesRes.payload.usedInvoices;
        setUsedInvoices(invoices);
      }
    }
  };

  useEffect(() => {
    fetchReleaseData();
    setSignedBy(null);

    return () => {};
  }, [location, selectedMainBuyerId]);

  useEffect(() => {
    if (selectedMainBuyerId && selectedJob) {
      fetchUsedInvoices({ mainBuyerId: selectedMainBuyerId, buyerJobId: selectedJob.id });
    }

    return () => {};
  }, [location, selectedMainBuyerId, selectedJob]);

  useEffect(() => {
    if (selectedMainBuyerId) {
      dispatch(getLienTemplates({ mainBuyerId: selectedMainBuyerId }));
      dispatch(getJobs({ mainBuyerId: selectedMainBuyerId }));
      dispatch(getSubSuppliers({ mainBuyerId: selectedMainBuyerId }));
      dispatch(getUserByBuyers({ buyerId: selectedMainBuyerId }));
      dispatch(
        getGroupedBuyersSuppliers({
          mainBuyerId: selectedMainBuyerId,
          weeksLimit: 12,
        })
      );
    }

    return () => {};
  }, [selectedMainBuyerId]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowSchedule(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!showInvoiceListNotes && invoiceList.length !== 0) {
      setInvoiceList([]);
    }
  }, [showInvoiceListNotes]);

  const getVendorDetails = () => {
    let vendorCityStateData: { [x: string]: ReleaseFieldData } = {};
    let vendorAddressData: { [x: string]: ReleaseFieldData } = {};
    if (selectedTemplate) {
      const citystatezipcode = `${city ? `${city}, ` : ''}${state ? `${state} ` : ''}${
        zipCode ? `${zipCode} ` : ''
      }`;
      const foundVendorCityState = [...selectedTemplate.formFields].find(
        (formFields) =>
          formFields.fromFieldType === 'NEW_FIELD' &&
          formFields.whoPopulatesField === 'BUYER' &&
          formFields.id.startsWith('vendorCityStateZip')
      );
      const foundVendorAddress = [...selectedTemplate.formFields].find(
        (formFields) =>
          formFields.fromFieldType === 'NEW_FIELD' &&
          formFields.whoPopulatesField === 'BUYER' &&
          formFields.id.startsWith('vendorAddress')
      );
      if (foundVendorCityState) {
        const updateField = formFieldsData[foundVendorCityState.id];
        if (updateField) {
          vendorCityStateData = {
            [updateField.id]: { ...updateField, value: citystatezipcode },
          };
        }
      }
      if (foundVendorAddress) {
        const updateAddressField = formFieldsData[foundVendorAddress.id];
        if (updateAddressField) {
          vendorAddressData = {
            [updateAddressField.id]: { ...updateAddressField, value: address },
          };
        }
      }
    }

    return { ...vendorCityStateData, ...vendorAddressData };
  };

  const populatePdf = async (fieldDetails: PDFFieldReleaseTemplate[]) => {
    if (!selectedTemplate?.originalPdf?.url) {
      return;
    }

    let pdfUrl = '';
    if (flattenedPdfUrl) {
      pdfUrl = flattenedPdfUrl;
    } else {
      const flatPDF = await removePDFFields(selectedTemplate?.originalPdf?.url ?? '');
      pdfUrl = flatPDF ?? '';
    }
    const populatedPdfUrl = await populatePagesPdfWithFieldsData({
      fieldDetails,
      url: pdfUrl,
    });
    if (populatedPdfUrl) {
      setCurrentPdfUrl(populatedPdfUrl);
    }
  };

  const populateAllPdfData = () => {
    const specialFieldsPDFData: {
      fromFieldType: FromFieldTypeReleaseTemplate;
      dataToUse: any;
    }[] = [];

    const dynamicForFieldsPdfData: {
      fromFieldId: string;
      value: any;
    }[] = [];

    if (selectedJob) {
      specialFieldsPDFData.push({ fromFieldType: 'JOB_FIELD', dataToUse: selectedJob });
    }

    if (selectedSupplierUserPosition !== null && subSupplierUsers[selectedSupplierUserPosition]) {
      specialFieldsPDFData.push({
        fromFieldType: 'SUPPLIER_FIELD',
        dataToUse: subSupplierUsers[selectedSupplierUserPosition],
      });
    }

    if (selectedTemplate) {
      specialFieldsPDFData.push({
        fromFieldType: 'USER_BUYER_FIELD',
        dataToUse: selectedMainBuyer,
      });
      const vendorDetails = getVendorDetails();
      const allNewFormFields = { ...formFieldsData, ...vendorDetails };

      Object.keys(allNewFormFields).forEach((formFieldKey) => {
        dynamicForFieldsPdfData.push({
          fromFieldId: formFieldKey,
          value: allNewFormFields[formFieldKey].value,
        });
      });
    }
    let pdfData: PDFFieldReleaseTemplate[] = [];
    if (specialFieldsPDFData.length > 0 && selectedTemplate) {
      const data1 = structureTemplateNonNewFieldFromTypePDFData({
        template: selectedTemplate,
        details: specialFieldsPDFData,
      });
      pdfData = [...pdfData, ...data1];
    }
    if (dynamicForFieldsPdfData.length > 0) {
      const data2 = structureTemplateNewFieldFromTypePDFData({
        template: selectedTemplate,
        details: dynamicForFieldsPdfData,
      });
      pdfData = [...pdfData, ...data2];
    }
    if (pdfData.length > 0) {
      populatePdf(pdfData);
    }
  };

  useEffect(() => {
    populateAllPdfData();
  }, [debouncedPopulatePdfChange]);

  useEffect(() => {
    if (selectedTemplate) {
      // populateAllPdfData();
      triggerDebouncedPopulatePdf();
    }

    return () => {};
  }, [selectedJob, selectedSupplierUserPosition, formFieldsData, flattenedPdfUrl]);

  useEffect(() => {
    if (selectedSupplierUserPosition !== null) {
      const foundSubSupplier = subSuppliers.find(
        (item) => item.id === subSupplierUsers?.[selectedSupplierUserPosition]?.subSupplierId
      );
      if (foundSubSupplier) {
        setCity(foundSubSupplier?.city ?? '');
        setState(foundSubSupplier?.state ?? '');
        setZipCode(foundSubSupplier?.zipCode ? `${foundSubSupplier?.zipCode}` : '');
        setAddress(foundSubSupplier?.address ? `${foundSubSupplier?.address}` : '');
        const foundPhoneInput = selectedTemplate?.formFields?.find(
          (formFields) =>
            formFields.fromFieldType === 'NEW_FIELD' &&
            formFields.whoPopulatesField === 'BUYER' &&
            formFields.id.startsWith('vendorPhone')
        );
        if (foundPhoneInput) {
          const updateField = formFieldsData[foundPhoneInput.id];
          if (updateField) {
            setFormFieldsData({
              ...formFieldsData,
              [foundPhoneInput.id]: {
                ...updateField,
                value: foundSubSupplier?.phoneNumber ? `${foundSubSupplier?.phoneNumber}` : '',
              },
            });
          }
        }
      }
    }

    return () => {};
  }, [selectedSupplierUserPosition]);

  useEffect(() => {
    if (selectedLiensRelease) {
      if (selectedLiensRelease?.job) {
        setSelectedJob(selectedLiensRelease.job);
      }
      if (selectedLiensRelease.dueDate) {
        setDueDate(new Date(selectedLiensRelease.dueDate));
      }
      if (selectedLiensRelease.dueDate) {
        setNotes(selectedLiensRelease.notes);
      }
      if (subSupplierUsers.length > 0 && selectedLiensRelease?.recipient?.subUser?.id) {
        const foundIndex = subSupplierUsers.findIndex(
          (subSup) => subSup.user.subUser?.id === selectedLiensRelease?.recipient?.subUser?.id
        );
        setSelectedSubSupplierUser({
          subSupplierName: selectedLiensRelease.subSupplier?.name ?? '',
          subSupplierId: selectedLiensRelease.subSupplier?.id ?? '',
          syncSupplierId: selectedLiensRelease.subSupplier?.syncSupplierId ?? '',
          notes: selectedLiensRelease.subSupplier?.notes ?? '',
          user: {},
        });
        setSelectedSupplierUserPosition(foundIndex);
      }
      if (selectedLiensRelease?.releaseAttachments) {
        setUrlFiles(selectedLiensRelease?.releaseAttachments);
      } else {
        setUrlFiles([]);
      }
      if (selectedLiensRelease?.isWetSignature) {
        setIsWetSignature(selectedLiensRelease.isWetSignature);
      }
      if (selectedLiensRelease?.template && selectedLiensRelease?.status !== 'Signed') {
        setSelectedTemplate(selectedLiensRelease?.template ?? null);
        if (selectedLiensRelease?.template?.originalPdf?.url) {
          setOriginalPdfUrl(selectedLiensRelease?.template?.originalPdf.url);
        }
      }
      if (
        selectedLiensRelease &&
        selectedLiensRelease?.invoiceList &&
        selectedLiensRelease?.invoiceList.length > 0
      ) {
        setShowInvoiceListNotes(true);
        setInvoiceList(
          selectedLiensRelease?.invoiceList.map((invoice: any) => ({
            ...invoice,
            amount: typeof invoice.amount === 'number' ? invoice.amount.toFixed(2) : invoice.amount,
          })) //format values to 2 decimal places
        );
      }
      if (selectedLiensRelease?.status === 'Signed') {
        const signedByName =
          selectedLiensRelease?.signedBy?.subUser?.firstName ||
          selectedLiensRelease?.signedBy?.user?.firstName
            ? `${
                selectedLiensRelease?.signedBy?.subUser?.firstName ??
                selectedLiensRelease?.signedBy?.user?.firstName ??
                ''
              } ${
                selectedLiensRelease?.signedBy?.subUser?.lastName ??
                selectedLiensRelease?.signedBy?.user?.lastName ??
                ''
              }`
            : null;
        setSignedBy(signedByName);
        setCurrentPdfUrl(selectedLiensRelease?.signedPdf?.url ?? '');
      }
    }
    return () => {
      setTemplatePdfBytes(null);
      setSelectedTemplate(null);
      setSelectedJob(null);
      setSelectedSupplierUserPosition(null);
      setErrorFields([]);
      setNotes('');
      setEmailRemainderCount(3);
      setDueDate(null);
      setScheduledDate(null);
      setIsConfirmCreateLeanReleaseModalOpen(false);
      setIsErrorEditLeanReleaseModalOpen(false);
      setIsLienReleaseEditSuccessModalOpen(false);
      // setPdfUrlHistory([]);
      setUrlFiles([]);
      setCurrentPdfUrl('');
      setShowSchedule(false);
      setOpenScheduleModal(false);
      setOpenScheduleSuccessModal(false);
      setOpenScheduleErrorModal(false);
      setFiles([]);
      setIsWetSignature(false);
    };
  }, [selectedLiensRelease, subSupplierUsers]);

  useEffect(() => {
    if (selectedLiensRelease && selectedLiensRelease?.invoiceList) {
      const selInvoice: QuickbooksInvoice[] = [];
      for (let index = 0; index < selectedLiensRelease?.invoiceList.length; index++) {
        const invoiceItem = selectedLiensRelease?.invoiceList[index];
        const foundInvoice = allInvoices.find((inv) => inv.docNumber === invoiceItem.invoiceNumber);
        if (foundInvoice) {
          selInvoice.push(foundInvoice);
        }
      }
      setSelectedInvoices(selInvoice);
    }
  }, [selectedLiensRelease, allInvoices]);

  const hasCityStateZipCode = useMemo(() => {
    if (selectedTemplate) {
      const hasAddress = [...selectedTemplate.formFields].filter(
        (formFields) =>
          formFields.fromFieldType === 'NEW_FIELD' &&
          formFields.whoPopulatesField === 'BUYER' &&
          formFields.id.startsWith('vendorCityStateZip')
      );
      return hasAddress.length > 0;
    }

    return false;
  }, [selectedTemplate]);

  const hasVendorAddress = useMemo(() => {
    if (selectedTemplate) {
      const hasAddress = [...selectedTemplate.formFields].filter(
        (formFields) =>
          formFields.fromFieldType === 'NEW_FIELD' &&
          formFields.whoPopulatesField === 'BUYER' &&
          formFields.id.startsWith('vendorAddress')
      );
      return hasAddress.length > 0;
    }

    return false;
  }, [selectedTemplate]);

  const selectedSubSupplier = useMemo(() => {
    let foundSelectedSubSupplier = null;
    if (selectedSupplierUserPosition !== null && subSupplierUsers) {
      const foundSubSupplier = subSuppliers.find(
        (item) => item.id === subSupplierUsers?.[selectedSupplierUserPosition]?.subSupplierId
      );
      if (foundSubSupplier) {
        foundSelectedSubSupplier = foundSubSupplier;
      }
    }
    return foundSelectedSubSupplier;
  }, [selectedSupplierUserPosition]);

  const handleSubmitLienRelease = async (date?: Date) => {
    if (selectedSupplierUserPosition == null) {
      return;
    }
    if (selectedMainBuyerId === null) {
      return;
    }
    const selectedUser = subSupplierUsers[selectedSupplierUserPosition];
    let results;
    const releaseDetails = {
      jobId: selectedJob?.id || '',
      notes,
      recipientId: {
        userId: selectedUser.user.user?.id || '',
        subUserId: selectedUser.user.subUser?.id || '',
      },
      declineReason: '',
      fieldsData: Object.values(formFieldsData),
      subSupplierId: selectedUser?.subSupplierId,
      templateId: selectedTemplate?.id || '',
      emailReminderFrequency: Number(emailRemainderCount),
      createdAt: date,
      dueDate: dueDate || new Date(),
      isWetSignature,
      invoiceList: invoiceList
        .map((invoice: any) => ({
          ...invoice,
          amount: isNaN(parseFloat(invoice.amount)) ? null : parseFloat(invoice.amount),
        }))
        .filter((invoice: any) => invoice.invoiceNumber && !isNaN(invoice.amount)), // Removes empty invoices
    };
    if (date) {
      results = await dispatch(
        updateReleaseSendEmail({
          mainBuyerId: selectedMainBuyerId,
          releaseId: selectedLiensRelease?.id ?? '',
          releaseDetails: {
            ...releaseDetails,
            type: selectedTemplate?.type ?? null,
            createdAt: date,
            status: 'Scheduled',
          },
          releasePdf: currentPdfUrl,
          attachmentsFiles: files,
          existingAttachentUrl: urlFiles,
          storagePathName: `buyerReleases/${selectedMainBuyerId}/${subSupplierUsers[selectedSupplierUserPosition]?.subSupplierId}/${selectedJob?.id}`,
        })
      );
    } else {
      results = await dispatch(
        updateReleaseSendEmail({
          mainBuyerId: selectedMainBuyerId,
          releaseId: selectedLiensRelease?.id ?? '',
          releaseDetails: {
            ...releaseDetails,
            type: selectedTemplate?.type ?? null,
            status:
              selectedLiensRelease?.status === 'Declined' ||
              selectedLiensRelease?.status === 'Rejected' ||
              selectedLiensRelease?.status === 'Scheduled'
                ? 'Requested'
                : selectedLiensRelease?.status,
            createdAt: selectedLiensRelease?.status === 'Scheduled' ? new Date() : undefined,
          },
          releasePdf: currentPdfUrl,
          attachmentsFiles: files,
          existingAttachentUrl: urlFiles,
          storagePathName: `buyerReleases/${selectedMainBuyerId}/${subSupplierUsers[selectedSupplierUserPosition]?.subSupplierId}/${selectedJob?.id}`,
        })
      );
    }
    if (hasCityStateZipCode || hasVendorAddress) {
      const foundAddress = selectedTemplate?.formFields.find(
        (formFields) =>
          formFields.fromFieldType === 'NEW_FIELD' &&
          formFields.whoPopulatesField === 'BUYER' &&
          formFields.id.startsWith('vendorAddress')
      );
      const foundPhone = selectedTemplate?.formFields.find(
        (formFields) =>
          formFields.fromFieldType === 'NEW_FIELD' &&
          formFields.whoPopulatesField === 'BUYER' &&
          formFields.id.startsWith('vendorPhone')
      );
      await dispatch(
        updateSubSupplier({
          mainBuyerId: selectedMainBuyerId,
          subSupplierId: subSupplierUsers?.[selectedSupplierUserPosition]?.subSupplierId ?? '',
          subSupplierDetails: {
            city,
            state,
            zipCode: zipCode,
            phoneNumber: foundPhone?.id ? formFieldsData?.[foundPhone?.id]?.value ?? '' : '',
            address: foundAddress?.id ? formFieldsData?.[foundAddress?.id]?.value ?? '' : '',
          },
        })
      );
      await dispatch(getSubSuppliers({ mainBuyerId: selectedMainBuyerId }));
    }
    setScheduledDate(null);
    if (results.type === 'liens/updateReleaseSendEmail/fulfilled') {
      if (date) {
        setOpenScheduleSuccessModal(true);
      } else {
        setIsLienReleaseEditSuccessModalOpen(true);
      }
    } else {
      if (date) {
        setOpenScheduleErrorModal(true);
      } else {
        setIsErrorEditLeanReleaseModalOpen(true);
      }
    }
  };

  const handleScheduleDate = async (date: Date) => {
    setScheduledDate(date);
    handleSubmitLienRelease(date);
  };

  useEffect(() => {
    if (selectedTemplate) {
      if (selectedTemplate?.originalPdf?.url) {
        setOriginalPdfUrl(selectedTemplate?.originalPdf?.url);
      }

      if (selectedJob && selectedJob.defaultThroughDate) {
        const currentDate = moment();
        const endOfMonth = currentDate.endOf('month').startOf('day');
        const endOfMonthDate = endOfMonth.toDate();
        const formFieldsDataKey = Object.keys(formFieldsData);
        let throughDateData: { [x: string]: ReleaseFieldData } = {};
        for (const key of Object.keys(formFieldsData)) {
          if (key.startsWith('throughDate')) {
            throughDateData = {
              ...throughDateData,
              [key]: {
                ...formFieldsData[key],
                value: formFieldsData?.[key]?.value
                  ? formFieldsData[key].value
                  : new Date(endOfMonthDate),
              },
            };
          }
        }
        const vendorDetails = getVendorDetails();
        setFormFieldsData({ ...formFieldsData, ...throughDateData, ...vendorDetails });
      }
    }

    return () => {};
  }, [selectedJob, selectedSupplierUserPosition, refresh]);

  const setupOriginalAndFlattenPDF = async () => {
    if (selectedTemplate) {
      const flat = await removePDFFields(selectedTemplate?.originalPdf?.url ?? '');
      if (flat) {
        setCurrentPdfUrl(flat);
        setFlattenedPdfUrl(flat);
      } else {
        setCurrentPdfUrl(selectedTemplate?.originalPdf?.url ?? '');
        setFlattenedPdfUrl(selectedTemplate?.originalPdf?.url ?? '');
      }
    }
  };

  useEffect(() => {
    if (selectedJob) {
      setInvoiceList([]);
    }

    return () => {};
  }, [selectedJob]);

  useEffect(() => {
    if (selectedTemplate) {
      setupOriginalAndFlattenPDF();
      setSelectedTemplateFields(
        [...selectedTemplate.formFields]
          .sort((A, B) => {
            if (
              (A.position === null || A.position === undefined) &&
              B.position !== null &&
              B.position !== undefined
            ) {
              return 1;
            }
            if (
              (B.position === null || B.position === undefined) &&
              A.position !== null &&
              A.position !== undefined
            ) {
              return -1;
            }
            if (
              A.position !== null &&
              A.position !== undefined &&
              B.position !== null &&
              B.position !== undefined &&
              A.position > B?.position
            ) {
              return 1;
            }
            return -1;
          })
          .filter(
            (formFields) =>
              formFields.fromFieldType === 'NEW_FIELD' &&
              formFields.whoPopulatesField === 'BUYER' &&
              !formFields.id.startsWith('vendorCityStateZip') &&
              !formFields.id.startsWith('vendorAddress')
          )
      );

      const fieldDataValues = Object.values(formFieldsData);

      let data: { [x: string]: ReleaseFieldData } = {};
      selectedTemplate.formFields.forEach((formField) => {
        if (formField.fromFieldType === 'NEW_FIELD' && formField.whoPopulatesField === 'BUYER') {
          let releaseField: ReleaseFieldData | undefined | null = null;
          if (selectedLiensRelease && selectedLiensRelease?.fieldsData) {
            releaseField = selectedLiensRelease?.fieldsData.find(
              (field) => field.id === formField.id
            );
          }
          if (releaseField) {
            let value: any = null;
            if (releaseField.value !== null && releaseField.value !== undefined) {
              value = releaseField.value;
              const foundField = fieldDataValues.find((field) => field.id === releaseField?.id);
              if (foundField && foundField.value && foundField.value != releaseField.value) {
                value = foundField.value;
              }
              if (releaseField.type === 'CURRENCY') {
                if (value === 0) {
                  value = '0.00';
                } else {
                  value = Number(Number(value)).toFixed(2);
                }
              }
              if (releaseField.type === 'NUMBER' && value === 0) {
                value = '0';
              }
              if (releaseField.type === 'DATE') {
                value = new Date(value);
              }
            }
            data = {
              ...data,
              [formField.id]: {
                isRequired: formField.isFieldRequired,
                value,
                id: formField.id,
                name: formField.fieldName,
                type: formField.fieldType,
              },
            };
          } else {
            data = {
              ...data,
              [formField.id]: {
                isRequired: formField.isFieldRequired,
                value:
                  formFieldsData[formField.id] && formFieldsData[formField.id].value
                    ? formFieldsData[formField.id].value
                    : formField.defaultValue || '',
                id: formField.id,
                name: formField.fieldName,
                type: formField.fieldType,
              },
            };
          }
        }
      });
      let vendorPhone: { [x: string]: ReleaseFieldData } = {};
      if (selectedSupplierUserPosition !== null) {
        const foundSubSupplier = subSuppliers.find(
          (item) => item.id === subSupplierUsers?.[selectedSupplierUserPosition]?.subSupplierId
        );
        if (foundSubSupplier) {
          const foundPhoneInput = selectedTemplate?.formFields?.find(
            (formFields) =>
              formFields.fromFieldType === 'NEW_FIELD' &&
              formFields.whoPopulatesField === 'BUYER' &&
              formFields.id.startsWith('vendorPhone')
          );
          if (foundPhoneInput) {
            const updateField = data[foundPhoneInput.id];
            if (updateField) {
              vendorPhone = {
                [foundPhoneInput.id]: {
                  ...updateField,
                  value: foundSubSupplier?.phoneNumber ? `${foundSubSupplier?.phoneNumber}` : '',
                },
              };
            }
          }
        }
      }
      setFormFieldsData({ ...data, ...vendorPhone });
    }

    return () => {};
  }, [selectedTemplate, selectedLiensRelease]);

  const checkErrors = () => {
    let fields: string[] = [];

    if (selectedSupplierUserPosition === null) {
      fields = [...fields, 'supplier'];
    }
    if (!selectedJob) {
      fields = [...fields, 'job'];
    }
    if (!emailRemainderCount) {
      fields = [...fields, 'emailRemainderCount'];
    }
    if (!dueDate) {
      fields = [...fields, 'dueDate'];
    }
    if (!selectedTemplate) {
      fields = [...fields, 'template'];
    }
    if (hasVendorAddress && address === '') {
      fields = [...fields, 'address'];
    }
    if (hasCityStateZipCode && city === '') {
      fields = [...fields, 'city'];
    }
    if (hasCityStateZipCode && state === '') {
      fields = [...fields, 'state'];
    }
    if (hasCityStateZipCode && zipCode === '') {
      fields = [...fields, 'zipCode'];
    }
    if (invoiceList.filter((invoice) => !invoice.invoiceNumber && invoice.amount).length > 0) {
      fields = [...fields, 'invoiceList'];
    }

    Object.keys(formFieldsData).forEach((fieldKey) => {
      const fieldData = formFieldsData[fieldKey];
      if (
        fieldData.isRequired &&
        !fieldData.value &&
        !fieldData.id.startsWith('vendorCityStateZip') &&
        !fieldData.id.startsWith('vendorAddress')
      ) {
        fields = [...fields, fieldKey];
      }
    });
    setErrorFields(fields);
    return fields;
  };

  const checkMissingJobDetails = (job: Job | null) => {
    if (job) {
      if (
        !job?.owner ||
        job?.owner === '' ||
        !job?.city ||
        job?.city === '' ||
        !job?.state ||
        job?.state === '' ||
        !job?.zipCode ||
        !job?.address ||
        job?.address === ''
      ) {
        return true;
      }
    }
    return false;
  };

  const handleShowSchedule = () => {
    const fieldsWithErrors = checkErrors();
    const checkMissingDetails = checkMissingJobDetails(selectedJob);
    setShowErrorAbove(fieldsWithErrors.length !== 0 || checkMissingDetails);
    setMissingJobDetails(checkMissingDetails);
    if (fieldsWithErrors.length === 0 && !checkMissingDetails) {
      setShowSchedule(!showSchedule);
    }
  };

  const handleSelectFile = (uploadedFiles: File[], fileUrls?: { name: string; url: string }[]) => {
    setFiles(uploadedFiles);
    if (fileUrls) {
      setUrlFiles(fileUrls);
    }
  };

  useEffect(() => {
    if (selectedTemplate) {
      const citystatezipcode = `${city ? `${city}, ` : ''}${state ? `${state} ` : ''}${
        zipCode ? `${zipCode} ` : ''
      }`;
      const foundTemplateInput = [...selectedTemplate.formFields].find(
        (formFields) =>
          formFields.fromFieldType === 'NEW_FIELD' &&
          formFields.whoPopulatesField === 'BUYER' &&
          formFields.id.startsWith('vendorCityStateZip')
      );
      const foundAddressInput = [...selectedTemplate.formFields].find(
        (formFields) =>
          formFields.fromFieldType === 'NEW_FIELD' &&
          formFields.whoPopulatesField === 'BUYER' &&
          formFields.id.startsWith('vendorAddress')
      );
      if (foundTemplateInput || foundAddressInput) {
        const updateField = foundTemplateInput ? formFieldsData[foundTemplateInput.id] : undefined;
        const updateAddressField = foundAddressInput
          ? formFieldsData[foundAddressInput.id]
          : undefined;
        let releaseData = { ...formFieldsData };

        if (updateField) {
          releaseData = {
            ...formFieldsData,
            [updateField.id]: { ...updateField, value: citystatezipcode },
          };
        }
        if (updateAddressField) {
          releaseData = {
            ...formFieldsData,
            [updateAddressField.id]: { ...updateAddressField, value: address },
          };
        }
        setFormFieldsData(releaseData);
      }
    }
  }, [city, state, zipCode, address]);

  const handleEditJob = (e: React.MouseEvent<HTMLButtonElement>, job: Job) => {
    e.stopPropagation();
    setIsEditJob(true);
    setSelectedJob(job);
    setOpenJobModal(true);
  };

  useEffect(() => {
    if (selectedJob && jobs) {
      const updatedJob = jobs.find((item) => {
        return item.id === selectedJob.id;
      });
      if (updatedJob) {
        setSelectedJob(updatedJob);
      }
    }
    return () => {
      setMissingJobDetails(false);
    };
  }, [jobs]);

  const subUsersInvoices = useMemo(() => {
    if (selectedBuyerSuppliers && selectedSubSupplierUser) {
      const unPaidInvoices = [];
      const paidInvoices = [];
      for (let index = 0; index < selectedBuyerSuppliers.length; index++) {
        const selectedBuyerSupplier = selectedBuyerSuppliers[index];
        if (selectedBuyerSupplier.id === selectedSubSupplierUser.syncSupplierId) {
          const invoices = selectedBuyerSupplier.invoices;
          if (invoices) {
            const filteredPaid = [...invoices.paidInvoices.invoices].filter(
              (inv) => inv.jobId === selectedJob?.providerJobId
            );
            paidInvoices.push(...filteredPaid);
            const todayInvoices = invoices.todayInvoices.invoices;
            const currentInvoices = invoices.currentInvoices.invoices;
            const pastDueInvoices = invoices.pastDueInvoices.invoices;
            const pendingPayInvoices = invoices.pendingPayInvoices.invoices;
            const filterUnpaidInvoice = [
              ...todayInvoices,
              ...currentInvoices,
              ...pastDueInvoices,
              ...pendingPayInvoices,
            ].filter((inv) => inv.jobId === selectedJob?.providerJobId);
            unPaidInvoices.push(...filterUnpaidInvoice);
          }
        }
      }
      const result = {
        paidInvoices: paidInvoices,
        unPaidInvoices: unPaidInvoices,
      };
      return result;
    }

    return {
      paidInvoices: [],
      unPaidInvoices: [],
    };
  }, [selectedBuyerSuppliers, selectedSubSupplierUser, selectedJob]);

  return (
    <div className="bg-white pt-3 mt-2 mx-3 rounded">
      <AppNewJobModal
        open={openJobModal}
        editMode={isEditJob}
        handleClose={async () => {
          setOpenJobModal(false);
          if (selectedMainBuyerId) {
            await dispatch(getJobs({ mainBuyerId: selectedMainBuyerId }));
          }
        }}
        subSuppliers={subSuppliers}
        selectedJob={isEditJob ? selectedJob : null}
      />
      {selectedLiensRelease?.status === 'Declined' && (
        <div className="flex mx-4 my-2 WARNING_100-BG  items-center rounded-lg py-2 px-4 justify-between">
          <div className="flex">
            <div>
              <div className="text-sm TEXT_SECONDARY-CLR mb-1">Status</div>
              <AppReleaseStatusPill status={selectedLiensRelease?.status} />
            </div>
            <div className="ml-3">
              <div className="text-sm TEXT_SECONDARY-CLR mb-1">Reason</div>
              <div className="text-sm text-black font-semibold ">{`${
                selectedLiensRelease?.declineReason ?? ''
              }`}</div>
            </div>
          </div>
          <div className="flex WARNING_300-BG w-fit px-3 py-2 rounded items-center">
            <div className="mr-2">
              <Warning color={COLORS.WARNING_700} size={24} />
            </div>
            <div className="text-xs WARNING_700-CLR">
              If declined, you can edit and resend this release<br></br> request to your supplier
            </div>
          </div>
        </div>
      )}
      {selectedLiensRelease?.status === 'Signed' && (
        <div className="flex mx-4 my-2 SUCCESS_100-BG  SUCCESS_500-CLR items-center rounded-lg py-2 px-4 font-medium">
          <CheckCircle color={COLORS.SUCCESS_500} size={24} />
          <div className="ml-2 text-sm">Release has already been signed</div>
          {selectedLiensRelease?.signedTime ? (
            <div className="ml-1 text-sm">{`on ${moment(selectedLiensRelease.signedTime).format(
              'MM/DD/YYYY'
            )} at ${moment(selectedLiensRelease.signedTime).format('LT')}`}</div>
          ) : null}
          {signedBy ? <div className="ml-1 text-sm">{`by ${signedBy}`}</div> : null}
        </div>
      )}
      <div className="flex flex-row py-4 justify-between">
        <AppConfirmationModal
          open={isConfirmCreateLeanReleaseModalOpen}
          modalImage={sendCircle}
          negativeButtonText="No, cancel"
          highlightedText={`${
            selectedSupplierUserPosition !== null && subSupplierUsers[selectedSupplierUserPosition]
              ? subSupplierUsers[selectedSupplierUserPosition].subSupplierName
              : ''
          } - ${
            selectedSupplierUserPosition !== null && subSupplierUsers[selectedSupplierUserPosition]
              ? `${
                  subSupplierUsers[selectedSupplierUserPosition].user.subUser?.firstName ||
                  subSupplierUsers[selectedSupplierUserPosition].user.user?.firstName ||
                  ''
                } ${
                  subSupplierUsers[selectedSupplierUserPosition].user.subUser?.lastName ||
                  subSupplierUsers[selectedSupplierUserPosition].user.user?.lastName ||
                  ''
                }`
              : ''
          }`}
          positiveButtonText="Yes, send it"
          title="Are you sure you want to edit and resend this lien release?"
          subTitle="This release will be resent for signature to"
          handleClose={() => {
            setIsConfirmCreateLeanReleaseModalOpen(false);
          }}
          onNegativeButtonClick={() => {
            setIsConfirmCreateLeanReleaseModalOpen(false);
          }}
          onPositiveButtonClick={() => {
            setIsConfirmCreateLeanReleaseModalOpen(false);
            handleSubmitLienRelease();
          }}
        />
        <AppSuccessModal
          open={isLienReleaseCreateSuccessModalOpen}
          modalImage={successChecksCircle}
          highlightedText={`${
            selectedSupplierUserPosition !== null && subSupplierUsers[selectedSupplierUserPosition]
              ? subSupplierUsers[selectedSupplierUserPosition].subSupplierName
              : ''
          } - ${
            selectedSupplierUserPosition !== null && subSupplierUsers[selectedSupplierUserPosition]
              ? `${
                  subSupplierUsers[selectedSupplierUserPosition].user.subUser?.firstName ||
                  subSupplierUsers[selectedSupplierUserPosition].user.user?.firstName ||
                  ''
                } ${
                  subSupplierUsers[selectedSupplierUserPosition].user.subUser?.lastName ||
                  subSupplierUsers[selectedSupplierUserPosition].user.user?.lastName ||
                  ''
                }`
              : ''
          }`}
          title="Lien release edited and resent!"
          subTitle="This release will be resent for signature to"
          buttonText={
            location.pathname.split('/').length === 7
              ? 'View Job Details'
              : 'View All Lien Releases'
          }
          handleClose={() => {
            setIsLienReleaseEditSuccessModalOpen(false);
            if (location.pathname.split('/').length === 7) {
              navigate(
                `/buyerLienReleases/${selectedMainBuyerId}/lienReleasesJobs/${selectedJob?.id}`
              );
            } else {
              navigate(`/buyerLienReleases/${selectedMainBuyerId}/overview`);
            }
          }}
        />
        <AppSuccessModal
          open={isErrorCreateLeanReleaseModalOpen}
          modalImage={errorCircle}
          title="Release edit failed!"
          subTitle="Your lien release edit request failed to send. Contact customer support"
          buttonText="Close"
          secondButtonText="Retry"
          isSecondButton={true}
          onSecondButtonClick={() => {
            setIsErrorEditLeanReleaseModalOpen(false);
            fetchReleaseData();
            handleSubmitLienRelease();
          }}
          handleClose={() => {
            setIsErrorEditLeanReleaseModalOpen(false);
          }}
        />
        <AppScheduleModal
          open={openScheduleModal}
          handleClose={() => {
            setOpenScheduleModal(false);
            setShowSchedule(false);
          }}
          onSelectDate={handleScheduleDate}
        />
        <AppSuccessModal
          open={openScheduleSuccessModal}
          modalImage={successChecksCircle}
          highlightedText={`${
            selectedSupplierUserPosition !== null && subSupplierUsers[selectedSupplierUserPosition]
              ? subSupplierUsers[selectedSupplierUserPosition].subSupplierName
              : ''
          } - ${
            selectedSupplierUserPosition !== null && subSupplierUsers[selectedSupplierUserPosition]
              ? `${
                  subSupplierUsers[selectedSupplierUserPosition].user.subUser?.firstName ||
                  subSupplierUsers[selectedSupplierUserPosition].user.user?.firstName ||
                  ''
                } ${
                  subSupplierUsers[selectedSupplierUserPosition].user.subUser?.lastName ||
                  subSupplierUsers[selectedSupplierUserPosition].user.user?.lastName ||
                  ''
                }`
              : ''
          }`}
          title="Release scheduled!"
          subTitle="This release will be edited and sent for signature to"
          buttonText={
            location.pathname.split('/').length === 7
              ? 'View Job Details'
              : 'View All Lien Releases'
          }
          handleClose={() => {
            setOpenScheduleModal(false);
            setScheduledDate(null);
            if (location.pathname.split('/').length === 7) {
              navigate(
                `/buyerLienReleases/${selectedMainBuyerId}/lienReleasesJobs/${selectedJob?.id}`
              );
            } else {
              navigate(`/buyerLienReleases/${selectedMainBuyerId}/overview`);
            }
          }}
        />
        <AppSuccessModal
          open={openScheduleErrorModal}
          modalImage={errorCircle}
          title="Scheduling update failed!"
          subTitle="Contact customer support"
          buttonText="Close"
          secondButtonText="Retry"
          isSecondButton={true}
          onSecondButtonClick={() => {
            setOpenScheduleErrorModal(false);
            if (scheduledDate) {
              fetchReleaseData();
              handleScheduleDate(scheduledDate);
            }
          }}
          handleClose={() => {
            setOpenScheduleErrorModal(false);
            setScheduledDate(null);
          }}
        />
        <div
          className={twMerge('flex flex-col w-6/12 mx-4 overflow-scroll hidescrollbar')}
          style={{
            height: `${
              selectedLiensRelease?.status === 'Declined' ||
              selectedLiensRelease?.status === 'Signed'
                ? '70vh'
                : '80vh'
            }`,
          }}>
          <div className={twMerge('flex flex-col px-4 py-3 mb-3')}>
            <div className="my-2 flex justify-between">
              <div className="w-1/2">
                <div className="flex justify-end relative">
                  <button
                    onClick={() => {
                      setIsEditJob(false);
                      setOpenJobModal(true);
                    }}
                    className="absolute flex items-center PRIMARY_500-BORDER border-b cursor-pointer mr-1 mb-1">
                    <Plus size={12} color={COLORS.PRIMARY_500} />
                    <div className="PRIMARY_500-CLR text-xs">Add New Job</div>
                  </button>
                </div>
                <AppDropDown2
                  label="Job"
                  isRequired={true}
                  errorText={
                    errorFields.includes('job')
                      ? 'Please select a job'
                      : `${
                          missingJobDetails
                            ? 'Missing owner or address. Please edit job in dropdown in order to submit release'
                            : ''
                        }`
                  }
                  value={selectedJob?.id || ''}
                  items={[
                    ...jobs
                      .filter((jb) => !jb?.archivedState?.includes('BUYER_ARCHIVED'))
                      .map((job) => {
                        return {
                          label: `${job?.number ? `${job.number} - ` : ''}${job.name}`,
                          value: job.id,
                          labelInElement: (
                            <div className="flex px-2 py-3 m-[-5px] items-center justify-between group relative">
                              <div className="text-sm">{`${job?.number ? `${job.number} - ` : ''}${
                                job.name
                              }`}</div>
                              <button
                                onClick={(e) => {
                                  handleEditJob(e, job);
                                }}
                                className="text-sm px-4 py-1 WHITE-BG font-bold TEXT_SECONDARY-CLR rounded absolute hidden group-hover:inline-block right-2">
                                Edit
                              </button>
                            </div>
                          ),
                        };
                      }),
                  ]}
                  onSelectChange={(item) => {
                    if (item?.value) {
                      const uu = jobs.find((val) => val.id === item.value);
                      if (uu) {
                        setSelectedJob(uu);
                        setMissingJobDetails(false);
                        setShowErrorAbove(false);
                      }
                    }
                  }}
                  disabled={selectedLiensRelease?.status === 'Signed'}
                />
                {selectedJob?.notes && (
                  <div
                    data-tooltip-id={'job-notes-tooltip'}
                    className="flex INFO_200-BG py-3 px-2 items-center rounded-lg overflow-hidden w-full my-2">
                    <div className="flex font-bold items-center">
                      <Notepad color="#000000" size={20} />
                      <div className="mx-1 text-sm">Notes:</div>
                    </div>
                    <div className="truncate text-sm TEXT_SECONDARY-CLR font-medium ml-2 w">
                      {selectedJob?.notes}
                    </div>
                  </div>
                )}
                <ReactTooltip
                  id={'job-notes-tooltip'}
                  place="top"
                  variant="dark"
                  opacity={'100%'}
                  style={{
                    display: 'flex',
                    background: '#334155',
                    width: '300px',
                    zIndex: '70',
                    borderRadius: '8px',
                    alignItems: 'center',
                    justifyItems: 'center',
                    fontWeight: '600',
                  }}>
                  <span>{selectedJob?.notes}</span>
                </ReactTooltip>
              </div>
              <div className="w-1/2 ml-1">
                <AppDropDown
                  label="Supplier"
                  isRequired={true}
                  errorText={errorFields.includes('supplier') ? 'Please select a supplier' : ''}
                  value={
                    (selectedSupplierUserPosition !== null ? selectedSupplierUserPosition : '') + ''
                  }
                  items={[
                    ...subSupplierUsers.map((subSupplierUser, i) => {
                      const userDetails = subSupplierUser.user.subUser
                        ? subSupplierUser.user.subUser
                        : subSupplierUser.user.user;
                      return {
                        label: `${subSupplierUser.subSupplierName} - ${
                          userDetails?.firstName || ''
                        } ${userDetails?.lastName || ''} `,
                        value: i + '',
                      };
                    }),
                  ]}
                  onSelectChange={(item) => {
                    if (item?.value !== null && item?.value !== undefined) {
                      const uu = subSupplierUsers.find((val, i) => i === parseInt(item?.value));
                      if (uu) {
                        setSelectedSubSupplierUser(uu);
                        setSelectedSupplierUserPosition(parseInt(item?.value));
                        setShowErrorAbove(false);
                      }
                    }
                  }}
                  isDisabled={selectedLiensRelease?.status === 'Signed'}
                />
                {selectedSubSupplier?.notes && (
                  <div
                    data-tooltip-id={'subsupplier-notes-tooltip'}
                    className="flex INFO_200-BG py-3 px-2 items-center rounded-lg overflow-hidden w-full my-2">
                    <div className="flex font-bold items-center">
                      <Notepad color="#000000" size={20} />
                      <div className="mx-1 text-sm">Notes:</div>
                    </div>
                    <div className="truncate text-sm TEXT_SECONDARY-CLR font-medium ml-2 w">
                      {selectedSubSupplier?.notes}
                    </div>
                  </div>
                )}
                <ReactTooltip
                  id={'subsupplier-notes-tooltip'}
                  place="top"
                  variant="dark"
                  opacity={'100%'}
                  style={{
                    display: 'flex',
                    background: '#334155',
                    width: '300px',
                    zIndex: '70',
                    borderRadius: '8px',
                    alignItems: 'center',
                    justifyItems: 'center',
                    fontWeight: '600',
                  }}>
                  <span>{selectedSubSupplier?.notes}</span>
                </ReactTooltip>
              </div>
            </div>
            <div className="my-1">
              <AppDropDown
                label="Waiver Template"
                isRequired={true}
                errorText={errorFields.includes('template') ? 'Please select template' : ''}
                value={selectedTemplate ? selectedTemplate.id : ''}
                items={[
                  ...templates.map((template) => {
                    return {
                      label: template.name,
                      value: template.id,
                    };
                  }),
                ]}
                onSelectChange={(item) => {
                  if (item?.value) {
                    const uu = templates.find((val) => val.id === item.value);
                    if (uu) {
                      setSelectedTemplate(uu);
                      setIsWetSignature(uu?.isWetSignature ?? false);
                      setShowErrorAbove(false);
                    }
                  }
                }}
                isDisabled={selectedLiensRelease?.status === 'Signed'}
              />
            </div>
            <div className="my-1">
              <button className="flex gap-2" onClick={onShowInvoiceClick}>
                <AppCheckBox
                  isChecked={showInvoiceListNotes}
                  isDisabled={selectedLiensRelease?.status === 'Signed'}
                />
                <div className="  text-sm font-medium">Include Invoice List Notes</div>
              </button>
            </div>
            {showInvoiceListNotes && (
              <div>
                {subUsersInvoices.paidInvoices.length === 0 &&
                subUsersInvoices.unPaidInvoices.length === 0 ? (
                  <div className="my-1">
                    <EditInvoiceList
                      invoices={invoiceList}
                      setInvoices={setInvoiceList}
                      isShowErrors={errorFields.includes('invoiceList')}
                      invoiceAmountRequired={false}
                      invoiceButtonStyle={InvoiceListButtonStyle.BOTTOM}
                    />
                  </div>
                ) : (
                  <div className="w-full overflow-x-scroll">
                    <AppInvoiceTable
                      invoices={subUsersInvoices}
                      onSelectInvoice={(inv) => {
                        setInvoiceList(inv);
                      }}
                      invoicesSelected={selectedInvoices}
                      paidInvoicesWeeksAgo={8}
                      usedInvoices={usedInvoices}
                    />
                  </div>
                )}
              </div>
            )}
            {hasVendorAddress && (
              <div className="w-full flex">
                <div className="w-full mb-3">
                  <AppInputField
                    errorText={errorFields.includes('address') ? 'Required' : ''}
                    label="Vendor Address"
                    isRequired={true}
                    value={address}
                    inputType={'text'}
                    onTextChange={(text) => {
                      setAddress(text);
                      setShowErrorAbove(false);
                    }}
                    isDisabled={selectedLiensRelease?.status === 'Signed'}
                  />
                </div>
              </div>
            )}
            {hasCityStateZipCode && (
              <div className="w-full flex">
                <div className="w-full mr-2 mb-3">
                  <AppInputField
                    errorText={errorFields.includes('city') ? 'Required' : ''}
                    label="Vendor City"
                    isRequired={true}
                    value={city}
                    inputType={'text'}
                    onTextChange={(text) => {
                      setCity(text);
                      setShowErrorAbove(false);
                    }}
                    isDisabled={selectedLiensRelease?.status === 'Signed'}
                  />
                </div>
                <div className="w-full mr-2 mb-3">
                  <AppInputField
                    errorText={errorFields.includes('state') ? 'Required' : ''}
                    label="Vendor State"
                    isRequired={true}
                    value={state}
                    inputType={'text'}
                    onTextChange={(text) => {
                      setState(text);
                      setShowErrorAbove(false);
                    }}
                    isDisabled={selectedLiensRelease?.status === 'Signed'}
                  />
                </div>
                <div className="w-full mb-3">
                  <AppInputField
                    errorText={errorFields.includes('zipCode') ? 'Required' : ''}
                    label="Vendor Zip"
                    isRequired={true}
                    value={zipCode}
                    inputType={'text'}
                    onTextChange={(text) => {
                      setZipCode(text);
                      setShowErrorAbove(false);
                    }}
                    isDisabled={selectedLiensRelease?.status === 'Signed'}
                  />
                </div>
              </div>
            )}
            {selectedTemplate && (
              <div className="flex flex-wrap ">
                {selectedTemplateFields &&
                  selectedTemplateFields.length > 0 &&
                  [...selectedTemplateFields].map((formField, i) => {
                    const {
                      id,
                      fieldType,
                      fieldName,
                      isFieldRequired,
                      suffix,
                      prefix,
                      maxValue,
                      minValue,
                    } = formField;
                    const fieldValue = formFieldsData[id]?.value || '';
                    return (
                      <div
                        key={i + 'oo'}
                        className={twMerge(
                          'w-1/2 my-2',
                          i % 2 === 0 ? 'pr-1' : 'pl-1',
                          i ===
                            selectedTemplate.formFields.filter(
                              (formFields) =>
                                formFields.fromFieldType === 'NEW_FIELD' &&
                                formFields.whoPopulatesField === 'BUYER' &&
                                !formFields.id.startsWith('vendorCityStateZip') &&
                                !formFields.id.startsWith('vendorAddress')
                            ).length -
                              1 && i % 2 === 0
                            ? 'w-full p-0'
                            : '',
                          fieldType === 'TEXT_AREA' ? 'w-full p-0' : ''
                        )}>
                        {(fieldType === 'TEXT' ||
                          fieldType === 'NUMBER' ||
                          fieldType === 'CURRENCY' ||
                          fieldType === 'TEXT_AREA') && (
                          <AppInputField
                            label={fieldName}
                            inputType={
                              fieldType === 'TEXT_AREA'
                                ? 'textarea'
                                : fieldType === 'NUMBER' || fieldType === 'CURRENCY'
                                ? 'number'
                                : 'text'
                            }
                            value={
                              fieldValue !== null && fieldValue !== undefined ? `${fieldValue}` : ''
                            }
                            isRequired={isFieldRequired}
                            errorText={errorFields.includes(id) ? 'Required' : ''}
                            suffix={suffix || ''}
                            prefix={prefix || ''}
                            onBlur={(e) => {
                              let val = e.target.value;
                              if (fieldType === 'CURRENCY') {
                                if (!val) {
                                  val = null;
                                } else {
                                  val = Number(Number(val)).toFixed(2);
                                }
                                setFormFieldsData({
                                  ...formFieldsData,
                                  [id]: { ...formFieldsData[id], value: val },
                                });
                              }
                            }}
                            onTextChange={(text) => {
                              let val = text;
                              if (minValue && parseInt(text) >= minValue) {
                                return;
                              }
                              if (maxValue && parseInt(text) <= maxValue) {
                                return;
                              }
                              if (fieldType === 'NUMBER') {
                                if (text !== undefined && text !== null) {
                                  val = text;
                                } else {
                                  val = null;
                                }
                              } else if (fieldType === 'CURRENCY') {
                                if (text !== undefined && text !== null) {
                                  val = text;
                                } else {
                                  val = null;
                                }
                              }
                              setShowErrorAbove(false);
                              setFormFieldsData({
                                ...formFieldsData,
                                [id]: { ...formFieldsData[id], value: val },
                              });
                            }}
                            isDisabled={selectedLiensRelease?.status === 'Signed'}
                          />
                        )}
                        {fieldType === 'DATE' && (
                          <AppDatePicker
                            label={fieldName}
                            selectedDate={fieldValue}
                            isRequired={isFieldRequired}
                            errorText={errorFields.includes(id) ? 'Required' : ''}
                            onDateSelected={(date) => {
                              setShowErrorAbove(false);
                              setFormFieldsData({
                                ...formFieldsData,
                                [id]: { ...formFieldsData[id], value: date },
                              });
                            }}
                            isDisabled={selectedLiensRelease?.status === 'Signed'}
                          />
                        )}
                      </div>
                    );
                  })}
              </div>
            )}

            <div className="my-2 flex">
              <div className="w-full ">
                <AppInputField
                  label="Email Reminder Every"
                  isRequired={true}
                  value={emailRemainderCount}
                  errorText={errorFields.includes('emailRemainderCount') ? 'Required' : ''}
                  suffix="Business Days"
                  inputType="number"
                  onTextChange={(text) => {
                    setShowErrorAbove(false);
                    if (text && parseInt(text) >= 3) {
                      setEmailRemainderCount(parseInt(text));
                    }
                  }}
                  isDisabled={selectedLiensRelease?.status === 'Signed'}
                />
              </div>
              <div className="w-full ml-1">
                <AppDatePicker
                  label="Due Date"
                  errorText={errorFields.includes('dueDate') ? 'Due date is required' : ''}
                  selectedDate={dueDate}
                  isRequired={true}
                  minumumDate={nextDay}
                  onDateSelected={(date) => {
                    setShowErrorAbove(false);
                    if (date) {
                      setDueDate(date);
                    } else {
                      setDueDate(null);
                    }
                  }}
                  isDisabled={selectedLiensRelease?.status === 'Signed'}
                />
              </div>
            </div>
            <div className="my-2">
              <AppInputField
                label="Notes"
                inputType="textarea"
                value={notes}
                onTextChange={(text) => {
                  setNotes(text);
                }}
                isDisabled={selectedLiensRelease?.status === 'Signed'}
              />
            </div>
            <div className="my-2">
              <div className="text-xs mb-0.5  whitespace-nowrap font-medium">Attachments</div>
              <AppFilePicker
                onSelectFile={handleSelectFile}
                fileUrls={selectedLiensRelease?.releaseAttachments}
              />
            </div>
            <div className="my-3">
              <hr className="h-px mb-3 bg-gray-200 border-0 dark:bg-gray-200"></hr>
              <button className="flex" onClick={() => setIsWetSignature(!isWetSignature)}>
                <AppCheckBox isChecked={isWetSignature} />
                <div className="ml-2 text-sm font-medium">Require wet signature?</div>
              </button>
            </div>
          </div>
          {showErrorAbove && (
            <div className="my-2 text-xs ERROR_500-CLR text-center">Please see errors above</div>
          )}
          <div className="flex flex-row justify-end ">
            <AppButton
              text="Close"
              type="SECONDARY"
              onClick={() => {
                if (location.pathname.split('/').length === 7) {
                  navigate(
                    `/buyerLienReleases/${selectedMainBuyerId}/lienReleasesJobs/${selectedJob?.id}`
                  );
                } else {
                  navigate(`/buyerLienReleases/${selectedMainBuyerId}/overview`);
                }
              }}
            />
            <div
              className={`flex flex-row ml-4 rounded-lg justify-center align-center ${
                selectedLiensRelease?.status === 'Signed' ? 'PRIMARY_100-BG' : 'PRIMARY_500-BG'
              }`}>
              <AppButton
                // buttonStyles={{ marginLeft: '5px' }}
                text="Submit"
                onClick={() => {
                  const fieldsWithErrors = checkErrors();
                  const checkMissingDetails = checkMissingJobDetails(selectedJob);
                  setShowErrorAbove(fieldsWithErrors.length !== 0 || checkMissingDetails);
                  setMissingJobDetails(checkMissingDetails);
                  if (fieldsWithErrors.length === 0 && !checkMissingDetails) {
                    setIsConfirmCreateLeanReleaseModalOpen(true);
                  }
                }}
                isDisabled={selectedLiensRelease?.status === 'Signed'}
              />
              <div className="bg-white h-4/6 w-px mt-1.5" />
              <AppButton
                buttonStyles={{ width: '5px' }}
                text=""
                onClick={handleShowSchedule}
                icon={<ChevronDown size={15} color={COLORS.WHITE} />}
                isDisabled={selectedLiensRelease?.status === 'Signed'}
              />
            </div>
          </div>
          {showSchedule && (
            <div className="flex w-full justify-end">
              <div className="bg-white w-64 mt-2 rounded-lg px-6 py-5 border" ref={dropdownRef}>
                <div className="mb-2.5 uppercase text-xs font-normal tracking-widest TEXT_SECONDARY-CLR">
                  Schedule Request
                </div>
                <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-200 w-full"></hr>
                <button
                  onClick={() => {
                    setOpenScheduleModal(true);
                    setShowSchedule(false);
                  }}
                  className="text-base font-semibold TEXT_PRIMARY-CLR ">
                  Custom time
                </button>
              </div>
            </div>
          )}
        </div>
        <div
          className="flex flex-col w-6/12 h-full GREY_100-BG p-4 mr-4"
          style={{ height: `${selectedLiensRelease?.status === 'Declined' ? '85vh' : '95vh'}` }}>
          <div className="h-5/6 relative  overflow-scroll flex flex-col items-center pb-3">
            <div
              style={{
                width: '612px',
                background: '#F4F7FA',
              }}
              className="sticky top-0  z-10 flex justify-between  mb-0.5"></div>

            <div
              style={{
                width: '612px',
                height: '792px',
              }}
              className="relative ">
              {currentPdfUrl && (
                <Viewer
                  defaultScale={SpecialZoomLevel.PageFit}
                  fileUrl={currentPdfUrl}
                  plugins={[zoomPluginInstance, pageNavigationPluginInstance]}
                  initialPage={currentPage}
                  onPageChange={(e) => setCurrentPage(e.currentPage)}
                />
              )}
            </div>
          </div>

          <div className="flex flex-row justify-center">
            <div className="mx-4">
              <CurrentPageLabel>
                {(props: RenderCurrentPageLabelProps) => (
                  <span>{`page ${props.currentPage + 1} / ${props.numberOfPages}`}</span>
                )}
              </CurrentPageLabel>
            </div>

            <ZoomOutButton />
            <ZoomPopover />
            <ZoomInButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyerEditLienRelease;
