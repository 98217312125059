import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react';

import {
  pageNavigationPlugin,
  RenderCurrentPageLabelProps,
} from '@react-pdf-viewer/page-navigation';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { Viewer } from '@react-pdf-viewer/core';
import { AppButton } from '../../../../general/AppButton/AppButton';
import CreateSignatureModal from '../CreateSignatureModal/CreateSignatureModal';
import { PDFDocument, PDFName, PDFString } from 'pdf-lib';
import axios from 'axios';
import debounce from 'lodash.debounce';
import { twJoin, twMerge } from 'tailwind-merge';
import { X, ChevronDown } from 'react-feather';
import './LienWaiverDocumentView.scss';
import {
  updateSupplierLienSignature,
  getReleaseBySupplier,
  updateReleaseBySupplier,
  getInvoicesUsedOnReleases,
} from '../../../../../redux/services/liensService';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AppSignSuccessModal } from '../../../../general/AppSignSuccessModal/AppSignSuccessModal';
import {
  formatMoney,
  ReleaseFieldData,
  LienTemplate,
  PDFFieldReleaseTemplate,
  FromFieldTypeReleaseTemplate,
  LienApiResponse,
} from '@dill/dill-shared';
import AppNotesModal from '../../../../general/AppNotesModal/AppNotesModal';
import { AppSuccessModal } from '../../../../general/AppSuccessModal/AppSuccessModal';
import successChecksCircle from '../../../../../assets/svg/success_checks_circle.svg';
import errorCircle from '../../../../../assets/svg/error_circle.svg';
import { toast } from 'react-toastify';
import {
  downloadFile,
  disablePDFFields,
  structureTemplateNewFieldFromTypePDFData,
  structureTemplateNonNewFieldFromTypePDFData,
  populatePagesPdfWithFieldsData,
} from '../../../../../utils/helpers';
import AppDatePicker from '../../../../general/AppDatePicker/AppDatePicker';
import { AppInputField } from '../../../../general/AppInputField/AppInputField';
import { Warning, FileText, CaretCircleLeft, CaretCircleRight } from 'phosphor-react';
import { useTrivialValueDebounce } from '../../../../../utils/hooks/useDebounce';
import AppReleaseStatusPill from '../../../../general/AppReleaseStatusPill/AppReleaseStatusPill';
import {
  createUserSignature,
  deleteUserSignature,
  getUserDetails,
  getUserSignatures,
  updateUserDetails,
} from '../../../../../redux/services/authService';
import { COLORS } from '../../../../../utils/colors';
import AppInvoiceList from '../../../../general/AppInvoiceList';
import { getGroupedSuppliersInvoices } from '../../../../../redux/services/invoicesService';
import { AppInvoiceTable } from '../../../../general/AppInvoiceTable/AppInvoiceTable';
import { AppCheckBox } from '../../../../general/AppCheckBox/AppCheckBox';

const LienWaiverDocumentView = () => {
  const canvasRef = useRef(null);
  const imageRef = useRef(null);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { supplierLienReleases, loadingErrorList } = useAppSelector((state) => state.liens);
  const { user, userSignatures } = useAppSelector((state) => state.auth);
  const { groupedSuppliersInvoices } = useAppSelector((state) => state.invoice);

  const params = useParams();
  const [coodinates, setCoodinates] = useState({ x: 0, y: 0 });
  const [signature, setSignature] = useState('');
  const [originalPdfBytes, setOriginalPdfBytes] = useState<any>(null);
  const [isCreateSignatureModalOpen, setIsCreateSignatureModalOpen] = useState(false);
  const zoomPluginInstance = zoomPlugin();
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;
  const { CurrentPageLabel } = pageNavigationPluginInstance;
  const { lienTemplates } = useAppSelector((state) => state.lienTemplates);
  const [currentPdfUrl, setCurrentPdfUrl] = useState('');
  const [originalPdfUrl, setOriginalPdfUrl] = useState('');
  const [flattenedPdfUrl, setFlattenedPdfUrl] = useState('');
  const [isSignSuccess, setIsSignSuccess] = useState(false);
  const [openSignFailedModal, setOpenSignFailedModal] = useState(false);
  const [openDeclineReasonModal, setOpenDeclineReasonModal] = useState(false);
  const [openDeclineSuccessModal, setOpenDeclineSuccessModal] = useState(false);
  const [openDeclineErrorModal, setOpenDeclineErrorModal] = useState(false);
  const [declineReason, setDeclineReason] = useState('');
  const [defaultIsManualSign, setDefaultIsManualSign] = useState(false);
  const [manualSign, setManualSign] = useState(false);
  const [formFieldsData, setFormFieldsData] = useState<{
    [x: string]: ReleaseFieldData;
  }>({});
  const [errorFields, setErrorFields] = useState<string[]>([]);
  const [pdfUrlHistory, setPdfUrlHistory] = useState<string[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<LienTemplate | null>(null);
  const [selectedLiensRelease, setSelectedLiensRelease] = useState<LienApiResponse | null>(null);
  const [throughDate, setThroughDate] = useState<Date | null>(null);
  const [showMenu, setShowMenu] = useState(false);
  const [downloadAndUpload, setDownloadAndUpload] = useState('');
  const [debouncedPopulatePdfChange, triggerDebouncedPopulatePdf] = useTrivialValueDebounce(200);
  const [title, setTitle] = useState('');
  const [showInvoices, setShowInvoices] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [supplierInvoicesWeeksLimit, setsupplierInvoicesWeeksLimit] = useState(24);
  const [usedInvoices, setUsedInvoices] = useState<
    { type: string; invoiceNumber: string; date: string }[]
  >([]);

  const fetchPdf = async (url: string) => {
    const res = await axios.get(url, {
      responseType: 'arraybuffer',
    });
    if (res.data) {
      return res.data;
    }
    return null;
  };
  const loadOriginalPdf = async (url: string) => {
    const existingPdfBytes = await fetchPdf(url);
    setOriginalPdfBytes(existingPdfBytes);
  };

  const subUsersInvoices = useMemo(() => {
    if (groupedSuppliersInvoices && selectedLiensRelease) {
      const unPaidInvoices = [];
      const paidInvoices = [];
      for (let index = 0; index < groupedSuppliersInvoices.length; index++) {
        const groupedSuppliersInvoice = groupedSuppliersInvoices[index];
        if (groupedSuppliersInvoice.id === selectedLiensRelease.subSupplier?.syncSupplierId) {
          const invoices = groupedSuppliersInvoice.invoices;
          if (invoices) {
            if (selectedLiensRelease.job) {
              const filteredPaid = [...invoices.paidInvoices.invoices].filter((item) => {
                let isJob = false;
                if (
                  item.jobId === selectedLiensRelease.job?.providerJobId ||
                  item.jobId === selectedLiensRelease.job?.quickbooksJobId
                ) {
                  isJob = true;
                }
                return isJob;
              });
              paidInvoices.push(...filteredPaid);
              const todayInvoices = invoices.todayInvoices.invoices;
              const currentInvoices = invoices.currentInvoices.invoices;
              const pastDueInvoices = invoices.pastDueInvoices.invoices;
              const pendingPayInvoices = invoices.pendingPayInvoices.invoices;
              const filterUnpaidInvoice = [
                ...todayInvoices,
                ...currentInvoices,
                ...pastDueInvoices,
                ...pendingPayInvoices,
              ].filter((item) => {
                let isJob = false;
                if (
                  item.jobId === selectedLiensRelease.job?.providerJobId ||
                  item.jobId === selectedLiensRelease.job?.quickbooksJobId
                ) {
                  isJob = true;
                }
                return isJob;
              });
              unPaidInvoices.push(...filterUnpaidInvoice);
            } else {
              const filteredPaid = [...invoices.paidInvoices.invoices];
              paidInvoices.push(...filteredPaid);
              const todayInvoices = invoices.todayInvoices.invoices;
              const currentInvoices = invoices.currentInvoices.invoices;
              const pastDueInvoices = invoices.pastDueInvoices.invoices;
              const pendingPayInvoices = invoices.pendingPayInvoices.invoices;
              const filterUnpaidInvoice = [
                ...todayInvoices,
                ...currentInvoices,
                ...pastDueInvoices,
                ...pendingPayInvoices,
              ];
              unPaidInvoices.push(...filterUnpaidInvoice);
            }
          }
        }
      }
      const result = {
        paidInvoices: paidInvoices,
        unPaidInvoices: unPaidInvoices,
      };
      return result;
    }

    return {
      paidInvoices: [],
      unPaidInvoices: [],
    };
  }, [groupedSuppliersInvoices, selectedLiensRelease]);

  const fetchUsedInvoices = async ({
    mainBuyerId,
    buyerJobId,
  }: {
    mainBuyerId: string;
    buyerJobId: string;
  }) => {
    const usedInvoicesRes: any = await dispatch(
      getInvoicesUsedOnReleases({
        type: 'MAIN_BUYER',
        mainBuyerId,
        buyerJobId,
      })
    );
    if (usedInvoicesRes.type === 'releases/getInvoicesUsedOnReleases/fulfilled') {
      if (
        usedInvoicesRes?.payload &&
        usedInvoicesRes?.payload?.usedInvoices &&
        usedInvoicesRes.payload.usedInvoices.length > 0
      ) {
        const invoices: { type: string; invoiceNumber: string; date: string }[] =
          usedInvoicesRes.payload.usedInvoices;
        setUsedInvoices(invoices);
      }
    }
  };

  const fetchRelease = async (releaseId: string) => {
    const releaseDetails = (await dispatch(getReleaseBySupplier({ releaseId }))) as {
      type: string;
      payload: {
        data: LienApiResponse;
      };
    };

    if (releaseDetails.type === 'releases/getReleaseBySupplier/fulfilled') {
      const data: LienApiResponse = releaseDetails?.payload?.data;
      await dispatch(
        getGroupedSuppliersInvoices({
          mainBuyerId: data.buyer?.id ?? '',
          weeksLimit: supplierInvoicesWeeksLimit,
        })
      );
      setSelectedLiensRelease(data);
      await dispatch(getUserSignatures());
      await fetchUsedInvoices({
        mainBuyerId: data.buyer?.id ?? '',
        buyerJobId: data.job?.id || '',
      });
    }
  };

  useEffect(() => {
    const pathlist = location.pathname.split('/');
    const releaseId = pathlist[pathlist.length - 1];
    fetchRelease(releaseId);

    return () => {
      setDownloadAndUpload('');
    };
  }, [location]);

  const fetchEditedPdf = async () => {
    const res = await axios.get(currentPdfUrl, {
      responseType: 'arraybuffer',
    });
    if (res.data) {
      return res.data;
    }
    return null;
  };

  const vendorContactTitleId = useMemo(() => {
    if (selectedTemplate) {
      const hasTitle = [...selectedTemplate.formFields].filter(
        (formFields) =>
          formFields.fromFieldType === 'NEW_FIELD' &&
          formFields.whoPopulatesField === 'SUB_SUPPLIER' &&
          formFields.id.startsWith('vendorContactTitle')
      );
      if (hasTitle.length > 0) {
        return hasTitle?.[0]?.id;
      }
      return '';
    }

    return '';
  }, [selectedTemplate]);

  const hasUserTitle = useMemo(() => {
    if (selectedTemplate) {
      const hasAddress = [...selectedTemplate.formFields].filter(
        (formFields) =>
          formFields.fromFieldType === 'NEW_FIELD' &&
          formFields.whoPopulatesField === 'SUB_SUPPLIER' &&
          formFields.id.startsWith('vendorContactTitle')
      );
      if (hasAddress.length > 0) {
        setTitle(user?.title ?? '');
        setFormFieldsData({
          ...formFieldsData,
          [vendorContactTitleId]: {
            ...formFieldsData[vendorContactTitleId],
            value: user?.title ?? '',
          },
        });
      }
      return hasAddress.length > 0;
    }

    return false;
  }, [selectedTemplate]);

  useEffect(() => {
    if (originalPdfUrl) {
      (async () => {
        const flat = await disablePDFFields(originalPdfUrl);
        if (flat) {
          setCurrentPdfUrl(flat);
          setFlattenedPdfUrl(flat);
        } else {
          setCurrentPdfUrl(originalPdfUrl);
          setFlattenedPdfUrl(originalPdfUrl);
        }
      })();
    }

    return () => {};
  }, [originalPdfUrl]);

  const populatePdf = async (fieldDetails: PDFFieldReleaseTemplate[]) => {
    if (!selectedLiensRelease?.previewPdf?.url) {
      return;
    }
    let pdfUrl = flattenedPdfUrl;
    if (!flattenedPdfUrl) {
      pdfUrl = selectedLiensRelease?.previewPdf?.url;
    }
    const populatedPdfUrl = await populatePagesPdfWithFieldsData({
      fieldDetails,
      url: pdfUrl,
      releaseId: selectedLiensRelease.id,
    });
    if (populatedPdfUrl) {
      setCurrentPdfUrl(populatedPdfUrl);
    } else {
      setSignature('');
    }
  };

  const populateAllPdfData = () => {
    const specialFieldsPDFData: { fromFieldType: FromFieldTypeReleaseTemplate; dataToUse: any }[] =
      [];

    const dynamicForFieldsPdfData: {
      fromFieldId: string;
      value: any;
    }[] = [];

    if (signature) {
      specialFieldsPDFData.push({ fromFieldType: 'SIGNATURE_FIELD', dataToUse: signature });
      if (selectedLiensRelease?.template) {
        dynamicForFieldsPdfData.push({
          fromFieldId: 'signedDate',
          value: new Date(),
        });
      }
    }

    if (selectedTemplate) {
      Object.keys(formFieldsData).forEach((formFieldKey) => {
        dynamicForFieldsPdfData.push({
          fromFieldId: formFieldKey,
          value: formFieldsData[formFieldKey].value,
        });
      });
    }
    let pdfData: PDFFieldReleaseTemplate[] = [];
    if (specialFieldsPDFData.length > 0 && selectedTemplate) {
      const data1 = structureTemplateNonNewFieldFromTypePDFData({
        template: selectedTemplate,
        details: specialFieldsPDFData,
      });
      pdfData = [...pdfData, ...data1];
    }
    if (dynamicForFieldsPdfData.length > 0) {
      const data2 = structureTemplateNewFieldFromTypePDFData({
        template: selectedTemplate,
        details: dynamicForFieldsPdfData,
      });
      pdfData = [...pdfData, ...data2];
    }
    if (pdfData.length > 0) {
      populatePdf(pdfData);
    }
  };

  useEffect(() => {
    populateAllPdfData();
  }, [debouncedPopulatePdfChange]);

  useEffect(() => {
    if (selectedTemplate && !manualSign) {
      // populateAllPdfData();
      triggerDebouncedPopulatePdf();
    }

    return () => {};
  }, [selectedTemplate, formFieldsData, signature, flattenedPdfUrl, title]);

  useEffect(() => {
    if (selectedLiensRelease) {
      if (selectedLiensRelease?.template) {
        setSelectedTemplate(selectedLiensRelease.template);
        if (selectedLiensRelease?.previewPdf?.url) {
          setOriginalPdfUrl(selectedLiensRelease.previewPdf?.url);
        }
        if (selectedLiensRelease.fieldsData) {
          const tdate = selectedLiensRelease.fieldsData.find((elem) => elem.id === 'throughDate');

          if (tdate && tdate.value) {
            setThroughDate(new Date(tdate.value));
          }
        }
        let data: { [x: string]: ReleaseFieldData } = {};
        selectedLiensRelease?.template?.formFields.forEach((formField) => {
          if (
            formField.fromFieldType === 'NEW_FIELD' &&
            formField.whoPopulatesField === 'SUB_SUPPLIER' &&
            formField.id !== 'signedDate' &&
            formField.id !== 'signature'
          ) {
            data = {
              ...data,
              [formField.id]: {
                isRequired: formField.isFieldRequired,
                value: formField.defaultValue || '',
                id: formField.id,
                name: formField.fieldName,
                type: formField.fieldType,
              },
            };
          }
        });
        setFormFieldsData(data);
      }
      if (
        selectedLiensRelease.status !== 'Requested' &&
        selectedLiensRelease.status !== 'Rejected'
      ) {
        // navigate('/lienReleases');
        toast.error(
          `Release is already ${
            selectedLiensRelease.status === 'Sent to GC'
              ? 'sent to GC'
              : `${selectedLiensRelease.status.toLowerCase()}`
          }`,
          {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
            style: {
              backgroundColor: '#FEF0EF',
              color: '#F04438',
            },
          }
        );
      } else {
      }
      // setCurrentPdfUrl(selectedLiensRelease?.previewPdf?.url || '');

      loadOriginalPdf(selectedLiensRelease?.previewPdf?.url || '');
    }

    return () => {};
  }, [selectedLiensRelease]);

  const customFields = useMemo(() => {
    if (selectedLiensRelease && selectedLiensRelease.template) {
      const supplierFields = selectedLiensRelease.template.formFields.filter(
        (field) =>
          field.whoPopulatesField === 'SUB_SUPPLIER' &&
          field.id !== 'signedDate' &&
          field.id !== 'signature'
      );
      return supplierFields;
    }
    return [];
  }, [selectedLiensRelease]);

  const handleDecline = async (value: string) => {
    setDeclineReason(value);
    const result = await dispatch(
      updateReleaseBySupplier({
        mainBuyerId: selectedLiensRelease?.buyer?.id || '',
        releaseId: selectedLiensRelease?.id ?? '',
        releaseDetails: {
          status: 'Declined',
          declineReason: value,
          signedById: null,
          signedPdf: null,
          signedTime: null,
        },
      })
    );
    setOpenDeclineReasonModal(false);
    if (result.type === 'liens/updateSupplierRelease/fulfilled') {
      setOpenDeclineSuccessModal(true);
    } else {
      setOpenDeclineErrorModal(true);
    }
  };
  const handleDownloadRealease = async () => {
    const createdAtTimeStamp = selectedLiensRelease?.createdAt
      ? new Date(selectedLiensRelease.createdAt).getTime()
      : new Date().getTime();
    const flattened = await disablePDFFields(currentPdfUrl || '');
    if (flattened) {
      downloadFile(
        flattened,
        `${selectedLiensRelease?.job?.name ? `${selectedLiensRelease?.job?.name}-` : ''}${
          selectedLiensRelease?.buyer?.name ?? ''
        }-${createdAtTimeStamp}.pdf`
      );
    }
  };
  const handleAddSignature = (newSignature: string, isManual: boolean) => {
    setManualSign(isManual);
    if (isManual) {
      setSignature(newSignature);
      setCurrentPdfUrl(newSignature);
    } else {
      setSignature(newSignature);
    }
    setIsCreateSignatureModalOpen(false);
  };

  const checkErrors = () => {
    let fields: string[] = [];

    if (hasUserTitle && title === '') {
      fields = [...fields, 'userTitle'];
    }

    Object.keys(formFieldsData).forEach((fieldKey) => {
      const fieldData = formFieldsData[fieldKey];
      if (
        fieldData.isRequired &&
        !fieldData.value &&
        !fieldData.id.startsWith('vendorContactTitle')
      ) {
        fields = [...fields, fieldKey];
      }
    });
    setErrorFields(fields);
    return fields;
  };

  const handleCreateSignature = async () => {
    if (signature.startsWith('data:image')) {
      const foundSignature = userSignatures.find((item) => item.signature === signature);
      if (!foundSignature) {
        if (userSignatures.length > 4) {
          await dispatch(
            deleteUserSignature({ userSignatureId: userSignatures[userSignatures.length - 1].id })
          );
        }
        await dispatch(
          createUserSignature({
            signature,
            date: new Date(),
          })
        );
      }
    }
  };

  const handleSubmitSign = async () => {
    if (downloadAndUpload !== 'Manual') {
      const fieldsWithErrors = checkErrors();
      if (fieldsWithErrors.length > 0) {
        return;
      }
    }
    const flattenedSignedPdfUrl = await disablePDFFields(currentPdfUrl);
    if (flattenedSignedPdfUrl) {
      const currentTimeInDesiredTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const validTimezone = momentTz.tz.zone(currentTimeInDesiredTimeZone)
        ? currentTimeInDesiredTimeZone
        : 'America/Los_Angeles';
      const result = await dispatch(
        updateSupplierLienSignature({
          lienId: selectedLiensRelease?.id ?? '',
          lienDetails: {
            signedPdfUrl: flattenedSignedPdfUrl,
            signedTime: new Date(),
            timezone: validTimezone,
          },
          fieldsData: Object.values(formFieldsData),
          filename: `buyerReleases/${selectedLiensRelease?.buyer?.id}/${selectedLiensRelease?.subSupplier?.id}/${selectedLiensRelease?.job?.id}`,
        })
      );
      if (result.type === 'liens/updateSupplierLienSignature/fulfilled') {
        setIsSignSuccess(true);
        handleCreateSignature();
        if (user && hasUserTitle && title !== user?.title) {
          const updateUserDetailsResponse = await dispatch(
            updateUserDetails({
              userId: user.id,
              userDetails: { title },
            })
          );
          if (updateUserDetailsResponse.type === 'auth/updateUserDetails/fulfilled') {
            await dispatch(getUserDetails());
          }
        }
      } else {
        setOpenSignFailedModal(true);
      }
    }
  };

  const handleSignOrDecline = (type: string) => {
    const fieldsWithErrors = checkErrors();
    if (fieldsWithErrors.length === 0 && type === 'sign') {
      setDefaultIsManualSign(false);
      setIsCreateSignatureModalOpen(true);
    }
  };
  const paymentAmount = selectedLiensRelease?.fieldsData?.find(
    (elem) => elem.id === 'paymentAmount'
  );

  const fetchError = useMemo(() => {
    const findError = loadingErrorList.find((err: any) => err?.getRelease);
    if (findError?.getRelease && findError?.getRelease?.length > 0) {
      return findError?.getRelease?.[0];
    }
    return null;
  }, [loadingErrorList]);

  const handleShowPayment = (val: string) => {
    const viewPaymentTab = window.open(`/payments?transactionId=${val}`, '_blank');
    if (viewPaymentTab) {
      viewPaymentTab.focus();
    }
  };

  const handleShowDropdown = () => {
    setShowMenu(!showMenu);
  };

  return (
    <div className="flex flex-row h-full py-4">
      <CreateSignatureModal
        open={isCreateSignatureModalOpen}
        handleClose={() => {
          setIsCreateSignatureModalOpen(false);
        }}
        addSignature={handleAddSignature}
        onDownloadRealease={handleDownloadRealease}
        defaultIsManualSign={defaultIsManualSign}
      />
      <AppSignSuccessModal
        open={isSignSuccess}
        handleClose={() => {
          setIsSignSuccess(false);
          navigate(`/lienReleases`);
        }}
        selectedLiensRelease={selectedLiensRelease}
        data={{
          type: selectedLiensRelease?.type?.toLowerCase() ?? '',
          from: `${
            selectedLiensRelease?.sender?.subUser?.firstName ??
            selectedLiensRelease?.sender?.user?.firstName ??
            ''
          } ${
            selectedLiensRelease?.sender?.subUser?.lastName ??
            selectedLiensRelease?.sender?.user?.lastName ??
            ''
          }`,
          throughDate,
          amount: paymentAmount ? Number(paymentAmount.value) : null,
          job: `${selectedLiensRelease?.job?.name ?? ''}`,
        }}
      />
      <AppSuccessModal
        open={openSignFailedModal}
        modalImage={errorCircle}
        title="Signing failed!"
        subTitle="Contact customer support."
        buttonText="Close"
        secondButtonText="Retry"
        isSecondButton={true}
        onSecondButtonClick={() => {
          setOpenSignFailedModal(false);
          handleSubmitSign();
        }}
        handleClose={() => {
          setOpenSignFailedModal(false);
        }}
      />
      <AppNotesModal
        open={openDeclineReasonModal}
        handleClose={() => setOpenDeclineReasonModal(false)}
        onSubmit={handleDecline}
      />
      <AppSuccessModal
        open={openDeclineSuccessModal}
        modalImage={successChecksCircle}
        title={'Successfully declined release!'}
        subTitle="Decline reason will be sent to sender"
        buttonText="View All Lien Releases"
        handleClose={() => {
          setOpenDeclineSuccessModal(false);
          navigate(`/lienReleases`);
        }}
      />
      <AppSuccessModal
        open={openDeclineErrorModal}
        modalImage={errorCircle}
        title="Release decline failed!"
        subTitle="Your lien release decline failed to send. Contact customer support"
        buttonText="Close"
        secondButtonText="Retry"
        isSecondButton={true}
        onSecondButtonClick={() => {
          setOpenDeclineErrorModal(false);
          handleDecline(declineReason);
        }}
        handleClose={() => {
          setOpenDeclineErrorModal(false);
        }}
      />
      <div className="flex flex-col w-2/3 h-full">
        <div className="h-5/6 relative  overflow-scroll flex flex-col items-center pb-3">
          <div
            style={{
              width: '612px',
              background: '#F4F7FA',
            }}
            className="sticky top-0  z-10 flex justify-between  mb-0.5"></div>

          <div
            style={{
              width: '612px',
              height: '792px',
            }}
            className="relative ">
            {currentPdfUrl && (
              <Viewer
                defaultScale={1}
                fileUrl={currentPdfUrl}
                plugins={[zoomPluginInstance, pageNavigationPluginInstance]}
                initialPage={currentPage}
                onPageChange={(e) => setCurrentPage(e.currentPage)}
              />
            )}
          </div>
        </div>

        <div className="flex flex-row justify-center">
          <div className="mx-4">
            <CurrentPageLabel>
              {(props: RenderCurrentPageLabelProps) => (
                <span>{`page ${props.currentPage + 1} / ${props.numberOfPages}`}</span>
              )}
            </CurrentPageLabel>
          </div>

          <ZoomOutButton />
          <ZoomPopover />
          <ZoomInButton />
        </div>
      </div>
      <div>
        <div className="flex flex-col py-3 overflow-y-scroll h-4/5 w-[350px] ">
          {selectedLiensRelease?.status === 'Rejected' && (
            <div className="flex flex-col mb-3 p-4 bg-white rounded-2xl">
              <p className="lien-waiver-label mt-2">Status</p>
              <AppReleaseStatusPill status={selectedLiensRelease.status} wrapperClassName="mb-3 " />
              <div className="my-1">
                <p className="lien-waiver-label">Reason</p>
                <p className="lein-waiver-doc-details">{selectedLiensRelease?.declineReason}</p>
              </div>
              <div className="release-pdf-decline-reason-container mt-2">
                <div>
                  <Warning size={24} className="mr-2" color="#93370D" />
                </div>
                <div className="release-pdf-decline-reason-text">
                  Your lien release signature was rejected for the reason above. Please re-sign
                  according to the feedback
                </div>
              </div>
            </div>
          )}
          <div className="flex flex-col mb-3 p-4 bg-white rounded-2xl">
            {selectedLiensRelease?.transactions && (
              <div className="mb-3">
                {selectedLiensRelease?.transactions.length === 1 && (
                  <AppButton
                    type="PRIMARY"
                    buttonStyles={{
                      padding: '4px 8px',
                      margin: '0px 0px',
                      borderRadius: '4px',
                    }}
                    text="View Payment"
                    onClick={() => {
                      if (selectedLiensRelease?.transactions) {
                        handleShowPayment(selectedLiensRelease?.transactions[0]?.id ?? '');
                      }
                    }}
                    iconPosition="RIGHT"
                  />
                )}
                {selectedLiensRelease?.transactions.length > 1 && (
                  <div className="custom-dropdown-container  h-fit ">
                    <AppButton
                      type="PRIMARY"
                      buttonStyles={{
                        padding: '4px 8px',
                        margin: '0px 0px',
                        borderRadius: '4px',
                      }}
                      text="View Payment"
                      onClick={handleShowDropdown}
                      iconPosition="RIGHT"
                      icon={<ChevronDown size={18} color={COLORS.WHITE} />}
                    />
                    {showMenu && (
                      <div className="custom-dropdown-menu h-fit">
                        <div className={`flex flex-col py-3 w-56`}>
                          {selectedLiensRelease.transactions.map((transaction, i) => {
                            let value;
                            if (transaction.invoicePayments.length > 1) {
                              value = `${transaction.invoicePayments[0].invoiceNumber}...${
                                transaction.invoicePayments[transaction.invoicePayments.length - 1]
                                  .invoiceNumber
                              }`;
                            } else {
                              value = transaction.invoicePayments[0].invoiceNumber;
                            }
                            return (
                              <button
                                key={`${transaction.id}_${i}`}
                                onClick={() => handleShowPayment(transaction.id)}
                                className="w-full  py-1.5  px-2 font-medium  hover:bg-blue-100 ">
                                <div>
                                  <div className="flex items-center">
                                    <div className="p-2 PRIMARY_100-BG  mr-2 rounded">
                                      <FileText color={COLORS.PRIMARY_500} size={24} />
                                    </div>
                                    <div className="flex flex-col justify-start justify-items-start items-start">
                                      <div className="text-sm text-black">
                                        {`Invoice #${value}`}
                                      </div>
                                      <div className="text-sm TEXT_SECONDARY-CLR">
                                        Last Update{' '}
                                        <span className="text-black">
                                          {moment(transaction.updatedAt).format('DD/MM/YYYY')}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  {selectedLiensRelease?.transactions &&
                                    selectedLiensRelease.transactions.length - 1 !== i && (
                                      <div className="h-[1px] w-full GREY_300-BG mt-2"></div>
                                    )}
                                </div>
                              </button>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
            <div className="my-1">
              <p className="lien-waiver-label">From</p>
              <p className="lein-waiver-doc-details">{`${
                selectedLiensRelease?.sender?.subUser?.firstName ??
                selectedLiensRelease?.sender?.user?.firstName ??
                ''
              } ${
                selectedLiensRelease?.sender?.subUser?.lastName ??
                selectedLiensRelease?.sender?.user?.lastName ??
                ''
              }`}</p>
              <p className="lein-waiver-doc-details">{selectedLiensRelease?.buyer?.name}</p>
            </div>
            <div className="my-1">
              <p className="lien-waiver-label">Waiver Type</p>
              <p className="lein-waiver-doc-details capitalize">
                {selectedLiensRelease?.type?.toLowerCase()?.replaceAll('_', ' ') ?? ''}
              </p>
            </div>
            <div className="my-1">
              <p className="lien-waiver-label">Job</p>
              <p className="lein-waiver-doc-details">{`${
                selectedLiensRelease?.job?.name ?? ''
              }`}</p>
            </div>
            {throughDate && (
              <div className="my-1">
                <p className="lien-waiver-label">Through Date</p>
                <p className="lein-waiver-doc-details">
                  {moment(throughDate).format('MM/DD/YYYY')}
                </p>
              </div>
            )}
            {paymentAmount && (
              <div className="my-1">
                <p className="lien-waiver-label">Amount of Payment</p>
                <p className="lein-waiver-doc-details">
                  {formatMoney(paymentAmount.value ?? 0, 2)}
                </p>
              </div>
            )}
            {selectedLiensRelease?.dueDate && (
              <div className="my-1">
                <p className="lien-waiver-label">Due Date</p>
                <p className="lein-waiver-doc-details">
                  {moment(selectedLiensRelease?.dueDate).format('MM/DD/YYYY')}
                </p>
              </div>
            )}
            {selectedLiensRelease?.invoiceList && (
              <div className="my-1">
                <p className="lien-waiver-label">Invoice List</p>
                <AppInvoiceList invoiceList={selectedLiensRelease.invoiceList} />
              </div>
            )}
            {selectedLiensRelease?.notes && (
              <div className="my-1">
                <p className="lien-waiver-label">Notes</p>
                <p className="lein-waiver-doc-details">{`${selectedLiensRelease?.notes ?? ''}`}</p>
              </div>
            )}
            {selectedLiensRelease?.releaseAttachments &&
              selectedLiensRelease?.releaseAttachments.length > 0 && (
                <div className="my-1 flex flex-col">
                  <p className="lien-waiver-label">{`Attachment${
                    selectedLiensRelease?.releaseAttachments.length > 0 ? 's' : ''
                  }`}</p>
                  {selectedLiensRelease?.releaseAttachments.map((releaseAttachment, i) => (
                    <a
                      key={`${releaseAttachment.name}_${i}`}
                      href={releaseAttachment.url}
                      target="_blank"
                      className="lien-waiver-link">
                      {releaseAttachment.name}
                    </a>
                  ))}
                </div>
              )}
            <div>
              {(subUsersInvoices.paidInvoices.length > 0 ||
                subUsersInvoices.unPaidInvoices.length > 0) && (
                <button
                  className="flex items-center my-2"
                  onClick={() => {
                    setShowInvoices(!showInvoices);
                  }}>
                  <AppCheckBox isChecked={showInvoices} />
                  <div className="text-sm ml-2 font-medium">Show Invoices</div>
                </button>
              )}
              {(subUsersInvoices.paidInvoices.length > 0 ||
                subUsersInvoices.unPaidInvoices.length > 0) &&
                showInvoices && (
                  <div className="my-1 w-full overflow-x-scroll">
                    <AppInvoiceTable
                      invoices={subUsersInvoices}
                      onSelectInvoice={() => {}}
                      paidInvoicesWeeksAgo={supplierInvoicesWeeksLimit}
                      usedInvoices={usedInvoices}
                    />
                  </div>
                )}
            </div>

            {!signature && !manualSign && (
              <div>
                {hasUserTitle &&
                  selectedLiensRelease &&
                  (selectedLiensRelease.status === 'Requested' ||
                    selectedLiensRelease.status === 'Rejected') && (
                    <div className="w-full my-2 pr-2">
                      <AppInputField
                        label="Job Title"
                        inputType="text"
                        value={title}
                        isRequired={true}
                        errorText={errorFields.includes('userTitle') ? 'Required' : ''}
                        onTextChange={(text) => {
                          setFormFieldsData({
                            ...formFieldsData,
                            [vendorContactTitleId]: {
                              ...formFieldsData[vendorContactTitleId],
                              value: text,
                            },
                          });
                          setTitle(text);
                        }}
                      />
                    </div>
                  )}
                <div className="flex flex-col ">
                  {customFields &&
                    selectedLiensRelease &&
                    (selectedLiensRelease.status === 'Requested' ||
                      selectedLiensRelease.status === 'Rejected') &&
                    customFields.length > 0 &&
                    [...customFields]
                      .filter((fieldItm) => !fieldItm.id.startsWith('vendorContactTitle'))
                      .map((formField, i) => {
                        const {
                          id,
                          fieldType,
                          fieldName,
                          isFieldRequired,
                          suffix,
                          prefix,
                          minValue,
                          maxValue,
                        } = formField;
                        const fieldValue = formFieldsData[id]?.value || '';
                        return (
                          <div
                            key={i + 'oo'}
                            className={twMerge(
                              'w-full my-2',
                              fieldType === 'TEXT_AREA' ? 'w-full p-0' : ''
                            )}>
                            {(fieldType === 'TEXT' ||
                              fieldType === 'NUMBER' ||
                              fieldType === 'CURRENCY' ||
                              fieldType === 'TEXT_AREA') && (
                              <AppInputField
                                label={fieldName}
                                inputType={
                                  fieldType === 'TEXT_AREA'
                                    ? 'textarea'
                                    : fieldType === 'NUMBER' || fieldType === 'CURRENCY'
                                    ? 'number'
                                    : 'text'
                                }
                                value={fieldValue}
                                isRequired={isFieldRequired}
                                errorText={errorFields.includes(id) ? 'Required' : ''}
                                suffix={suffix || ''}
                                prefix={prefix || ''}
                                // isDisabled={isFieldRequired && signature !== ''}
                                onBlur={(e) => {
                                  let val = e.target.value;
                                  if (fieldType === 'CURRENCY') {
                                    if (!val) {
                                      val = null;
                                    } else {
                                      val = Number(Number(val)).toFixed(2);
                                    }
                                    setFormFieldsData({
                                      ...formFieldsData,
                                      [id]: {
                                        isRequired: formField.isFieldRequired,
                                        id: formField.id,
                                        name: formField.fieldName,
                                        type: formField.fieldType,
                                        value: val,
                                      },
                                    });
                                  }
                                }}
                                onTextChange={(text) => {
                                  let val = text;
                                  if (minValue && parseInt(text) >= minValue) {
                                    return;
                                  }
                                  if (maxValue && parseInt(text) <= maxValue) {
                                    return;
                                  }
                                  if (fieldType === 'NUMBER') {
                                    if (text !== undefined && text !== null) {
                                      val = text;
                                    } else {
                                      val = null;
                                    }
                                  } else if (fieldType === 'CURRENCY') {
                                    if (text !== undefined && text !== null) {
                                      val = text;
                                    } else {
                                      val = null;
                                    }
                                  }
                                  setFormFieldsData({
                                    ...formFieldsData,
                                    [id]: {
                                      isRequired: formField.isFieldRequired,
                                      id: formField.id,
                                      name: formField.fieldName,
                                      type: formField.fieldType,
                                      value: val,
                                    },
                                  });
                                }}
                              />
                            )}
                            {fieldType === 'DATE' && (
                              <AppDatePicker
                                label={fieldName}
                                selectedDate={fieldValue}
                                isRequired={isFieldRequired}
                                // isDisabled={isFieldRequired && signature !== ''}
                                errorText={errorFields.includes(id) ? 'Required' : ''}
                                onDateSelected={(date) => {
                                  setFormFieldsData({
                                    ...formFieldsData,
                                    [id]: {
                                      isRequired: formField.isFieldRequired,
                                      id: formField.id,
                                      name: formField.fieldName,
                                      type: formField.fieldType,
                                      value: date,
                                    },
                                  });
                                }}
                              />
                            )}
                          </div>
                        );
                      })}
                </div>
                {signature && !manualSign && (
                  <div className="signature-image-container">
                    <img className="signature-image" src={signature} />
                  </div>
                )}
                {(selectedLiensRelease?.status === 'Requested' ||
                  selectedLiensRelease?.status === 'Rejected') && (
                  <div className="flex flex-col mt-2">
                    {selectedLiensRelease?.isWetSignature && (
                      <div className="release-pdf-decline-reason-container mb-2">
                        <div>
                          <Warning size={24} className="mr-2" color="#93370D" />
                        </div>
                        <div className="release-pdf-decline-reason-text">
                          This release requires a wet signature
                        </div>
                      </div>
                    )}
                    {!selectedLiensRelease?.isWetSignature && (
                      <AppButton
                        buttonStyles={{
                          marginTop: '4px',
                          marginBottom: '4px',
                          width: '100%',
                          height: '40px',
                        }}
                        text={signature !== '' ? 'Signed' : 'Sign Now'}
                        onClick={() => {
                          handleSignOrDecline('sign');
                          setDownloadAndUpload('');
                        }}
                        isDisabled={!!fetchError || signature !== ''}
                      />
                    )}
                    <AppButton
                      text="Download & Manual Upload"
                      type="SECONDARY"
                      buttonStyles={{
                        marginTop: '4px',
                        marginBottom: '4px',
                        width: '100%',
                        height: '40px',
                      }}
                      onClick={() => {
                        setDefaultIsManualSign(true);
                        setIsCreateSignatureModalOpen(true);
                        setDownloadAndUpload('Manual');
                      }}
                      isDisabled={!!fetchError || signature !== ''}
                    />
                    <AppButton
                      text="Decline"
                      type="TERTIARY"
                      buttonStyles={{
                        marginTop: '4px',
                        marginBottom: '4px',
                        width: '100%',
                        height: '40px',
                      }}
                      onClick={() => setOpenDeclineReasonModal(true)}
                      isDisabled={!!fetchError || signature !== ''}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-row ">
          <AppButton
            text="Close"
            type="TERTIARY"
            buttonStyles={{
              marginTop: '4px',
              marginBottom: '4px',
              width: '100%',
              height: '40px',
            }}
            onClick={() => navigate(`/lienReleases`)}
          />
          <AppButton
            buttonStyles={{
              marginLeft: '8px',
              marginTop: '4px',
              marginBottom: '4px',
              width: '100%',
              height: '40px',
            }}
            text="Finish"
            isDisabled={signature === ''}
            onClick={handleSubmitSign}
          />
        </div>
      </div>
    </div>
  );
};

export default LienWaiverDocumentView;
