import type { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { REACT_APP_FRONTEND_URL } from '../../utils/constants';
import { AppServerResponse } from '../../utils/interfaces/generalInterfaces';
import {
  manualUploadData,
  getManualUploadInvoices,
  getManualUploadJobs,
  getManualUploadCustomers,
  recursiveGetManualUploadInvoices,
  getManualUploadInvoicesIds,
} from '../services/manualUploadService';
import { ManualUploadInitialState } from '../globalSlices/manualUploadSlice';

export const manualUploadDataExtraReducer = (
  builder: ActionReducerMapBuilder<ManualUploadInitialState>
) => {
  const reducerId = 'manualUploadData';
  builder.addCase(manualUploadData.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    manualUploadData.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(manualUploadData.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const getManualUploadInvoicesExtraReducer = (
  builder: ActionReducerMapBuilder<ManualUploadInitialState>
) => {
  const reducerId = 'getManualUploadInvoices';
  builder.addCase(getManualUploadInvoices.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    getManualUploadInvoices.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      if (action.payload && action.payload.success && action.payload?.data) {
        state.manualUploadInvoices = action.payload.data;
      }
      if (action.payload && action.payload.success && action.payload?.count) {
        state.numberOfManualInvoices = action.payload?.count;
      }
      if (action.payload && action.payload.success && action.payload?.lastRefKey) {
        state.lastRefKey = action.payload?.lastRefKey ?? null;
      }
    }
  );
  builder.addCase(getManualUploadInvoices.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    state.numberOfManualInvoices = null;
    state.lastRefKey = null;
  });
};

export const recursiveGetManualUploadInvoicesExtraReducer = (
  builder: ActionReducerMapBuilder<ManualUploadInitialState>
) => {
  builder.addCase(
    recursiveGetManualUploadInvoices.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      if (action.payload && action.payload.success && action.payload?.data) {
        state.manualUploadInvoices = state.manualUploadInvoices.concat(action.payload?.data);
      }
      if (action.payload && action.payload.success && action.payload?.lastRefKey) {
        state.lastRefKey = action.payload?.lastRefKey?._fieldsProto?.id?.stringValue ?? null;
      }
    }
  );
};

export const getManualUploadJobsExtraReducer = (
  builder: ActionReducerMapBuilder<ManualUploadInitialState>
) => {
  const reducerId = 'getManualUploadJobs';
  builder.addCase(getManualUploadJobs.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    getManualUploadJobs.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      if (action.payload && action.payload.success && action.payload?.data) {
        state.manualUploadJobs = action.payload.data;
      }
    }
  );
  builder.addCase(getManualUploadJobs.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const getManualUploadCustomersExtraReducer = (
  builder: ActionReducerMapBuilder<ManualUploadInitialState>
) => {
  const reducerId = 'getManualUploadCustomers';
  builder.addCase(getManualUploadCustomers.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    getManualUploadCustomers.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      if (action.payload && action.payload.success && action.payload?.data) {
        state.manualUploadCustomers = action.payload.data;
      }
    }
  );
  builder.addCase(getManualUploadCustomers.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const getManualUploadInvoicesIdsExtraReducer = (
  builder: ActionReducerMapBuilder<ManualUploadInitialState>
) => {
  const reducerId = 'getManualUploadInvoicesIds';
  builder.addCase(getManualUploadInvoicesIds.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    getManualUploadInvoicesIds.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      if (action.payload && action.payload.success && action.payload?.data) {
        state.existingManualUploadInvoices = action.payload.data;
      }
    }
  );
  builder.addCase(getManualUploadInvoicesIds.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};
